export interface Category {
    _id: string;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    parent?: Category;
}




export interface Company {
    _id: string;
    name: string;
    description: string;
    website: string;
    category: Category;
    founded: string,
    verified: boolean
    image?: string 
    status?: string
    reason_rejection? : string
    reason_disabled? : string
    activeCouponsCount: number
    maximumEarnings: number


}



export interface User {
    _id: string,
    name: string,
    email:string,
    password: string,
    username: string,
    verified: true,
    is_active: true,
    image?: string,
    bio?: string,
    createdAt: string,
    updatedAt: string,

}
export interface Coupon {
    _id: string;
    userId: User ;
    category: Category;
    company: Company;
    link: string;
    description: string,
    code: string,
    minValue : number,
    maxValue : number,
    expiryDate : string,
    comment : number,
    nonwWorking: number,
    working: number,
    used: number,
    status : string,
    created?: string,
    updatedAt? : string 
    reason_rejection?: string
    reason_disabled?:  string
    is_status_subscription: boolean
    views: number
    priority: number
   
 
    clicks: number
    copyCode: number
    saved: number
    //To remmove
    total_impressions?: number
    impression?: number
    impression_popular_placement?: number
    lastSeen_search_placemenet?:string
    lastSeen_popular_placemenet?: string


}

export interface CouponUpdateRequest{
    id: string,
   

    company: string,
    link: string,
    description: string,
    code?: string,
    minValue: number,

}


export interface ISubscription {
    _id?: string; // Assuming you want to include the ID field which is automatically added by MongoDB
    userId:   string; // Use string if you're passing the ID around in your React app
    stripeCustomerId: string;
    stripeSubscriptionId: string;
    stripePriceId: string;
    stripeProductName: string;
    status: string;
    stripeCurrentPeriodEnd:  string; // Use string if the date is serialized
    lastInvoiceId?: string; // Optional because `required: false`
    cancel_at?:  string; // Optional because `required: false`, use string if the date is serialized
    cancel_at_period_end: boolean;
    canceled_at?:string; // Optional because `required: false`, use string if the date is serialized
  }
  

export type SearchQuery =  {
    _id : string,
    query : string,
    count : number,
    createdAt  : string,
    updateAt : string
}
export type SearchResult  =  {
    totalCompanies :  number,
    totalCoupons :  number,
    totalUsers: number,
    companies :  Company[],
    coupons :  Coupon[],
    users:  User[]
}


export enum LayoutType {
    Home = "Home",
    Search = "Search",
}
