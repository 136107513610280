import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiSlice } from "../../app/api/apiSlice";
import { ApiResponse } from "../../types/apiType";
import { Company, Coupon, User } from "../../types/modelType";
import { boolean } from "zod";




type CouponsFiltered ={
    total : number,
    page : number,
    limit : number,

    coupons : Coupon[]
}


type CouponResponse = ApiResponse<CouponsFiltered>;
type CouponResponseSingle = ApiResponse<Coupon>;


type CompanyFiltered ={
    total : number,
    page : number,
    limit : number,

    companies : Company[]
}

type CompanyResponse = ApiResponse<CompanyFiltered>;


type UsersFiltered ={
    total : number,
    page : number,
    limit : number,

    users : User[]
}
type UserResponse = ApiResponse<UsersFiltered>;

type SingleUserResponse = ApiResponse<User>;



export const moderatorApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getModeratorCoupon: builder.query<CouponResponse, { page: number; limit: number; search?: string; sort?: string;  order?: string; category? : string, status?: string}>({
            query: ({ page, limit, search = '', sort = 'link', order = 'asc' ,  status = '' }) => ({
                url: `/moderator/posts/pending?page=${page}&limit=${limit}&search=${search}&sort=${sort}&order=${order}&status=${status}`,
                method: 'GET',
            }),
          
            providesTags: (result?: CouponResponse, error?: FetchBaseQueryError) => {
                if (error) {
                    return [];
                }
                
                if (result) {
                    return [
                        { type: 'ModeratorCoupon' as const, id: "LIST" },
                        ...result.data.coupons.map(coupon => ({ type: 'ModeratorCoupon' as const, id: coupon._id }))
                    ];
                }
                
                return [];
            }
        }),

        approveCoupon: builder.mutation<CouponResponseSingle, string>({
            query: (couponId) => ({
                url: `/moderator/posts/approve/${couponId}`,
                method: 'POST',
    
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "ModeratorCoupon", id: "LIST" }
            ]
        }),

        rejectCoupon: builder.mutation<CouponResponseSingle,  { id: string, reason_disabled: string }>({
            query: ({ id, reason_disabled }) => ({
                url: `/moderator/posts/reject/${id}`,
                method: 'POST',
                body: { reason: reason_disabled }
    
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "ModeratorCoupon", id: "LIST" }
            ]
        }),

        disableCoupon: builder.mutation<CouponResponseSingle,  { id: string, reason_disabled: string }>({
            query: ({ id, reason_disabled }) => ({
                url: `/moderator/posts/disable/${id}`,
                method: 'POST',
                body: { reason: reason_disabled }
    
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "ModeratorCoupon", id: "LIST" }
            ]
        }),

        getModeratorCompany: builder.query<CompanyResponse, { page: number; limit: number; search?: string; sort?: string;  order?: string; category? : string, status? : string}>({
            query: ({ page, limit, search = '', sort = 'createdAt', order = 'asc' , category = '', status =  ''}) => ({
                url: `/moderator/company/pending?page=${page}&limit=${limit}&search=${search}&sort=${sort}&order=${order}&category=${category}&status=${status}`,
                method: 'GET',
            }),
            providesTags: (result?: CompanyResponse, error?: FetchBaseQueryError) => {
                if (error) {
                    return [];
                }
                
                if (result) {
                    return [
                        { type: 'ModeratorCompany' as const, id: "LIST" },
                        ...result.data.companies.map(company => ({ type: 'ModeratorCompany' as const, id: company._id }))
                    ];
                }
                
                return [];
            }
        }),

        approveCompany: builder.mutation<CouponResponseSingle, string>({
            query: (companyId) => ({
                url: `/moderator/company/approve/${companyId}`,
                method: 'POST',
    
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "ModeratorCompany", id: "LIST" },
                { type: "ModeratorCoupon", id: "LIST" }
            ]
        }),

        rejectCompany: builder.mutation<CouponResponseSingle, { id: string, reason_rejected: string }>({
            query: ({ id, reason_rejected }) => ({
                url: `/moderator/company/reject/${id}`,
                method: 'POST',
                body: { reason: reason_rejected }
    
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "ModeratorCompany", id: "LIST" },
                { type: "ModeratorCoupon", id: "LIST" }
                
            ]
        }),

        disableCompany: builder.mutation<CouponResponseSingle, { id: string, reason_disabled: string }>({
            query: ({ id, reason_disabled }) => ({
                url: `/moderator/company/disable/${id}`,
                method: 'POST',
             
                body: { reason: reason_disabled }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "ModeratorCompany", id: "LIST" },
                { type: "ModeratorCoupon", id: "LIST" }
            ]
        }),


        getModeratorUsers: builder.query<UserResponse, { page: number; limit: number; search?: string; sort?: string;  order?: string; category? : string, status? : boolean}>({
            query: ({ page, limit, search = '', sort = 'createdAt', order = 'asc' , category = '', status =  true}) => ({
                url: `/moderator/users/pending?page=${page}&limit=${limit}&search=${search}&sort=${sort}&order=${order}&category=${category}&status=${status}`,
                method: 'GET',
            }),
            providesTags: (result?: UserResponse, error?: FetchBaseQueryError) => {
                if (error) {
                    return [];
                }
                
                if (result) {
                    return [
                        { type: 'ModeratorUser' as const, id: "LIST" },
                        ...result.data.users.map(user => ({ type: 'ModeratorUser' as const, id: user._id }))
                    ];
                }
                
                return [];
            }
        }),

        activateUser: builder.mutation<SingleUserResponse, string>({
            query: (id) => ({
                url: `/moderator/users/activate/${id}`,
                method: 'POST',
    
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "ModeratorUser", id: "LIST" },
                { type: "ModeratorCoupon", id: "LIST" }
                
            ]
        }),

        deactivateUser: builder.mutation<SingleUserResponse, { id: string, reason_deactivate: string }>({
            query: ({ id, reason_deactivate }) => ({
                url: `/moderator/users/deactivate/${id}`,
                method: 'POST',
                body: { reason: reason_deactivate }
    
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "ModeratorUser", id: "LIST" },
                { type: "ModeratorCoupon", id: "LIST" }
            ]
        }),




    })






})

export const {
    useGetModeratorCouponQuery,
    useDisableCouponMutation,
    useRejectCouponMutation,
    useApproveCouponMutation,
    useGetModeratorCompanyQuery,
    useApproveCompanyMutation,
    useDisableCompanyMutation,
    useRejectCompanyMutation,
    useGetModeratorUsersQuery,
    useActivateUserMutation,
    useDeactivateUserMutation
} = moderatorApiSlice;
