import { EntityState, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { ApiResponse } from "../../types/apiType";
import { User } from "../../types/modelType";
import { apiSlice } from "../../app/api/apiSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";



type AllUsersResponse  =  ApiResponse<User[]>
type SingleResponse  =  ApiResponse<User>

const allUserAdapter  =  createEntityAdapter<User>({
    selectId: (user) => user._id
})

type AllUserState = EntityState<User>;
const initialAllUserState: AllUserState = allUserAdapter.getInitialState();



export const userAllApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllUsers : builder.query<AllUsersResponse, void>({
            query : () =>'user/all',
            providesTags: (result?: AllUsersResponse, error?: FetchBaseQueryError) => {
                if (error) {
                    return [];
                }
                
                if (result) {
                    return [
                        { type: 'AllUser' as const, id: "LIST" },
                        ...result.data.map(user => ({ type: 'AllUser' as const, id: user._id }))
                    ];
                }
                
                return [];
            }
          
        }),

        getUserById: builder.query<SingleResponse, string | undefined>({
            query: (id) => `user/all/${id}`,
            providesTags: (result, error) => {
                // Add tags for getUserById
                if (error) {
                    return [];
                }

                if (result) {
                    return [{ type: 'AllUser' as const, id: result.data._id }];
                }

                return [];
            },
        })
    })
})

export const {
    useGetAllUsersQuery,
    useGetUserByIdQuery
} =  userAllApiSlice


export const selectALlUSerResults = userAllApiSlice.endpoints.getAllUsers.select();
const transformAllUserResponseToState = (userResponse:  AllUsersResponse ): AllUserState => {
    // Use the categoryAdapter to transform the array of categories into the desired state shape
    return allUserAdapter.setAll(initialAllUserState, userResponse.data);
}


const selectAllUserData  = createSelector (
    selectALlUSerResults,
    (userResult) => {
        if (userResult && userResult.data) {
            return transformAllUserResponseToState(userResult.data);
        }
        return initialAllUserState;
    }
);

export const {
    selectAll: selectAllUsers,
    selectById: selectAllUserById,
    selectIds: selectAllUsersIds,
} = allUserAdapter.getSelectors((state: any) => selectAllUserData(state) ?? initialAllUserState);
