import { ReactNode } from "react";


type SectionProps = {
    title: string;
    children: ReactNode;
};

const Section = ({ title, children }: SectionProps) => (
    <div className='flex flex-col gap-2 mt-10'>
        <div>
            <p className='text-3xl font-semibold text-primary'> {title} </p>
        </div>
        {children}
    </div>
);

export default Section;