import React, { CSSProperties, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectCompanyById, useGetCompanyByIdQuery, useGetCompanyQuery, useGetCompanyStatsQuery, useUpdateCompanyViewsMutation } from '../features/company/companySlice'
import { useGetCouponByCompanyIdQuery } from '../features/coupon/couponApiSlice'
import  userDefault  from '../images/default.png'
import AnalyticsData from '../components/AnalyticsData'
import CouponCard from '../components/CouponCard'
import ViewDetailedCode from './ViewDetailedCode'
import { Coupon } from '../types/modelType'
import { formatDate, formatDateWithMonth, formatDateWithYear } from '../utils/formatting'
import { FcSearch } from 'react-icons/fc'
import { ClipLoader } from 'react-spinners';
import { Helmet } from 'react-helmet-async'

const ViewInvidualCompany = () => {

    const {id} =  useParams()
    const navigate  = useNavigate()
    // const [triggerGetCompanyById, { data: companyData, isLoading, isError :  isLazyGetCompany  }] = useLazyGetCompanyByIdQuery();
    const companyFromStore = useSelector(state =>
      id ? selectCompanyById(state, id) : undefined
    );

    // Fetch company data only if it's not in the store
    const { data: fetchedCompany, isLoading, isError:  isErrorCompanyById } = useGetCompanyByIdQuery({id: id}, {
        skip: !!companyFromStore
    });
    

    const { data: companyStats, isLoading :  isLoadingCompanyStats, isError:  isErrorCompanyStats } = useGetCompanyStatsQuery(id)
    const company = companyFromStore || fetchedCompany?.data;

    const [couponSelected, setCouponSelected] =  useState<Coupon |  undefined>(undefined)
    const [  updateViews , {isLoading: isLaodingUpdatingView}]   = useUpdateCompanyViewsMutation()
    const [showCoupon , setShowCoupon] =  useState(false)
  
    const handleOnClick  =  (coupon : Coupon) =>{
        setCouponSelected(coupon)
        setShowCoupon(true)
    }
    const MAX_LENGTH_TEXT = 500
    const {data, isLoading :  isLoadingPosts, isError, error, isFetching, isSuccess  }  =   useGetCouponByCompanyIdQuery(id)
    

    const handleShareLink =  () =>{
      navigate('/create')
    }

    // useEffect(() => {
    //   async function updateOnMount() {
    //     try {
    //       const couponToSend = id 
    //       const result = await updateViews(id);
         
    //     } catch (error) {}
    //   }
  
    //   updateOnMount();
    // }, []); // The empty dependency array ensures this runs only once on mount
  
  
    // if (company?.status === "disabled"){
    //   return null
    // }

    const handleRequestBrand = () =>{
      navigate('/create')
  }

  let [color, setColor] = useState("#ffffff");

  let [loading, setLoading] = useState(true);

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "black",
  };


  if (isLoading || isLoadingCompanyStats || isFetching){

    return (

      <div className='h-screen bg-background'>

        <div className='flex flex-col items-center justify-center h-full'>

          <ClipLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />


        </div>


     </div>
    )

  }


  const isDataAvailable = company && !isLoading;

  // Default SEO tags
  let title = "Uniqshare | Company Details";
  let description = "Explore company details on Uniqshare.";
  let canonicalUrl = `${process.env.REACT_APP_BASE_URL}/companies/${id}`;

  // Update SEO tags if company data is available
  if (isDataAvailable) {
      title = `Uniqshare | Referrals & Coupons of  ${company.name}`;
      description = `Find all coupons, referrals codes, couponds, savingings affiliates, deals for ${company.name}`;

  }

  return (

    <>
       <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    
    
    
    <div className='bg-background'>

    {showCoupon ?  <ViewDetailedCode  open = {showCoupon} coupon_data={couponSelected} setShowCoupon={setShowCoupon}/> : null}
    <div className='container flex flex-col min-h-screen gap-5 px-3 py-10 mx-auto lg:px-2 lg:w-4/5 '>
       {/* Title */}
      {/* <div>
        <p> ALL REWARDS, AFFILIATES AND DEALS FROM {"company.name"}</p>

      </div> */}
      {/* Main */}
      


      {company?.status === "pending" && 
                        <div className="p-3 text-blue-800 bg-blue-100 rounded-md">
                            * Info: Company will be reviewed in 24 hours, thanks
                        </div>
                    }

      {company?.status === "rejected" && 
          <div className="p-3 text-red-800 bg-red-100 rounded-md">
              * Warning: Company was rejected: {company.reason_rejection}
          </div>
      }

      {company?.status === "disabled" && 
          <div className="p-3 text-yellow-800 bg-yellow-100 rounded-md">
              * Warning: Company was disabled: {company.reason_disabled}
          </div>
      }

                    
      <div className='flex flex-col gap-4 lg:flex-row'>
        {/* Information about the company */}
        <div className='flex flex-col w-full gap-3 p-5 border-2 shadow-xl border-zinc-100 lg:w-1/3 bg-card '>
          {/* First part */}
          
          <div className='flex flex-row gap-2'>
            <div className='w-24 h-24 '>
                <img src={company?.image ? company?.image : userDefault } className='rounded-md aspect-square' />
            </div>

            <div>
              <div>
                <h1 className='text-3xl font-bold'>   <p className='text-xl'> {company?.name}</p></h1>
              </div>

              <div>
             
                <p className='text-md'> {company?.category.name}</p>
              </div>
             

            </div>

          

          </div>
        



          
         



          <div className='text-md'>
      
              {company?.description &&
                <p className=''>  {company?.description.slice(0, MAX_LENGTH_TEXT) + (company?.description.length > MAX_LENGTH_TEXT ? "..." : "")}</p>
              }

          </div>

          <div>
            
          </div>
          <div className=' text-md'>
      
              <p><span className='font-medium'>Founded: </span>{company?.founded && formatDateWithMonth(company.founded)}</p>

          </div>

           {/* Second Part  */}
          <div className=''>

              <p> <span className='font-medium'>Website: </span> <a href={company?.website}  target="_blank"> {company?.website} </a></p> 
          </div>

          <div className='mt-2'>
             
             <button className='px-4 py-1 text-white rounded-md ext-left bg-primary' onClick={()=> handleShareLink()}> Share Your Referral</button>
           </div>



        




        </div>

        {/* Grid about comapnies codes  */}

        <div className='grid flex-1 grid-cols-3 gap-4 py-4 border-2 shadow-xl bg-card border-zinc-100 lg:py-0'>
            <AnalyticsData label={"Clicks"} data={companyStats?.data.totalClicks || 0} />
            <AnalyticsData label={"Codes"} data={companyStats?.data.totalCoupons || 0}/>
            <AnalyticsData label={"Views"} data={companyStats?.data.totalViews || 0}/>
            <AnalyticsData label={"Users"} data={companyStats?.data.userCount || 0}/>
            <AnalyticsData label={"Max Earn($)"} data={companyStats?.data.maxEarnings || 0}/>
            <AnalyticsData label={"Avg Earn($)"} data={companyStats?.data.avgEarnings || 0}/>

            

          {/* {content} */}
          
        </div>








      </div>

      { data?.data.length === 0  && isFetching === false ?
              <div className='flex flex-col items-center justify-center h-full p-1 py-10 '>
                <span className='mb-2 text-3xl text-gray-800'>
                    <FcSearch  className='w-24 h-24' />
                </span>
                <h3 className='mb-3 text-2xl font-semibold text-gray-800'>Oops! No Codes Yet</h3>
                <p className='mb-5 text-base text-center text-gray-600'>
                    We've looked everywhere, but no codes yet. <br></br>Be the first to share and start the trend!
                </p>

                <button className='px-4 py-1.5 font-bold text-white bg-primary rounded-sm' onClick={()=>handleRequestBrand()}>
                  SHARE  CODE
                </button>
              </div>
            : 
            

      (<div className='flex flex-col gap-2'>
        <h1 className='mb-2 text-2xl font-semibold'>Active Codes</h1>


          
       
        <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
            
              { data?.data.map((coupon)  => (
          <CouponCard  coupon_data={coupon} onClick={()=> handleOnClick(coupon)} key={coupon._id}/>
        ))}


        </div>


      </div>)
    }
    
      
      
      
      

      
      
      </div>

      </div>
      </>
  )

 
}

export default ViewInvidualCompany