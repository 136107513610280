import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentEmail } from '../features/auth/authSlice';
import { selectSubscription, selectUserProfile } from '../features/user/userSlice';
import { Helmet } from 'react-helmet-async';
declare global {
    namespace JSX {
      interface IntrinsicElements {
        'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
    }

}
const StripePlan = ()  => {
  // Paste the stripe-pricing-table snippet in your React component
  const email  =  useSelector(selectCurrentEmail)
  const subscription  =  useSelector(selectSubscription)
  const title = "Uniqshare | Subscribe";
  const description = "Subscribe to one of our cheap plans, start posting referrals codes and affiliate links and start your passive income journey.";
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/plan`;


  return (
    <>
        <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    
    <div  className='py-10 bg-background'>

    <div className='container flex flex-col w-full gap-4 px-10 mx-auto text-center '>
      <h1 className='text-4xl font-semibold'>Promote Your Codes & Propel Your Profits</h1>
      <div>
         <p className='mx-auto text-lg font-normal lg:w-2/3 xl:w-1/2'>Dive into a world where your affiliate codes pave the way to profit. Select your plan, share your links, and watch your earnings grow! </p>

      </div>
    </div>


    <div className='pb-5 mt-5'>

    {/* <stripe-pricing-table  
        pricing-table-id="prctbl_1O5DsbAPt7GVRAELgpsDxtkn"
        publishable-key="pk_test_51O5BvvAPt7GVRAELPSQXCs2uyvLf3tkMJz3f6bnbJZ3EJItaCqiedCqgspGebpjcQQ5sDnfHPIKmj3Ckcg5UDGZJ00v2XTAhIS"
        client-reference-id={subscription?.stripeCustomerId}
        customer-email= {email}>
      </stripe-pricing-table> */}

    {process.env.NODE_ENV === "production" ?
      <stripe-pricing-table 
        pricing-table-id="prctbl_1OLsy4APt7GVRAELCjTpyGjH"
        publishable-key="pk_live_51O5BvvAPt7GVRAELmndREgfDAHjE9YHDn9OeE2DYgdPrHSZThMh0zJW7FEEH3LNLZREQBycmYrQSxCefaMWbpFkw00kwaDbe0l"
        client-reference-id={subscription?.stripeCustomerId}
        customer-email= {email}
        
        >
      
      </stripe-pricing-table> : 
  
      <stripe-pricing-table  
        pricing-table-id="prctbl_1O5DsbAPt7GVRAELgpsDxtkn"
        publishable-key="pk_test_51O5BvvAPt7GVRAELPSQXCs2uyvLf3tkMJz3f6bnbJZ3EJItaCqiedCqgspGebpjcQQ5sDnfHPIKmj3Ckcg5UDGZJ00v2XTAhIS"
        client-reference-id={subscription?.stripeCustomerId}
        customer-email= {email}>
      </stripe-pricing-table>

        




    }
    
    
     


      

    </div>

     
      
    </div>
  
 




   

    </>


    
  );
}

export default StripePlan;

// https://saasbase.dev/blog/subscription-payments-3-update-and-cancel-plans-via-a-manage-billing-screen-using-stripe