import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import { deleteUser, setUser } from '../features/auth/authSlice';
import { useLazySuccessAuthQuery, useSuccessAuthQuery } from '../features/auth/authApiSlice';
import ClipLoader from "react-spinners/ClipLoader";
import { resetUserState } from '../features/user/userSlice';


const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "black",
};
const AuthSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trigger, { data, error, isLoading }] = useLazySuccessAuthQuery();

 
  // const { data, error, isLoading } = useSuccessAuthQuery(undefined, {
  //   refetchOnFocus: true,
  //   refetchOnMountOrArgChange:true,
  //    refetchOnReconnect: true
  //   // skipCache: true, // Skip using cached data
  // });
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");

  useEffect(() => {
    trigger(); // This will call the query every time the component mounts
  }, [trigger]);


  useEffect(() => {
    if (data) {
      // Dispatch the setUser action with the fetched data
      dispatch(setUser(data.data));
      // Redirect after a few seconds
      const timer = setTimeout(() => {
        navigate('/account'); // Redirect to the dashboard or another route
      }, 2000); // Wait for 5 seconds

      return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }
  }, [data, dispatch, navigate]);

  if (error ) {
    navigate('/login/?success=false')
  }

  // // If there is no data, there's nothing to display
  // if (!data) return null;

  // If there is data, display the success message
  return (
    <div className='flex items-center justify-center w-screen h-screen px-5 bg-background'>
      {/* Card container  */}
      <div className='max-w-xl px-5 py-10 '>
        <div className='flex flex-col gap-6 px-3 py-5 mx-3 '>
            {/* Logo */}
              <div className='flex justify-center w-full '>
                  <img src={logo} alt="Logo" className='object-contain w-2/3'/>
              </div>

              <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              

        </div>
      </div>
    </div>

  );
};

export default AuthSuccess;
