import React, { useState } from 'react'

type EditableInputProps  =  {
    value : string,
    setValue : React.Dispatch<React.SetStateAction<string>>
    isEditable : boolean,
    defaultValue? : string,
    instructiots?: string ,

}

export const EditableInput = ({value, setValue, isEditable, defaultValue , instructiots} : EditableInputProps) => {
  // Handle Input when key is entered
  const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
    const target = event.target as HTMLInputElement; 
    const value = target.value;
    setValue(value)
  }
  return (
    <div className="flex flex-col items-start text-base">
    {isEditable ? (
      <input 
        type="text" 
        value={value} 
        onChange={handleInput}
        placeholder={instructiots}
        className="w-full bg-transparent border-b-2 border-gray-200 outline-none "
      />
    ) : (
      <span className="">{value ? value:  defaultValue}</span>
    )}

  </div>
  )
}

export default EditableInput