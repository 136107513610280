

import React, { Fragment, useEffect, useState } from 'react'
import  userDefault  from '../images/default.png'
import AccountCode from '../components/AccountCode'
import { Menu, Tab } from '@headlessui/react'
import { useGetUserCouponsQuery, useGetUserSubscriptionQuery, usePortalBillingMutation } from '../features/user/userApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCouponMonthlyLimit, selectCouponUsed, selectSubscription, selectUserCouponIds } from '../features/user/userSlice'
import CouponCard from '../components/CouponCard'
import { EntityId } from '@reduxjs/toolkit'
import InvidualCode from './InvidualCode'
import SubscriptionDetails from '../components/SubscriptionDetails'
import { showModal } from '../features/modal/modalSlice'
import { MdArrowDropDown, MdModeEdit } from "react-icons/md";
import EditableInput from '../components/EditableInput'
import EditableTextArea from '../components/EditableTextArea'
import { selectCurrentUser, setUser } from '../features/auth/authSlice'
import { convertImageToBase64 } from '../utils/helperFunction'
import { useGetCurrentUserQuery, useUpdateUserInfoMutation } from '../features/auth/authApiSlice'
import { IoQrCodeSharp } from "react-icons/io5";
import { FaCircleQuestion } from "react-icons/fa6";
import { FaBookmark } from "react-icons/fa";


function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
  }
  
const Account = () => {
    const  dispatch =  useDispatch()
    //Selector Before Calls
    
    const [status, setStatus] = useState('')
    // Servers Calls
    const {data , isError, isLoading, error, refetch} =  useGetUserCouponsQuery({ status:  status})
    const {data: userSubscription, isError :  isErrorSubscription,   error: errorSubscription, refetch : refetchUserSub} =  useGetUserSubscriptionQuery()

    // const {data:userInfo, isLoading: isLoadingCurrentUserInfo, isError :  isErrorUserInfo,   error: errorUserInfo} =  useGetCurrentUserQuery()
    
    // if (!isLoadingCurrentUserInfo && !isErrorUserInfo && userInfo){
    //     dispatch(setUser(userInfo?.data))
    // }

    const user  =  useSelector(selectCurrentUser)



    const [requestPortalBilling , {isLoading : isPortalBillingLoading}] =  usePortalBillingMutation()   
    const [updateUserInfo  , {isLoading: isLoadingUserInfo}] =  useUpdateUserInfoMutation()
    //Selectors
    const couponsIds  = useSelector(selectUserCouponIds) 
    const subscription =  useSelector(selectSubscription)
    const limit =  useSelector(selectCouponMonthlyLimit)
    const used =  useSelector(selectCouponUsed)
   

    // Use States
    const [imagePreview, setImagePreview] = useState<string | null>(user.image);
    const [couponSelected, setCouponSelected] =  useState<EntityId |  undefined>(undefined)
    const [showCoupon , setShowCoupon] =  useState(false)
    const [usernameValue, setUsernameValue] = useState(user.username!)
    const [nameValue, setNameValue] = useState(user.name!)
    const [bioValue, setBioValue] = useState(user.bio!)

    const  [editingMode, setEditingMode]  = useState(false) 
    // Handelers
    // -Redirect user to subscription page
    const handleManageAccount  = async()=>{
        try {
            const data  =  await requestPortalBilling().unwrap()
            const url  = data.data
            if (!url){
                dispatch(showModal({
                    type: 'error',
                    message: 'Portal is missing',
                }));
                return 
            }
            window.location.href = url; 
        } catch (error) {
        }
    }
    // -Show individual coupon
    const handleOnClick  =  (id : EntityId) =>{        
        setCouponSelected(id)
        setShowCoupon(true)
    }
    // -Image Selection
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) =>{
        const target = event.target as HTMLInputElement; // Cast to HTMLInputElement
        if (target.type === 'file') {
            const files = target.files;
            if (files && files.length > 0) {
              const file = files[0];
            try { 
              const base64String = await convertImageToBase64(file);
              setImagePreview(base64String); // Set image preview
            } catch (error) {
              console.error("Error converting file to base64", error);
            }
            }
          } 
    }   
    // -Edit Profile
    const handleEditProfile = async() =>{
        if (!editingMode){
            setEditingMode(true)
        }else {
            type UserData = { username: any; name: any; bio: any; } | { username: any; name: any; bio: any; image: string | null; };
            let data: UserData = { username: usernameValue, name: nameValue, bio: bioValue };
            if (data.name != "" && data.username != ""){
                if (imagePreview !== user.image) {
                    data = { image: imagePreview, ...data };
                }    
                try {
                    const resuts =  await updateUserInfo(data).unwrap()
                    dispatch(setUser(resuts.data))
                    setNameValue(resuts.data.name)
                    setBioValue(resuts.data.bio || "")
                    setUsernameValue(resuts.data.username)
                    setImagePreview(resuts.data.image ? resuts.data.image  :  null)
                } catch (error) {}
            }else {
                setNameValue(user.name!)
                setUsernameValue(user.username!)
            }
            setEditingMode(false)
        }
    }
    // -Cancel Editing
    const cancelAction  =  () =>{
        setEditingMode(false)
        setNameValue(user.name!)
        setBioValue(user.bio!)
        setUsernameValue(user.username!)
        setImagePreview(user.image)
    }
    useEffect(() => {
        refetch();
    }, [status, refetch]);

    // const handleFilter = (filter: string) =>{
    //     setStatus(filter)
    //     refetch()
    // }

  

  return (
    <>
    {/* Invidual Coupon Pop Up */}
    {showCoupon ?  <InvidualCode coupon_id={couponSelected} setShowCoupon={setShowCoupon}  refetchSub={refetchUserSub}/> : null}
    {/* Page */}
    <div className='min-h-screen py-10 bg-background'> 
        {/* Containr */}
        <div className='mx-auto container-2'>
            {/* Top Part */}
            <div className='flex flex-col items-center gap-5 px-2 py-1 md:py-5 md:justify-start md:flex-row '>
            
            {/* Right Part - Image */}
            <div className='relative w-1/2 md:w-1/5 aspect-square'>
                <img src={imagePreview ? imagePreview : userDefault}  className='object-cover w-full h-full rounded-lg'  alt="User" />

                {/* Image Editing Button */}
                <div className={`icon-and-input-container  ${editingMode ? 'block' : 'hidden'}`}>
                    <MdModeEdit
                        className='absolute top-0 right-0 w-8 h-8 px-2 py-1 text-base text-white'
                        onClick={() => document.getElementById('image-upload')?.click()}
                    />
                    <input
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                    />
                </div>
            </div>
                {/* Right Part */}
                <div className='flex-1 w-full text-base '>
                    {/* Top Part right */}
                    <div className='flex flex-row justify-between '>
                        {/* Username */}

                        <div className='flex flex-row items-center gap-1 text-base'>
                            Welcome <EditableInput isEditable={editingMode}  value={nameValue} instructiots={'Enter your name'}  setValue={setNameValue} /> 
                        </div>
                       
                        {/* Editing buttton */}
                        <div className='flex flex-row gap-3'>
                            <button className='px-3 py-1 text-sm text-white bg-primary disabled:bg-gray-400' onClick={()=>  handleEditProfile()} disabled={isLoadingUserInfo}>  {editingMode?  "Save" : "Edit Profile"  } </button>
                            <button className={`px-3  py-1  text-sm text-white bg-primary ${editingMode ? 'block' : 'hidden'}`} onClick={() => cancelAction()}> Cancel </button>
                        </div>
                    </div>

                    {/* Number of codes, requests, and saved */}
                    <div className='flex flex-row justify-between mt-5 '>
                        <div>
                            {couponsIds ?  couponsIds.length :  0} codes
                        </div>

                        <div>
                            0 requests
                        </div>

                        <div>
                            0  saved
                        </div>
                    </div>

                    {/* Username and description */}
                    <div className='flex flex-col gap-2 mt-5'>
                         <div className='flex flex-row items-center font-semibold'>
                                {/* <p> navidsheikh</p> */}
                                @<EditableInput isEditable={editingMode} value={usernameValue!} instructiots={'Enter your username'}  setValue={setUsernameValue}/> 
                        </div>
                        <div>
                            <EditableInput isEditable={editingMode} value={bioValue!} defaultValue={'Checkout my codes'} instructiots={'Enter your bio here'}   setValue={setBioValue} /> 
                        </div>
                    </div>

                </div>

            </div>
            {/* Subscription */}

            {user.is_active === false && 
                <div className="p-3 text-red-800 bg-red-100 rounded-md">
                    * Warning: Your account was disabled : {user.deactivation_reason} . We cancelled your subscription.
                </div>
            }

        
            <SubscriptionDetails subscription={subscription} onClickManage =  {handleManageAccount}/>
            {/* <div className="px-3">
                <p>Used Monthly : { used  + "/" +  limit}</p>

            </div> */}

         

      
           
    
            <div className="w-full mt-5 text-sm">
            <Tab.Group>

                <Tab.List className="flex space-x-1 border-t-2 border-primary">
                    {/* My Codes */}
                    <Tab
                        className={({ selected }) =>
                            classNames(
                            'w-full py-2.5 text-sm font-medium leading-5 outline-none',
                            'text-primary', // Common styles
                            selected ? 'bg-primary shadow text-white' : '' // Conditional styles based on selection
                            )
                        }
                    >
                        <div className='flex flex-row items-center justify-center gap-1'>
                            <IoQrCodeSharp />
                            CODES
                        </div>
                    </Tab>

                    {/* Requests */}
                    <Tab
                        className={({ selected }) =>
                            classNames(
                            'w-full py-2.5 text-sm font-medium leading-5 outline-none',
                            'text-primary', // Common styles
                            selected ? 'bg-primary shadow text-white' : '' // Conditional styles based on selection
                            )
                        }
                    >
                        <div className='flex flex-row items-center justify-center gap-1'>
                             <FaCircleQuestion />
                            REQUESTS
                        </div>
                    </Tab>

                    {/* Saved Codes */}
                    <Tab
                        className={({ selected }) =>
                            classNames(
                            'w-full py-2.5 text-sm font-medium leading-5 outline-none',
                            'text-primary', // Common styles
                            selected ? 'bg-primary shadow text-white' : '' // Conditional styles based on selection
                            )
                        }
                    >
                        <div className='flex flex-row items-center justify-center gap-1'>
                             <FaBookmark /> 
                            SAVED
                        </div>
                    </Tab>

                </Tab.List>
                {/* PAnesl */}

                
                <Tab.Panels className='mt-4'>
                    {/* codes */}
                    <Tab.Panel >
                        <div className='relative '>

                        <div className="absolute left-0 z-10 block  py-1.5  rounded-sm -top-10 ">
                            <p className='font-medium'> Monthly Limit - { used  + "/" +  limit}</p>
                        </div>
            
                        <div className="absolute right-0 z-10 block w-32 text-white rounded-sm -top-10 bg-primary">
                      
                            <Menu >
                                <Menu.Button className="flex flex-row items-center justify-between w-full px-4 py-1 text-sm text-white font-small">
                                    {status.length === 0 ? 'All' :   status.charAt(0).toUpperCase() + status.slice(1)} {/* Displaying current status */}
                                    <MdArrowDropDown className='w-6 h-6' />
                                </Menu.Button>
                                <Menu.Items className="py-2 text-sm font-medium text-white ">

                                    <Menu.Item >
                                        {({ active }) => (
                                            <button
                                                className={`block w-full text-left px-4 py-2 ${active ? 'bg-gray-400 text-black' : 'text-white'}`}
                                                onClick={() => setStatus('')}
                                            >
                                                All
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`block w-full text-left px-4 py-2 ${active ? 'bg-gray-400 text-black' : 'text-white'}`}
                                                onClick={() => setStatus('pending')}
                                            >
                                                Pending
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`block w-full text-left px-4 py-2 ${active ? 'bg-gray-400 text-black' : 'text-white'}`}
                                                onClick={() => setStatus('approved')}
                                            >
                                                Approved
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`block w-full text-left px-4 py-2 ${active ? 'bg-gray-400 text-black' : 'text-white'}`}
                                                onClick={() => setStatus('rejected')}
                                            >
                                                Rejected
                                            </button>
                                        )}
                                    </Menu.Item>

                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`block w-full text-left px-4 py-2 ${active ? 'bg-gray-400 text-black' : 'text-white'}`}
                                                onClick={() => setStatus('deactivated')}
                                            >
                                                Deactivated
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`block w-full text-left px-4 py-2 ${active ? 'bg-gray-400 text-black' : 'text-white'}`}
                                                onClick={() => setStatus('disabled')}
                                            >
                                                Disabled
                                            </button>
                                        )}
                                    </Menu.Item>
                                    {/* Add other status options here as needed */}
                                </Menu.Items>
                            </Menu>
                        </div>
                      
            

                        
       
                        
                        
                        <div className='w-full mt-12 min-h-[50vh] overflow-y-auto '>
                            {couponsIds.length == 0 ? (
                                <div className='flex flex-col items-center justify-center min-h-[50vh] text-white  bg-gradient-to-r from-primary to-blue-300  '>
                                    <p className='font-semibold'>{status === '' ? "YOU HAVEN'T POSTED ANY CODE" : "NO CODES FOR THIS FILTER"}</p>
                                </div>
                            ) : null}
                            
                            <div className='grid grid-cols-3 md:grid-cols-4 '>  
                                {couponsIds.map((id) => (
                                    <AccountCode logged={true} codeId={id} onClick={() => handleOnClick(id)} key={id} />
                                ))}
                            </div>
                        </div>

                    </div>
                    </Tab.Panel>
                    {/* requests */}
                    <Tab.Panel>
                        <div className='flex flex-col items-center justify-center h-[50vh] text-white bg-gradient-to-r from-primary to-blue-300  '>
                                <p className='font-semibold'> UPCOMING </p>
                        </div>
                    </Tab.Panel>
                    {/* saved */}
                    <Tab.Panel>
                        <div className='flex flex-col items-center justify-center h-[50vh] text-white bg-gradient-to-r from-primary to-blue-300  '>
                                <p className='font-semibold'> UPCOMING </p>
                        </div>
                    </Tab.Panel>
                    {/* ... */}
                </Tab.Panels>
            </Tab.Group>

            </div> 

           
             


            
        </div>
    </div>

    </>

  )
}

export default Account