import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Image imports
import logo from '../images/logo.png';
import { useRequestPasswordResetLinkMutation } from '../features/auth/authApiSlice';
import { useForm } from 'react-hook-form';
import { PasswordLinkRequest, PasswordLinkSchema } from '../types/formType';
import { zodResolver } from '@hookform/resolvers/zod';


const SendPasswordReset = () => {
  //Server calls
  const  [passwordRest , {isLoading: isLoadingPasswordRest}] =  useRequestPasswordResetLinkMutation()
  // Form
  const {register,handleSubmit, formState: {errors, isSubmitting}, reset} = useForm <PasswordLinkRequest>({resolver : zodResolver(PasswordLinkSchema)})
  //Timer
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  //Handlers
  const onSendResetLink = async (data: PasswordLinkRequest) => {
    try {
      const response = await passwordRest(data).unwrap();
      if (response){

      }setTimeRemaining(30);
    } catch (error) {
      setTimeRemaining(0);
    }
  };
  // Effect to manage the countdown timer
  useEffect(() => {
    if (timeRemaining > 0) {
      const timerId = setTimeout(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);
      return () => clearTimeout(timerId);  // Clean up the timeout when unmounting
    }
  }, [timeRemaining]);

  return (
    <div className='py-4 bg-background'> 
   
    <div className='container flex flex-col items-center justify-center min-h-screen px-2 mx-auto'>
        {/* Card  */}
        <div className='flex flex-col justify-center max-w-lg gap-6 px-6 py-16 shadow-2xl md:py-16 h-fit bg-card ring-2 ring-black ring-opacity-5 '>
          {/* Logo */}
          <div className='flex justify-center w-full px-4'>
            <img src={logo} alt="" className='object-contain w-2/3'/>
          </div>
          {/* Form */}
          <form onSubmit={handleSubmit(onSendResetLink)} className='flex flex-col h-full gap-6 py-12'>
            <div className='flex flex-col gap-2'>
              <input type="email" placeholder='Email' className='custom-input'  {...register("email")} />
              { errors.email && <span className='text-sm text-red-700 '> * {errors.email.message}</span> }
            </div>
            <button type="submit" className="w-full py-2.5 text-base font-semibold text-center text-white bg-primary rounded-md ">{timeRemaining > 0 ? `SENT (${timeRemaining} seconds)`:  'SEND RESET LINK'} </button>
          </form>
          {/* Request Another link */}
          <div className='flex flex-col justify-center gap-6 mt-4'>
            {timeRemaining > 0 ? (
              <p className='text-base font-normal text-center text-primary'>You can request another link in {timeRemaining} seconds.</p>
            ) : (
              <p className='text-base font-normal text-center text-primary'>Haven't received the link? Request again</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendPasswordReset;
