import { EntityId } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectSubscription, selectUserCouponById } from '../features/user/userSlice'
import CompanyFormView from '../components/CompanyFormView'
import AnalyticsData from '../components/AnalyticsData'
import { useActivateCouponMutation, useDeactivateCouponMutation, useDeleteCouponMutation, useEditCouponMutation } from '../features/user/userApiSlice'
import { showModal } from '../features/modal/modalSlice'
import { CreateCouponSchema, TCreateCoupon } from '../types/formType'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { AiFillCloseCircle } from 'react-icons/ai'
import { selectCurrentUser } from '../features/auth/authSlice'
import { Helmet } from 'react-helmet-async'


type InvidualCodeProps = {
    coupon_id : EntityId | undefined
    setShowCoupon  : React.Dispatch<React.SetStateAction<boolean>> 
    refetchSub : () => void
   

}
const InvidualCode = ({coupon_id, setShowCoupon, refetchSub}: InvidualCodeProps) => {
    const user  = useSelector(selectCurrentUser)


    const navigate =  useNavigate()
    const dispatch  = useDispatch()
    const coupon  =  useSelector(state =>  coupon_id != null ? selectUserCouponById(state, coupon_id) : undefined)
    const [selectedCompany, setSelectedCompany] = useState<EntityId | undefined>(coupon?.company._id);
    const subscription =  useSelector(selectSubscription)
  
    const {register, trigger, handleSubmit, clearErrors, setValue, formState: {errors, isSubmitting}, reset} = useForm <TCreateCoupon>({resolver : zodResolver(CreateCouponSchema)})
    const [editCoupon , {isLoading}] =  useEditCouponMutation()
    const [activeCoupon , {isLoading:  isActivateCouponLoading}] =  useActivateCouponMutation()
    const [deactivateCoupon , {isLoading:  isDeactivateCouponLoading}] =  useDeactivateCouponMutation()
    const [deleteCoupon , {isLoading:  isDeleteCouponLoading}] =  useDeleteCouponMutation() 

    const isEmpty = (value: string | null | undefined) => {
        return value == null || value.trim() === "";
    };
 
   //Use Effect
   useEffect(() => {
        if (selectedCompany !== undefined) {
            setValue('company', selectedCompany.toString(), { shouldValidate: true });
        }
    }, [selectedCompany, setValue]);

  useEffect(() => {
    if (coupon) {
      setValue('code', coupon.code);
      setValue('link', coupon.link);
      setValue('description', coupon.description);
      setValue('company', coupon.company._id);
      setValue('minValue', coupon.minValue);

      // Repeat for other fields as necessary
    }
  }, [coupon, setValue]);

  // Handlers
  const onUpdateCode =  async(data:TCreateCoupon ) => {
    if (selectedCompany === undefined){
      await trigger('company');
      return;
    }
    const stringCompany = selectedCompany != undefined?   selectedCompany.toString() : ''
    const request  = {id: coupon!._id, company :  stringCompany, link:data.link, description: data.description, code: data.code, minValue:  data.minValue}
   
    try {
    //   const result  =  await createCoupon(request).unwrap()
      const result  =  await editCoupon(request).unwrap()
      
      reset()
      setSelectedCompany(undefined)
      setShowCoupon(false)
    //TODO:change, only show the data is changed
    // const fieldsToCompare = ['company', 'link', 'description', 'code', 'minValue'];
    // const isDataChanged = fieldsToCompare.some(field => 
    //     (coupon as any)[field] !== (result.data as any)[field]
    //   );
        
          
      dispatch(showModal({ type: 'confirmation', message: 'We received your changes. Please allow 24 hours to review the code' ,
          redirectButtonText: 'OK' // Optional custom text for the redirect button
  
        }));
    } catch (error) { }
  }


  const handleActivateCode  =  async() =>{
    try {
        const result  =  await activeCoupon({id: coupon_id!.toString()}).unwrap()
        setSelectedCompany(undefined)
        setShowCoupon(false)
        refetchSub()
        dispatch(showModal({ type: 'confirmation', message: 'We received your activation. Please allow 24 hours to review the code' ,
            redirectButtonText: 'OK' // Optional custom text for the redirect button
    
          }));


    } catch (error) {}
  }

  const handleDeactivateCode  =  async() =>{
    try {
        const result  =  await deactivateCoupon({id: coupon_id!.toString()}).unwrap()
        setSelectedCompany(undefined)
        setShowCoupon(false)
        refetchSub()
        dispatch(showModal({ type: 'confirmation', message: "Your code was deactivated" ,
            redirectButtonText: 'OK' // Optional custom text for the redirect button
    
          }));


    } catch (error) {}
  }

  const handleDeleteCode  =  async() =>{
    try {
        const result  =  await deleteCoupon({id: coupon_id!.toString()}).unwrap()
        setSelectedCompany(undefined)
        setShowCoupon(false)
        refetchSub()
        dispatch(showModal({ type: 'confirmation', message: 'We deleted your code' ,
            redirectButtonText: 'OK' // Optional custom text for the redirect button
    
          }));


    } catch (error) {}
  }




  


  return (

    <>

 
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 pb-20 overflow-auto bg-gray-500 bg-opacity-75 md:p-6">
   
    <div className='relative flex flex-col w-full max-w-screen-lg gap-6 p-4 m-auto bg-white rounded-lg shadow-lg md:p-8 lg:flex-row'>

        {/* Cancel Button */}
        <div className='absolute flex flex-row justify-end top-3 right-6 lg:right-3 text-primary '>
                        {/* <div className='flex flex-col items-center justify-center flex-1 text-center'>
                            <p>Referal</p>
                        </div> */}
                        <button onClick={()=> setShowCoupon(false)}><AiFillCloseCircle className='w-8 h-8' /> </button>
         </div>
       
        {/* Other Content */}
   
        <div className="flex flex-col flex-1 mt-10 lg:mt-2 ">
            <form className='' onSubmit={handleSubmit(onUpdateCode)} >
                <div className=''>

                    {   subscription?.status !== "active"  && 
                        <div className="p-3 mb-3 text-orange-800 bg-orange-100 rounded-md">
                            * Warning: Not active/unpaid plan, reactivate your subscribtion.
                        </div>
                    }

                    {coupon?.status === "pending" && 
                        <div className="p-3 text-blue-800 bg-blue-100 rounded-md">
                            * Info: Your code will be reviewed in 24 hours, thanks
                        </div>
                    }

                    {coupon?.status === "rejected" && 
                        <div className="p-3 text-red-800 bg-red-100 rounded-md">
                            * Warning: Your code was rejected: {coupon.reason_rejection}
                        </div>
                    }

                    {coupon?.status === "disabled" && 
                        <div className="p-3 text-yellow-800 bg-yellow-100 rounded-md">
                            * Warning: Your code was disabled: {coupon.reason_disabled}
                        </div>
                    }

                    {coupon?.status === "deactivated" && 
                        <div className="p-3 text-gray-800 bg-gray-100 rounded-md">
                            * Info: Your code is not active, activate your code
                        </div>
                    }
                </div>
                
            

                <div className='flex flex-col gap-2 md:flex-row '>
                    {/* Image */}
                    <div className='flex flex-col items-center justify-center w-full md:w-1/4 '>
                        
                        <CompanyFormView  selectedCompany={selectedCompany} companyData={coupon?.company} setSelectedCompany={setSelectedCompany} />
                        { errors.company && <span  className='text-sm text-red-700'> * {errors.company.message}</span> }
          
                    </div>

                    {/* Right Part */}
                    <div className='flex flex-col flex-1 gap-2 md:px-4 md:py-4'>
                        {/* Link Input Field */}
                        <div>
                        <label>{'Link'}</label>
                        <input type="text"  placeholder="Enter your link" className="py-2 custom-input"  {...register("link")}  />
                        { errors.link && <span  className='text-sm text-red-700'> * {errors.link.message}</span> }
                        </div>
                        {/* Code Input Field  */}
                        <div>
                        <label>{'Code'}</label>
                        <input type="text"  placeholder="Enter your code (optional)" className="py-2 l custom-input "  {...register("code")} />
                        { errors.code && <span  className='text-sm text-red-700'> * {errors.code.message}</span> }
                        </div>
                        {/* Value Input field  */}
                        <div>
                        <label>{'Value'}</label>
                        <div className='flex flex-row '>
                        <div className='flex flex-col items-center justify-center px-4 text-white bg-primary '>
                            <p>$</p>
                        </div>
                            <input type="number" className='py-2 border-l-0 rounded-l-none custom-input' placeholder='Enter the value' {...register("minValue", { valueAsNumber: !isEmpty("minValue") })}  />
                        </div>
                        { errors.minValue && <span  className='text-sm text-red-700'> * {errors.minValue.message}</span> }
                        </div>

                    </div>

                </div>

                {/* Text area */}
                <div className='mt-5'>
                    <label htmlFor="message" className="block mb-1 text-base text-primary">Offer (Max 50 characters)</label>
                    <textarea id="message" rows={4} className="custom-input" placeholder="Write your thoughts here..."  {...register("description")} ></textarea>
                    { errors.description && <span  className='text-sm text-red-700'> * {errors.description.message}</span> }
                </div>
                    

                    
            

          
                <div className='flex flex-row flex-wrap items-center justify-center gap-2 mt-5 md:flex md:flex-row'>
                    {subscription?.status === "active" && user.is_active && (
                        <>
                            {coupon?.status !== "pending" && (
                                <button type='button' className='px-4 py-1 text-white bg-red-800 hover:bg-red-900' onClick={() => handleDeleteCode()}>
                                    Delete
                                </button>
                            )}
                            {coupon?.status === "deactivated" && (
                                <button type='button' className='px-4 py-1 text-white bg-green-700 hover:bg-green-800' onClick={() => handleActivateCode()}>
                                    Activate
                                </button>
                            )}
                            {coupon?.status === "approved" && (
                                <button type='button' className='px-4 py-1 text-white bg-orange-700 hover:bg-orange-800' onClick={() => handleDeactivateCode()}>
                                    Deactivate
                                </button>
                            )}

                            {(coupon?.status === "deactivated" || coupon?.status === "approved") && (
                                <button type='submit' className='px-4 py-1 text-white bg-slate-700 hover:bg-slate-800'>
                                    Update
                                </button>
                            )}

                          
                        </>
                    )}
                </div>


            </form>
           
       
        
        </div>
        {/* Right */}
      
    



        <div className='grid grid-cols-3 gap-5 md:gap-10 md:border-l-2 md:px-5 md:border-l-neutral-300-200 '>
        <AnalyticsData data={coupon?.views || 0} label={ "Views"} />        
        <AnalyticsData data={coupon?.clicks || 0}  label={ "Clicks"} />  
        <AnalyticsData data={coupon?.copyCode || 0}  label={ "Code"} />        
        <AnalyticsData data={coupon?.total_impressions || 0} label={ "Impression"} />   
        <AnalyticsData data={coupon?.priority || 1} label={ "Priority"} />        
        <AnalyticsData data={coupon?.saved || 0} label={ "Saved"} />         


       
        




        </div>
  
    
    </div>




    </div>
    </>
  )
}

export default InvidualCode

