import { EntityId } from '@reduxjs/toolkit';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { selectCompanyById } from '../../features/company/companySlice';
import SelectCompanyModal from '../SelectCompanyModal';
import  userDefault  from '../../images/default.png'
import { Company } from '../../types/modelType';


type CompanyFormViewProps = {
    selectedCompany?: EntityId | undefined
    setSelectedCompany :  React.Dispatch<React.SetStateAction<EntityId | undefined>>
    companyData? : Company,
}
  
const CompanyFormView = ({selectedCompany,setSelectedCompany, companyData} :  CompanyFormViewProps) => {

    // Selector, search if in the cache
    const companySelectedF = useSelector(state => {
      if (selectedCompany) {
        return selectCompanyById(state, selectedCompany);
      }
     
    });
    // If not available within the selector, then get the passed data
    const companySelected =   companySelectedF ||  companyData
    // Use State
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <div className='flex flex-col justify-center w-1/2 gap-2 px-2 py-2 mx-aut md:w-full '>
            <div className='w-full aspect-square'>
              <img src={companySelected?.image ? companySelected.image : userDefault}
                  className='object-cover w-full h-full rounded-md aspect-square' />
            </div>
            <div 
                className='py-1 text-center text-white rounded-md cursor-pointer bg-primary'
                onClick={openModal}>
                {companySelected ?  companySelected.name : "Select company"}
            </div>
            {isModalOpen && <SelectCompanyModal setSelected = {setSelectedCompany}  onClose = {closeModal} />}
        </div>
    );
  };
  

export default CompanyFormView