import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../../features/modal/modalSlice';
import { RootState } from '../../app/store/store';
import Modal from '../Modal';

const GlobalModal = () => {
  // useSelector to get the modal state
  const { isVisible, type, message, redirectPath, redirectButtonText } = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideModal());
  };
  return (
    <Modal 
      isVisible={isVisible}
      type={type}
      message={message}
      onClose={handleClose}
      redirectPath={redirectPath}
      redirectButtonText={redirectButtonText}
    />
  );
};

export default GlobalModal;
