import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';


type RejectReasonModalProps =  {
    title: string,
    isOpen:  boolean,
    setIsOpen:  React.Dispatch<React.SetStateAction<boolean>>
    handleDisableCompany: ( reason: string) =>{}
}
export default function RejectReasonModal({ title, isOpen, setIsOpen , handleDisableCompany } : RejectReasonModalProps) {
  const [inputValue, setInputValue] = useState('');

  // Handlers
  const  closeModal = () => {
    setInputValue('')
    setIsOpen(false);
  }
  
 const  handleSubmit = async() =>{
    try {
        const result =  handleDisableCompany(inputValue); // Replace 'id' with actual ID if needed
        closeModal();
    } catch (error) {}
  }
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <div className="fixed inset-0 bg-primary/25" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                   Specify a reason of why the content is being rejected or disabled.
                  </p>
                </div>

                {/* Input Field */}
                <div className="mt-4">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={e => setInputValue( e.target.value)}
                    className="custom-input"
                    placeholder="Enter your reason..."
                  />
                </div>

                <div className="flex flex-row justify-end w-full gap-2 mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-800 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={(closeModal)}
                  >
                    CANCEL
                  </button>


                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-primary hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={(handleSubmit)}
                  >
                      SUBMIT
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
