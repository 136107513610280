// src/api/apiSlice.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    // baseUrl : "https://api.uniqshare.com",
    baseUrl: process.env.NODE_ENV === "production" 
    ? process.env.REACT_APP_URL_PRODUCTION 
    : process.env.REACT_APP_URL_DEVELOPMENT,

    credentials: 'include',
    // prepareHeaders can be uncommented and used as needed
    // prepareHeaders(headers, { getState }) {
    //   // Use getState() to access the current state
    //   return headers;
    // },
  }),
  tagTypes: ['User', 'Coupon', 'Company', 'ModeratorCoupon', 'ModeratorCompany', 'ModeratorUser','Category', "UserCoupon", "AllUser"],
  endpoints: builder => ({
    // Define your endpoints here
  }),
});
