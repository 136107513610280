import React, { CSSProperties, ReactNode, useState } from "react";
import CouponStore from "../components/CouponStore";
import CouponCard from "../components/CouponCard";
import Banner from "../components/Banner";
import bannerImage from "../images/banner.jpg";
import Section from "../components/Section";
import {
    selectAllCategory,
} from "../features/categoryApiSlice/categoryApiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    useGetCompanyQuery,
    useGetPopularCompanyQuery,
    useUpdateCompanyViewsMutation,
} from "../features/company/companySlice";
import { Company, Coupon } from "../types/modelType";
import { useGetNewestCouponsQuery, useGetPopularCouponsQuery } from "../features/coupon/couponApiSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showModal } from "../features/modal/modalSlice";
import ViewDetailedCode from "./ViewDetailedCode";
import { EntityId } from "@reduxjs/toolkit";
import CompanyModal from "../components/CompanyModal";
import { ClipLoader } from "react-spinners";
import AlgorithmCard from "../components/AlgorithmCard";
import CouponCardSkeleton from "../components/CouponCardSkeleton";
import CouponStoreSkeleton from "../components/CouponStoreSkeleton";
import { Helmet } from "react-helmet-async";

interface CouponStoreProps {
    company: Company;
}



const Home = () => {
    const dispatch  =  useDispatch() 
    const navigate  =  useNavigate()
    const [searchParams] = useSearchParams();
    const auth = searchParams.get('success'); // This will retrieve the
    const [visibleCount, setVisibleCount] = useState(6);
    const showMore = () => {
        setVisibleCount(selectAllCategories.length); // or any other number you wish to increase by
      };

    if (auth === "true"){
        dispatch(showModal({
  
          type: 'error',
          message: 'Failed authetication',}
        ))
    }
  
  
    //Fetching 
    const { data: popularCoupons, isLoading:  isLoadingPopularCoupons } = useGetPopularCouponsQuery({ limit: 4 });
    const { data: popularCompanies ,isLoading:  isLoadingPopularCompanies } = useGetPopularCompanyQuery({ limit: 6 });
    const { data: newestCoupons , isLoading:  isLoadingNewest } = useGetNewestCouponsQuery({ limit: 4 });
    const [  updateViews , {isLoading: isLaodingUpdatingView}]   = useUpdateCompanyViewsMutation()
    //Selectors
    const selectAllCategories = useSelector(selectAllCategory);
    const renderBanners = (count: number) => {
        return Array(count).fill(null).map((_, idx) => <Banner key={idx} />);
    };
    const [couponSelected, setCouponSelected] =  useState<EntityId |  undefined>(undefined)
    const [couponSelectedData, setCouponSelectedData] =  useState<Coupon |  undefined>(undefined)
    const [showCoupon , setShowCoupon] =  useState(false)

    const handleOnClick  =  (id : EntityId, coupon_data: Coupon) =>{
  
        setCouponSelectedData(coupon_data)
      setCouponSelected(id)
      setShowCoupon(true)
  
      
    }



  const handleNavigate = async(id : string) =>{
    const result = await updateViews(id); 
    navigate(`/companies/${id}`);

    }

    const handleNavigateCategory = (category_id :  string) =>{
        navigate(`/search?category=${category_id}`);
    
    }
    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "black",
      };
    
    
      let [color, setColor] = useState("#ffffff");

    const title = "Uniqshare | Referral Codes, Coupons Codes, and Share Affiliate Links";
    const description = "Post your referrals and affiliate codes to earn money, find new passive incoem, and reach new shoppers.";
    const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/`;

    return (

        <>   

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonicalUrl} />
        </Helmet>


            {showCoupon ?  <ViewDetailedCode open={showCoupon}  coupon_id={couponSelected} setShowCoupon={setShowCoupon} coupon_data={couponSelectedData}/> : null}
    
        
          
        
        <div className="px-5 py-2 md:py-2 bg-background">
      



            <div className="container mx-auto ">

           
                


                {/* Most Popular */}

                {/* Banner Section */}
                {/* <div className='flex flex-row gap-2 overflow-x-auto scrollbar-hide md:gap-10 snap-x '>
                    {renderBanners(10)}
                </div> */}

                <Section title="Popular Stores">
                    {
                        isLoadingPopularCompanies &&  <CouponStoreSkeleton cards={6} />
                    }
                    <div className="grid grid-cols-2 gap-2 md:gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                   
                 
                        {popularCompanies?.data.map((company) => (
                            <CouponStore onClick={() => handleNavigate(company._id)} company_data={company.company} key={company._id}/>
                        ))}
                    </div>
                </Section>

                <Section title="Most Popular">
{/* 
                    {isLoading && <div className='flex flex-col items-center justify-center h-full p-10 '>

                        <ClipLoader
                        color={color}
                        loading={isLoading}
                        cssOverride={override}
                        size={40}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        />
                        </div>} */}
                    {isLoadingPopularCoupons &&
                        <CouponCardSkeleton  cards={4}/>
                    }
                       
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-4">

                        

                        {popularCoupons?.data.map((coupon) => (
                            <CouponCard onClick={() => handleOnClick(coupon._id, coupon)} coupon_data={coupon} key={coupon._id}/>
                        ))}

                        
                    </div>
                  
                </Section>

            

                {/* Most Popular stores */}
             
                {/*Banner sections */}

                <section className="p-10 mt-10 md:p-14 rounded-2xl banner-section bg-primary text-textprimary">
                    <div className="banner-content lg:w-1/2">
                        <h2 className="text-3xl md:text-5xl banner-title">Discover & Share Exclusive Codes</h2>
                        <p className="mt-5 text-base md:text-xl banner-description">
                            Join our community of savvy shoppers. Share and discover referral and affiliate codes to maximize savings and earnings on your favorite products.
                        </p>
                    </div>
                </section>


                
             

                {/* New Added */}
                <Section title="New Added">

                    {isLoadingNewest &&
                        <CouponCardSkeleton  cards={4}/>
                    }
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-4">
                        {/* {renderCouponCards(6)} */}
                        {newestCoupons?.data.map((coupon) => (
                            <CouponCard onClick={() => handleOnClick(coupon._id, coupon)} coupon_data={coupon} key={coupon._id} />
                        ))}
                    </div>
                </Section>

                       {/* Categories */}

                <Section title="Categories">
               
                 <div className="grid grid-cols-3 gap-2 mt-2 md:flex md:flex-row md:gap-5 md:flex-nowrap md:overflow-x-auto scrollbar-hide">
                    {selectAllCategories.map((category, index) => (
                        <div key={index} className="py-3 rounded-md cursor-pointer md:px-10 bg-primary text-textprimary" onClick={()=> handleNavigateCategory(category._id)}>
                             <p className="text-sm text-center truncate md:text-base">{category.name} </p>
                        </div>
                    ))}
                </div>
                </Section>
                <AlgorithmCard />

                {/* Banner */}

                {/* Recently Used */}
                {/* <Section title="Recently Used">

                </Section> */}

                {/* Ending */}


               
               
            </div>

           
            
         
        </div>
        <Banner />
      
      
      



        </>
    );
};



export default Home;

