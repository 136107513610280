import React, { CSSProperties, useEffect, useState } from 'react'
import SingleCompanyCard from '../components/SingleCompanyCard'
import CouponStore from '../components/CouponStore'
import CouponCard from '../components/CouponCard'
import { useDispatch, useSelector } from 'react-redux'
import {  getSearchResult, useGetSearchResultQuery } from '../features/search/searchSlice'
import { Company, Coupon } from '../types/modelType'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { EntityId } from '@reduxjs/toolkit'
import  userDefault  from "../images/default.png";
import ViewDetailedCode from './ViewDetailedCode'
import { ClipLoader } from 'react-spinners'
import { Helmet } from 'react-helmet-async'

const SearchResults = () => {

    const [searchParams] = useSearchParams();
    const query = searchParams.get('query'); // This will retrieve the
    const category = searchParams.get('category'); // This will retrieve the

    const {data, isLoading, isFetching} = useGetSearchResultQuery({search: query, category: category})
    // const results  =  useSelector(getSearchResult)

    const navigate  = useNavigate()

    const viewAllCompannies =  () =>{
        if (category != null && query  != null){
            navigate(`/companies?query=${query}&category=${category}`)
        }else if (query  != null) {
        
            navigate(`/companies?query=${query}`)
        }else {
            navigate(`/companies?category=${category}`)
        }

       
    }
    const viewAllCode =  () =>{
        if (category != null && query  != null){
            navigate(`/codes/?query=${query}&category=${category}`)
        }else if (query  != null) {
        
            navigate(`/codes?query=${query}`)
        }else {
            navigate(`/codes?category=${category}`)
        }
    }

    const viewAllUsers =  () =>{
        navigate(`/users/?query=${query} `)
    }



    const [couponSelected, setCouponSelected] =  useState<Coupon |  undefined>(undefined)
    const [showCoupon , setShowCoupon] =  useState(false)
  

  const handleOnClick  =  (coupon : Coupon) =>{

        
    setCouponSelected(coupon)
    setShowCoupon(true)

    
  }

  const handleNavigate = (id : string) =>{
    navigate(`/companies/${id}`);

}
const handleOnUserClick  = (id : string) =>{
    navigate(`/users/${id}`)

}
const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "primary",
  };


  let [color, setColor] = useState("#1f2937");


  if (isLoading || isFetching){

    return (

      <div className='h-screen bg-background'>

        <div className='flex flex-col items-center justify-center h-full'>

          <ClipLoader
          color={color}
          loading={isLoading}
          cssOverride={override}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />


        </div>


     </div>
    )

  }


    const title = "Uniqshare | Search Results";
    const description = "Explore a wide range of referral codes, coupons, and users on Uniqshare. Find exactly what you're looking for with our comprehensive search feature.";
    const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/search`;

   
  return (

    <>
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    
    
    
    {showCoupon ?  <ViewDetailedCode open={showCoupon} coupon_data={couponSelected} setShowCoupon={setShowCoupon}/> : null}
    
    
   
    <div className='min-h-screen py-10'> 
   
        <div className='container flex flex-col gap-10 p-5 mx-auto '>

            <h1 className='text-2xl font-semibold md:text-3xl lg:text-4xl'> All Search Results  {query &&  `for ${query}`}</h1>
            {/* {isLoading && 
      
                <div className='flex flex-col items-center justify-center h-[70vh] p-10 '>
            
                    <ClipLoader
                    color={color}
                    loading={isLoading}
                    cssOverride={override}
                    size={40}
                    aria-label="Loading Spinner"
                    data-testid="loader"/>
                </div>
         
            } */}

            {/* Companies */}
            {isFetching === false &&  isLoading === false &&
                    <div className='flex flex-col gap-4'>

                    <div className='flex flex-row items-center justify-between '>
                    <p className='text-xl font-semibold md:text-2xl'> Companies ({data?.data.totalCompanies})</p>
                        <button className='px-3 py-1.5 text-white bg-primary' onClick={()=>viewAllCompannies()}> View All </button>
                    </div>

                    <div className="grid grid-cols-2 gap-2 md:gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                        { data?.data.companies.map((company : Company) =>
                            <CouponStore  company_data={company}  company_id={''}  onClick={() =>handleNavigate(company._id)} key={company._id}/>
                        )}
                    
                    </div>


                </div>


            }


            {/* Coupons */}
            {isFetching === false &&   isLoading === false &&
            <div className='flex flex-col gap-4'>

                <div className='flex flex-row items-center justify-between '>
                   <p className='text-xl font-semibold md:text-2xl'> Codes ({data?.data.totalCoupons})</p>
                    <button className='px-3 py-1.5 text-white bg-primary' onClick={()=>viewAllCode()}> View All </button>
                </div>

                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-4">

                    {data?.data.coupons.map((coupon : Coupon) =>
                           <CouponCard  onClick={()=> handleOnClick(coupon)} coupon_data = {coupon}  key={coupon._id}  />

                    )}
                   
                
                </div>


            </div>
             }
            {/* Users */}
            {isFetching === false &&   isLoading === false &&

            <div className='flex flex-col gap-4'>

                <div className='flex flex-row items-center justify-between '>
                   <p className='text-xl font-semibold md:text-2xl'> Users ({data?.data.totalUsers})</p>
                    <button className='px-3 py-1.5 text-white bg-primary' onClick={()=>viewAllUsers()}> All Users</button>
                </div>

                <div className='grid grid-cols-3 gap-5 md:gap-8 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7'>
                    {data?.data.users.map((user) =>(
                    <div className='flex flex-col items-center gap-2 p-2'  onClick={() =>handleOnUserClick(user._id)} key={user._id}>
                        <img src={user.image ? user.image :  userDefault } className='rounded-full' />


                        <div className=''>
                        {user.username}
                        </div>

                    </div>


                    ))}


                </div>

            </div>
             }

            {/* Requests */}


        </div>
    </div>

    </>
  )
}

export default SearchResults