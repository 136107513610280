import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { showModal } from '../features/modal/modalSlice';
import { useCreateCouponMutation } from '../features/coupon/couponApiSlice';
import CompanyFormView from '../components/CompanyFormView';
import { useForm } from 'react-hook-form';
import { CreateCouponSchema, TCreateCoupon } from '../types/formType';
import { zodResolver } from '@hookform/resolvers/zod';
import { selectCouponMonthlyLimit, selectCouponUsed, selectSubscription } from '../features/user/userSlice';
import { Link, useNavigate } from 'react-router-dom';
import { usePortalBillingMutation } from '../features/user/userApiSlice';
import { selectCurrentUser } from '../features/auth/authSlice';
import { Helmet } from 'react-helmet-async';



const CreateCode2 = () => {
  const navigate  =  useNavigate()
  const  dispatch  = useDispatch()
  // Selectors
  const montlyLimit  =  useSelector(selectCouponMonthlyLimit)
  const used  =  useSelector(selectCouponUsed)
  const [selectedCompany, setSelectedCompany] = useState<EntityId | undefined>(undefined);

  const user = useSelector(selectCurrentUser)

  // Server Calls
  const [createCoupon , {isLoading}] =  useCreateCouponMutation()
  const [requestPortalBilling , {isLoading : isPortalBillingLoading}] =  usePortalBillingMutation()   
  const {register, trigger, handleSubmit, clearErrors, setValue, formState: {errors, isSubmitting}, reset} = useForm <TCreateCoupon>({resolver : zodResolver(CreateCouponSchema)})

  // Empty 
  const isEmpty = (value: string | null | undefined) => {
    return value == null || value.trim() === "";
  };
  const subscription =  useSelector(selectSubscription)
  
  //Use Effect
  useEffect(() => {
    if (selectedCompany !== undefined) {
      setValue('company', selectedCompany.toString(), { shouldValidate: true });
    }
  }, [selectedCompany, setValue]);

  // Handlers
  const onCreateCode =  async(data:TCreateCoupon ) => {
    if (selectedCompany === undefined){
      await trigger('company');
      return;
    }

    if (user.is_active !== true){
      dispatch(showModal({ type: 'error', message:  "You account has been disabled" ,
        redirectPath: '/plan', // The path where you want to redirect the user
        redirectButtonText: 'Contact us' // Optional custom text for the redirect button
      
      }));
      return
    }
    if (subscription?.status !== "active"){
      dispatch(showModal({ type: 'error', message:  "You don't have an active subscription" ,
        redirectPath: '/plan', // The path where you want to redirect the user
        redirectButtonText: 'Purchase Plan' // Optional custom text for the redirect button
      
      }));
      return
    }

   
    const stringCompany = selectedCompany != undefined?   selectedCompany.toString() : ''
    const request  = {company :  stringCompany, link:data.link, description: data.description, code: data.code, minValue:  data.minValue}
   
    try {
      const result  =  await createCoupon(request).unwrap()
      setSelectedCompany(undefined)
      reset()
      navigate('/account')
    } catch (error) { }
  }

  const handleManageAccount  = async()=>{
    try {
        const data  =  await requestPortalBilling().unwrap()
        const url  = data.data
        if (!url){
            dispatch(showModal({
                type: 'error',
                message: 'Portal is missing',
            }));
            return 
        }
        window.location.href = url; 
    } catch (error) {
    }
  }

  const title = "Uniqshare | Post Referral";
  const description = "Our copyright policy";
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/create`;

  return (
    <>
     <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
    </Helmet>
    <div className='flex flex-col md:justify-center md:min-h-screen md:py-10 bg-background'>
        

    <div className='flex flex-col py-5 mx-auto md:rounded-lg md:shadow-2xl pop-sheet bg-background md:bg-card ' style={{ boxShadow: '0 10px 25px rgba(0,0,0,0.2)' }}>
        
       
        
        <form className='' onSubmit={handleSubmit(onCreateCode)} >
        {/* Title */}
        <div className='py-3 text-3xl text-center border-b-2 border-primary'>
            <h1 className='text-2xl font-bold md:text-3xl'>CREATE YOUR CODE</h1>
        </div>


        {
          user?.is_active !== true ? (
            <div className="p-3 my-3 text-red-800 bg-red-100 rounded-md">
              <p>* Warning: Your account has been deactivated, please <Link to="/plan" className='underline'>contact us</Link>.</p>
            </div>
          ) : subscription?.status !== "active" ? (
            <div className="p-3 my-3 text-orange-800 bg-orange-100 rounded-md">
              <p>* Warning: Not active subscription, please <Link to="/plan" className='underline'>subscribe</Link>.</p>
            </div>
          ) : montlyLimit === used ? (
            <div className="p-3 my-3 text-orange-800 bg-orange-100 rounded-md">
              <p>* Warning: You've reached your limit for the month. <button onClick={handleManageAccount} className='underline'>Upgrade</button></p>
            </div>
          ) : null
        }






        
       
        {/* Top Part */}
        <div className='flex flex-col gap-2 py-5 md:flex-row'>
              {/* Image */}
              <div className='flex flex-col items-center justify-center w-full mt-2 md:w-1/4 '>
                <CompanyFormView selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} />
                { errors.company && <span  className='text-sm text-red-700'> * {errors.company.message}</span> }
              </div>

              {/* Right Part */}
              <div className='flex flex-col flex-1 gap-2 md:px-4 md:py-4'>
                {/* Link Input Field */}
                <div className='flex flex-col gap-1'>
                  <label>{'Link'}</label>
                  <input type="text"  placeholder="Enter your link" className="py-2 custom-input"  {...register("link")}  />
                  { errors.link && <span  className='text-sm text-red-700'> * {errors.link.message}</span> }
                </div>
                {/* Code Input Field  */}
                <div className='flex flex-col gap-1'>
                  <label>{'Code'}</label>
                  <input type="text"  placeholder="Enter your code (optional)" className="py-2 custom-input"  {...register("code")} />
                  { errors.code && <span  className='text-sm text-red-700'> * {errors.code.message}</span> }
                </div>
                {/* Value Input field  */}
                <div className='flex flex-col gap-1'>
                  <label>{'Value'}</label>
                  <div className='flex flex-row '>
                  <div className='flex flex-col items-center justify-center px-4 text-white bg-primary '>
                      <p>$</p>
                  </div>
                      <input type="number" className='py-2 border-l-0 rounded-l-none custom-input' placeholder='Enter the value' {...register("minValue", { valueAsNumber: !isEmpty("minValue") })}  />
                  </div>
                  { errors.minValue && <span  className='text-sm text-red-700'> * {errors.minValue.message}</span> }
                </div>

              </div>

        </div>

        {/* Text area */}
        <div className='mt-5'>
            <label htmlFor="message" className="block mb-1 text-base text-primary">Offer (Max 50 characters)</label>
            <textarea id="message" rows={4} className="custom-input" placeholder="Write your thoughts here..."  {...register("description")} ></textarea>
            { errors.description && <span  className='text-sm text-red-700'> * {errors.description.message}</span> }
        </div>
        {/* Subscription and info */}
        <div className='flex flex-row justify-end gap-1 mt-1 text-end'>
            
            <p>{`${montlyLimit - used }`} coupon left this month.</p>  
            <span>{
              subscription?.status !== "active" ? (
                <p>
                  <Link to="/plan" className='underline'>Subscribe</Link>
                </p>
              ) : (
                <button onClick={handleManageAccount} className='underline'>Upgrade</button>
              )
            }</span> 

            
        </div>



        {/* Create Button */}
        <div className='mt-2 text-end'>
            <button className='px-4 py-1 text-white transition-colors rounded-md bg-primary hover:bg-gray-800 disabled:opacity-30' disabled={isSubmitting || isLoading || !user.is_active || used === montlyLimit}>Create</button>
        </div>
        {/* End Form */}


      
        </form>
      </div>
  </div>

  </>

  )
}

export default CreateCode2

