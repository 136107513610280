import { ApiError } from "../types/apiType";

export const handleApiError = (error: ApiError) => {
    console.error('Error status code:', error.status);
    console.error('Error data:', error.data);
    console.error('Error message:', error.data.message);
    console.error('Error type:', error.data.errorType);
    
    // You can also add other common error handling logic here, 
    // like sending the error to an error tracking service, etc.
  };