import React from 'react';
import { Helmet } from 'react-helmet-async';

const Privacy = () => {


     //SEO
     const title = "Uniqshare | Privacy";
     const description = "Uniqshare privacy policy";
     const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/privacy`;
 
  return (

    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
    </Helmet>
    
    
   
    <div className="min-h-screen py-10 text-base bg-background">
      {/* Container */}
      <div className="px-4 mx-auto container-2">
        <h1 className="mb-6 text-3xl font-bold text-center">Privacy Policy for UniqShare </h1>
        <p className="mb-4">Effective Date: 01/01/2024</p>

        <h2 className="mb-3 text-2xl font-semibold">1. Introduction</h2>
        <p className="mb-4">
          Welcome to UniqShare . We are committed to protecting the privacy and security of our users. This Privacy Policy outlines how we collect, use, and protect the personal information of our users and adheres to the 2018 EU General Data Protection Regulation (GDPR).
        </p>

        <h2 className="mb-3 text-2xl font-semibold">2. Information Collection</h2>
        <p className="mb-4">
          We collect information during registration, including name, email address, and login credentials. When users register via third-party services like Google, we receive similar types of information. For subscription and payment, we process data through Stripe.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">3. Use of Information</h2>
        <p className="mb-4">
          We use the information to manage user accounts, provide our services, process payments, and communicate with users. We also use this information for email verification, subscription management, and reviewing user-submitted content.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">4. Data Sharing and Disclosure</h2>
        <p className="mb-4">
          We do not sell user information. We may share data with third parties for business operations, such as payment processing, and as required by law.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">5. User Rights and Data Management</h2>
        <p className="mb-4">
          Users have rights to access, correct, or delete their personal information. We ensure data accuracy and allow users to reset passwords and edit their account information.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">6. Data Security</h2>
        <p className="mb-4">
          We implement security measures to protect user data from unauthorized access and misuse. Our platform is designed to maintain data integrity and confidentiality.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">7. Third-Party Services</h2>
        <p className="mb-4">
          Registering or linking accounts with third-party services (like Google) results in us receiving similar information directly from those services.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">8. GDPR Compliance</h2>
        <p className="mb-4">
          We comply with GDPR standards for data protection and privacy for individuals within the European Union.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">9. Changes to the Privacy Policy</h2>
        <p className="mb-4">
          We reserve the right to modify this policy at any time. Changes will be posted on our website with an updated effective date.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">10. Contact Us</h2>
        <p>
          For any questions or concerns regarding this privacy policy, please contact us at contact@uniqshare.com.
        </p>
      </div>
    </div>

    </>
  );
}

export default Privacy;
