import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectCurrentEmail, selectCurrentUser } from '../features/auth/authSlice';

interface ModeratorRoutesProps {
    children?: ReactNode;
}

const ModeratorRoutes = () => {
    const currentUserEmail = useSelector(selectCurrentEmail);
    const currentUser = useSelector(selectCurrentUser);

    const location = useLocation();

    // If the user is not authenticated, redirect them to the login page
    if (!currentUserEmail) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!currentUser.verified){
        return  <Navigate to="/verify" state={{ from: location }} />;
    }
    if (currentUser.role  != "moderator"){
      return  <Navigate to="/404" state={{ from: location }} />;
    }

    // If the user is authenticated, render the nested routes
    return <Outlet />;
}

export default ModeratorRoutes;
