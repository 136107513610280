import  userDefault  from '../../images/default.png'
import {Coupon} from '../../types/modelType'
import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectCouponById } from '../../features/coupon/couponApiSlice';
import { formatDateWithTime, limitTextLength } from '../../utils/formatting';



interface CouponCardProps {
    coupon_id?: EntityId;
    onClick : () => void
    coupon_data? : Coupon

  }

const CouponCard = ({coupon_id, onClick, coupon_data} :  CouponCardProps) => {


    const coupon = useSelector(state => {
        if (coupon_id) {
            // Convert to a number if your EntityId is a number
            return selectCouponById(state, coupon_id);
            
        }
        return coupon_data; // or a suitable default/fallback
    });
    return (   
       
    <div className='flex flex-col gap-2 px-3 py-3 transition-shadow duration-300 ease-in-out bg-white border-2 border-gray-100 shadow-xl rounded-xl hover:shadow-2xl' onClick={onClick}>
        {/* <p> {coupon?.impression}</p>
        <p> {coupon?.priority}</p>
        <p> {formatDateWithTime( coupon!.lastSeen_search_placemenet || "")}</p> */}
        {/* Top Part  */}
      
        <div className='flex flex-row h-14'>

            <img src= {coupon?.company.image ?  coupon?.company.image  : userDefault}  className='h-full rounded-md w-14 '/>
            <div className='flex flex-col ml-3 overflow-hidden'>
                <div className=''> 
                    <p className='text-xl truncate'> {coupon?.company.name }</p>
                </div>

                <div className=''>
                    <p className='text-sm truncate'>{coupon?.category.name} </p>
                    
                </div>

            </div>
       
        </div>
        {/* Middle Part */}
        <div className='py-2 min-h-[40px] md:h-[60px] '>
            <p> {limitTextLength( coupon?.description, 50)} </p>
        </div>
        {/* BUtton Part */}
        <div className='flex flex-row justify-end'>
            <button className='px-4 py-1 font-medium text-white rounded-md bg-button'> SHOW </button>
        </div>

    
    

    
    </div>
   
  )
}

export default CouponCard