
import React, { Fragment, useEffect, useState } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import {AiFillCloseCircle} from 'react-icons/ai'
import { Coupon } from '../types/modelType'
import { useDispatch, useSelector } from 'react-redux'
import { selectCouponById, useUpdateCouponVewClicksMutation, useUpdateCouponVewCopyCodeMutation, useUpdateCouponViewsMutation } from '../features/coupon/couponApiSlice'
import { Dialog, Transition } from '@headlessui/react'
import { MdCopyAll } from "react-icons/md";
import  userDefault  from '../images/default.png'
import { formatDateWithYear } from '../utils/formatting'
import { useNavigate } from 'react-router-dom'
import { selectCurrentUser } from '../features/auth/authSlice'
import { Helmet } from 'react-helmet-async'
import { hideMenu, isModalOpenFalse, isModalOpenTrue, showMenu } from '../features/mobileMenu/mobileMenu'




type ViewDetailedCodeProps =  {
    open: boolean;
    coupon_id?: EntityId;
    setShowCoupon: React.Dispatch<React.SetStateAction<boolean>>
    coupon_data? : Coupon

}
type ViewedCoupons = {
  [key: string]: { expiry: number };
};

const ViewDetailedCode = ({open,coupon_id,setShowCoupon, coupon_data }: ViewDetailedCodeProps) => {

const navigate =  useNavigate()
const dispatch  = useDispatch()

const [  updateViews , {isLoading: isLaodingUpdatingView}]   = useUpdateCouponViewsMutation()

const [  updateClick , {isLoading: isLoadindUpdatingClicks}]   = useUpdateCouponVewClicksMutation()
const [  updateCopyCode , {isLoading: isLoadingCoyCode}]   = useUpdateCouponVewCopyCodeMutation()
const couponSelector = useSelector(state => {
        if (coupon_id) {
            // Convert to a number if your EntityId is a number
            return selectCouponById(state, coupon_id);
            
        }
        return coupon_data; // or a suitable default/fallback
    });
    const coupon =  couponSelector ||  coupon_data


    const closeModal = () => {
     
        dispatch(isModalOpenFalse())
        dispatch(showMenu());
     
        setShowCoupon(false)
        
    }
    
  const  openModal =  async() =>  {
    setShowCoupon(true)
    
  }


    


  const handleVisitProfile = () =>{
    navigate(`/users/${coupon?.userId._id}`)
  }


  const user  =  useSelector(selectCurrentUser)
  
  const [updatedViewCount, setUpdatedViewCount] = useState(false);


  const checkViewAndUpdate = async (couponId: string) => {
    const viewedCoupons: ViewedCoupons = JSON.parse(localStorage.getItem('viewedCoupons') || '{}');
    
      if (viewedCoupons[couponId] && (new Date().getTime() < viewedCoupons[couponId].expiry)) {
          return; // Coupon has already been viewed and not expired
      }

      // ... code to update view in the backend

      if (user._id === coupon?.userId._id) {
        return;
      }
      try {
        const couponToSend = coupon_id ? coupon_id : coupon_data?._id;
        await updateViews(couponToSend?.toString());
      } catch (error) {
        console.error(error);
      }

      

      // Set the new expiry time (24 hours later)
      viewedCoupons[couponId] = { expiry: new Date().getTime() + (24 * 60 * 60 * 1000) };
      localStorage.setItem('viewedCoupons', JSON.stringify(viewedCoupons));
  };

  // useEffect(() => {
  //   const updateOnMount = async () => {
  //     if (user._id === coupon?.userId._id) {
  //       return;
  //     }
  //     try {
  //       const couponToSend = coupon_id ? coupon_id : coupon_data?._id;
  //       await updateViews(couponToSend?.toString());
  //       console.log("Sent");
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   if (!updatedViewCount) {
  //     updateOnMount();
  //     setUpdatedViewCount(true);
  //   }
  // }, []);

  useEffect(() => {
    // dispatch(hideMenu());

    if (open) {
      dispatch(isModalOpenTrue())
      // Hide the mobile menu when the ViewDetailedCode component is opened
      dispatch(hideMenu());
     

      if (coupon_id) {
          checkViewAndUpdate(coupon_id.toString());
      }
  }
  }, [open, coupon_id]);

 



  // useEffect(() => {
    
   
  
  // }, []); // The empty dependency array ensures this runs only once on mount



  const [updateClickSent, setUpdateClickSent] =  useState(false)
  const handleUpdateClick = async()=>{
    if (updateClickSent){
      return
    }
    try {
      const couponToSend = coupon_id ? coupon_id : coupon_data?._id;
      const result = await updateClick(couponToSend?.toString());
      setUpdateClickSent(true)
    } catch (error) {
      // Handle the error here
      console.error(error);
    }

  }
  const [updateClickCode, setUpdateClickCode] =  useState(false)
  const [showCopiedMesssage, setCopiedMessage ] =  useState(false)

  const handleUpdateCodeClick = async () => {
   
    const code = coupon?.code;
    if (!code) {
      return;
    }

  
    try {
      setCopiedMessage(true)
      await navigator.clipboard.writeText(code);
      

      if (updateClickCode){
        await new Promise(resolve => setTimeout(resolve, 3000));
        setCopiedMessage(false)
        return
      }

     
      // dispatch(showModal({ type: 'confirmation', message: 'Code copied in the clipboard' ,
      //     redirectButtonText: 'OK' // Optional custom text for the redirect button
  
      // }));
  
      // Determine the correct coupon ID to send
      const couponToSend = coupon_id || coupon_data?._id;
      if (!couponToSend) {
        console.error("No coupon ID available for updating");
        return;
      }
  
      const result = await updateCopyCode(couponToSend.toString());
      
      setUpdateClickCode(true)
    } catch (error) {}

    await new Promise(resolve => setTimeout(resolve, 3000));
    setCopiedMessage(false)

  };
 
  return (
    
    <> 
     
      

      <Transition appear show={open} as={Fragment}>
    
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
       
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-primary/25" />
          </Transition.Child>

          <div className="fixed inset-0 py-0 overflow-y-auto md:py-16">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-4 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-2xl ring-2 ring-black ring-opacity-10">
            
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 " >
                    <div className='flex flex-row justify-between gap-2'>
                        {/* <div className='flex flex-col items-center justify-center flex-1 text-center'>
                            <p>Referal</p>
                        </div> */}
                         { 
                          
                          <div className={`flex-1 flex flex-row  gap-1 p-2  text-sm text-white bg-primary  rounded-md transition-opacity duration-500 ease-in-out ${showCopiedMesssage ? 'opacity-100' : 'opacity-0'}`}>

                            <MdCopyAll className='w-6 h-6'/>
                            <p>Code was copied succesfully </p>
                          </div>



                        }
                        <button onClick={()=> closeModal()}><AiFillCloseCircle className='w-8 h-8' /> </button>
                    </div>
                       

              
                  


                
                   
                </Dialog.Title>
               
               
                    <div className='flex flex-col justify-around w-1/2 px-2 mx-auto md:py-2'>
                        <img src={coupon?.company.image ? coupon.company.image :  userDefault} className=' aspect-square' />
                        <div  className='py-2 text-center text-white cursor-pointer bg-primary' onClick={()=>{}}>
                                {coupon?.company.name}
                        </div>
                        
                    </div>

                    <div className='flex flex-col gap-2 mt-6 text-center md:mt-10'>
                        <p className='text-xl font-bold'> USE & EARN ${coupon?.minValue}   </p>
                       <p className='text-center'>{coupon?.description}</p>
                    </div>


                    <div className='flex flex-col items-center gap-2 mt-8 text-center md:mt-10'>
                        {/* Dotted button */}
                        <div  className="flex flex-row items-center justify-between gap-2 px-8 py-2 transition-all border-2 border-gray-500 border-dotted hover:scale-105 cursor-text" onClick={()=>handleUpdateCodeClick()}><MdCopyAll className='w-6 h-6'/> <p>{coupon?.code ? coupon?.code.toUpperCase() : "ALREADY APPLIED CODE" }  </p></div>
                        <a href={coupon?.link}  target="_blank" className='px-10 py-2 text-white bg-primary w-fit'> <button onClick={()=> handleUpdateClick()}>Activate Code →  </button></a>


                        {/* button */}
                   
                    </div>

                    {/* <div className='flex flex-row items-center justify-end gap-2 mt-10'>
                        <p> Posted By</p>

                        <div className=' aspect-square'>
                      
                        <img 
                            src={ userDefault}
                            className='object-cover w-8 h-8 rounded-md' 
                          />

                        </div>
                        
                    </div> */}
                    
                   
                   

                    <div className='flex flex-col py-2 mt-4 md:mt-10'>
                      <div className='flex items-center'>
                          <div className='flex-grow h-px bg-primary'></div>
                          <h1 className='mx-2'> Posted By</h1>
                          <div className='flex-grow h-px bg-primary'></div>
                      </div>


                      <div className='flex flex-row items-center justify-between mt-2'>
                        <div className='flex flex-row items-center gap-2 text-base'>


                          <div className=''>
                            <img src={userDefault} alt="" className='w-16 h-16 rounded-full aspect-square' />

                          </div>


                          <div className='flex-1 '>
                                <p className='text-lg font-semibold'> {coupon?.userId.username} </p>
                                <p className='text-sm italic font-light'>  Member since {coupon?.userId.createdAt ?  formatDateWithYear( coupon?.userId.createdAt): "2022"}</p>
                                {/* <p> Active codes: 245 </p> */}
                        

                          </div>
                        </div>
                        <div>
                        <button className='px-4 py-1 text-white bg-primary ' onClick={()=>handleVisitProfile()}>
                          VISIT
                        </button>
                      </div>


                      </div>



                    </div>


                    {/* <div className='py-4 mt-10 border-t-2 border-gray-200'>
                      <div className='grid grid-cols-3 '>
                        <div className='flex flex-col items-center justify-center gap-2'>
                          <AiFillLike className='w-6 h-6' />
                          Working

                        </div>

                        <div className='flex flex-col items-center justify-center gap-2'>
                          <AiFillDislike className='w-6 h-6' />
                          No Working

                        </div>

                        <div className='flex flex-col items-center justify-center gap-2'>
                          <FaBookmark className='w-6 h-6' />
                          Save

                        </div>

                      </div>
                        
                    </div>

 */}

                    

                    


                  

                   

                
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  



    // <div className="fixed inset-0 z-50 flex items-center justify-center p-6 pb-20 overflow-auto bg-gray-500 bg-opacity-75">
   
    // <div className='relative flex flex-col w-full max-w-screen-lg gap-6 p-8 m-auto bg-white rounded-lg shadow-lg md:flex-row'>
       

    //     <div className="absolute top-0 p-2 right-2">
   
    //         <button onClick={()=> setShowCoupon(false)}><AiFillCloseCircle className='w-8 h-8' /> </button>
           
    //     </div>
    //     <div className="flex flex-col justify-around flex-1">

            
           
            
            

        
    //             <div className='flex flex-col gap-2 md:flex-row'>
    //                 {/* XCOmpany view */}

    //                 <div className='flex flex-col justify-around w-1/2 gap-4 px-2 py-2 mx-auto md:w-1/4 '>
    //                     <img src={userDefault} className='rounded-md' />
    //                     <div 
    //                         className='py-1 text-center text-white cursor-pointer bg-primary'
    //                         onClick={()=>{}}
    //                     >
    //                         {"Select company"}
    //                     </div>

                        
    //                 </div>
    //                 <div className='flex flex-col justify-between flex-1 gap-2 px-4 py-4'>
    //                     {/* right part */}

    //                     <h1 className='text-xl font-semibold'>USE AND EARN UP TO $20</h1>
    //                     <h1>{coupon?.company.name}</h1>
    //                     <h1>Get 25% off on selected products</h1>


    //                     <div className='flex flex-row justify-between'> 
    //                      <button className='px-16 py-0.5 text-white bg-primary '> CODE </button>
    //                      <button className='px-16 py-0.5 text-white bg-primary '> LINK </button>


    //                     </div>
    //                 </div>

    //             </div>
    //             {/* <div className='flex flex-col gap-2'>

    //                 <h1 className='text-xl font-medium'> More Codes</h1>
    //                 <div className='flex flex-row gap-2 overflow-x-auto scrollbar-hide md:gap-10 snap-x'>
    //                     {renderBanners(10)}
    //                 </div>

                



    //             </div> */}
               
               
   
                
       
        
    //     </div >
    //     {/* Right */}
      
    


    //     <div className='flex flex-col justify-between gap-5 px-5 py-2 border-l-2 lg:w-1/3 border-l-neutral-300'>
        
    //         <div className='flex flex-col gap-2'>
    //             <h1 className='text-lg font-medium'>Terms & Conditions</h1>
    //             <p>Amazon.com, Inc. is an American multinational tech firm specializing in e-commerce, cloud computing, digital streaming, online advertising, and AI. Recognized as a major economic and cultural force globally, it's among the most valuable brands and part of the Big Five U.S. tech giants, which include Alphabet (Google), Apple, Meta, and Microsoft.</p>
    //         </div>
        

    //         <div className='grid grid-cols-3 gap-10 px-5 '>

            
        
    //             <AnalyticsData data={100000} label={ "Like"} />        
    //             <AnalyticsData data={10} label={ "Used"} />  
    //             <AnalyticsData data={10} label={ "Views"} />        








    //         </div>


          
    //          {/* User  */}
    //         <div>
    //             <div className='flex flex-row gap-3 '>
    //                 <div className=''>
    //                 <img src={userDefault} alt="" className='w-16 h-16 aspect-square' />

    //                 </div>

    //                 <div className='flex-1'>
    //                 <p> Username </p>
    //                 <p> Member Sinece 2019 </p>
    //                 <p> Active codes: 245 </p>
                    

    //                 </div>

                    

    //             </div>

    //             <div className='flex flex-row mt-2'>
    //                     <button className='w-full text-sm text-white bg-primary'> Visit Profile </button>

    //             </div>
                


    //         </div>


    //     {/* Next */}
       
    

    //     </div>
  
    
    // </div>




    // </div>
  )
}

export default ViewDetailedCode