// React and Router: Core functionalities for building UI components, managing state, and handling navigation
import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// Static assets: Logo image for UI display
import logo from '../images/logo.png';

// Utilities and API: Functions and types for error handling, and API interactions for authentication and user data
import { handleApiError } from '../utils/errorHandlers';
import { ApiError } from '../types/apiType';
import { useLoginMutation } from '../features/auth/authApiSlice';

// Redux: Hooks for state management within React components
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../features/auth/authSlice';

// User data and form handling: Hooks for lazy user data fetching and form management with validation
import { useLazyGetUserSubscriptionQuery } from '../features/user/userApiSlice';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginRequest, LoginSchema } from '../types/formType';

// UI feedback: Function to display modal dialogs for user feedback
import { showModal } from '../features/modal/modalSlice';
import { Helmet } from 'react-helmet-async';



const Login = () => {
  // Navigation and Redux dispatch hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Retrieving URL search parameters to check authentication status
  const [searchParams] = useSearchParams();
  const auth = searchParams.get('success');

  // Display an error modal if authentication fails
  if (auth === "false") {
    dispatch(showModal({
      type: 'error',
      message: 'Failed authentication',
    }));
  }

  // API calls: Lazy query for user subscription and mutation for login
  const [getData] = useLazyGetUserSubscriptionQuery();
  const [login, { isLoading }] = useLoginMutation();

  // Form handling setup with React Hook Form and Zod for validation
  const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm<LoginRequest>({
    resolver: zodResolver(LoginSchema)
  });

  // Handler function for login form submission
  const onLogin = async (data: LoginRequest) => {
    try {
      // Attempt login and handle user data on success
      const userData = await login(data).unwrap();
      dispatch(setUser(userData.data));

      // Redirect user based on their verification status
      navigate('/auth/success')

      // const { verified } = userData.data;
      // verified ? navigate("/account") : navigate("/verify");
    } catch (error) {
      // Handle API errors
      handleApiError(error as ApiError);
    }
  };
  const title = "Uniqshare | Login";
  const description = "Login to your Uniqshare account";
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/login`;

  return (
    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
    </Helmet>
    <div className='py-2 bg-background'> 
   
    <div className='container flex flex-col items-center justify-center min-h-screen px-2 mx-auto'>
      {/* Card container  */}
      <div className='max-w-lg px-6 py-8 mx-auto shadow-2xl md:py-10 ring-2 ring-black ring-opacity-5 bg-card'>
          {/* Form with logo */}
          <div className='flex flex-col gap-6'>
            {/* Logo */}
              <div className='flex justify-center w-full '>
                  <img src={logo} alt="Logo" className='object-contain w-2/3'/>
              </div>
            {/* Form */}
              <form onSubmit={handleSubmit(onLogin)} className='flex flex-col gap-8 py-8'>
                  <div className='flex flex-col gap-2'>
                    <input type="email" placeholder='Email' className='custom-input'   {...register("email")}/>
                    { errors.email && <span className='text-sm text-red-700 '> * {errors.email.message}</span> }
                  </div>

                  <div className='flex flex-col gap-2'>
                    <input type="password"  placeholder='Password' className='custom-input'   {...register("password")}   />
                    { errors.password && <span  className='text-sm text-red-700'> * {errors.password.message}</span> }

                  </div>
                  <button type="submit" className="w-full px-6 py-2.5 text-base font-semibold text-center text-white rounded-md bg-primary " disabled={isSubmitting}> LOGIN</button>
                  <Link to="/send-password-link"><p className='text-base font-normal text-center text-primary'>Forgotten your password?</p></Link>
              </form>
          </div>

          {/* Divider */}
          <div className='relative flex items-center py-2 mx-3'>
              <div className="flex-grow border-t border-primary"></div>
              <span className="flex-shrink mx-4 text-primary">OR</span>
              <div className="flex-grow border-t border-primary"></div>
          </div>
          {/* Google Login */}
          <div className='py-2 mx-3 '>
          <a href={`${process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL_PRODUCTION : process.env.REACT_APP_URL_DEVELOPMENT}/auth/google`} className="flex items-center justify-center w-full py-2.5 text-base transition duration-300 ease-in-out transform bg-white border border-gray-300 rounded-md hover:scale-105 hover:shadow-lg">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="w-6 h-6" viewBox="0 0 48 48">
                    <defs>
                        <path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/>
                    </defs>
                    <clipPath id="b">
                        <use xlinkHref="#a" overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/>
                    <path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/>
                    <path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/>
                    <path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/>
                </svg>
                  <span className="ml-4 font-medium text-primary">Login with Google</span>
              </a>
          </div>
          {/* Don't have an account */}
          <div className='py-5 mx-3 mt-4'>
              <p className='font-normal text-center text-md text-primary'>Don't have an account yet? <Link to="/register" className='font-bold'  > Sign Up</Link></p>
          </div>

      </div>
      {/* End Card Container */}
    </div>

    </div>
   
    </>
  )
};

export default Login;
