import { createEntityAdapter, createSelector, EntityState } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ApiResponse } from "../../types/apiType";
import { Category } from "../../types/modelType";
import { sortBy } from "lodash";

// Define a type for Category




type CategoryResponse = ApiResponse<Category[]>;



interface CreateCategoryRequest extends Pick<Category, 'name' | 'description'> {
    parent?: string;
}

interface ServerError {
    status: 'error';
    message: string;
    statusCode: number;
    errorType: string;
}

const categoryAdapter = createEntityAdapter<Category>({
    selectId: (category) => category._id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});

// Define the state shape for categories
type CategoryState = EntityState<Category>;

const initialCategoryState: CategoryState = categoryAdapter.getInitialState();



export const categoryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCategory: builder.query<CategoryResponse, void>({
            query: () => '/category/all',
            transformResponse: (baseQueryReturnValue: unknown) => {
                const responseData = baseQueryReturnValue as CategoryResponse;
                
                return responseData;
            },
            providesTags: (result?: CategoryResponse, error?: FetchBaseQueryError) => {
                if (error) {
                    return [];
                }
                
                if (result) {
                    return [
                        { type: 'Category' as const, id: "LIST" },
                        ...result.data.map(category => ({ type: 'Category' as const, id: category._id }))
                    ];
                }
                
                return [];
            }
        }),
        createCategory: builder.mutation<Category, CreateCategoryRequest>({
            query: (object) => ({
                url: 'category/create',
                method: 'POST',
                body: object
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Category", id: "LIST" }
            ]
        }),
    })
});

export const {
    useGetCategoryQuery,
    useCreateCategoryMutation
} = categoryApiSlice;


export const selectCategoryResults = categoryApiSlice.endpoints.getCategory.select();

const transformCategoryResponseToState = (categoryResponse: CategoryResponse): CategoryState => {
    // Use the categoryAdapter to transform the array of categories into the desired state shape
    return categoryAdapter.setAll(initialCategoryState, categoryResponse.data);
}

const selectCategoryData = createSelector(
    selectCategoryResults,
    (categoryResult) => {
        if (categoryResult && categoryResult.data) {
            return transformCategoryResponseToState(categoryResult.data);
        }
        return initialCategoryState;
    }
);

export const selectCategoryResponseStatusAndMessage = createSelector(
    selectCategoryResults,
    (categoryResult) => {
        if (categoryResult && categoryResult.data) {
            const { status, message } = categoryResult.data;
            return { status, message };
        }
        return { status: "", message: "" };
    }
);



export const {
    selectAll: selectAllCategory,
    selectById: selectCategoryById,
    selectIds: selectCategoryIds,
} = categoryAdapter.getSelectors((state: any) => selectCategoryData(state) ?? initialCategoryState);
