import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="p-8 text-white bg-gray-900">
            <div className="container grid grid-cols-1 gap-8 mx-auto md:grid-cols-4">
                {/* Customer Service */}
                <div>
                    <h2 className="mb-3 text-lg font-semibold">Company </h2>
                    <ul className="flex flex-col space-y-2">
                        <Link to='/about' className="hover:underline" >About Us</Link>
                        <Link to='/contact' className="hover:underline">Help</Link>
                        <Link to='/faqs' className="hover:underline">FAQ</Link>
                        <Link to='/contact' className="hover:underline">Support</Link>
                    </ul>
                </div>
                {/* Information */}
                <div>
                    <h2 className="mb-3 text-lg font-semibold">Product</h2>
                    <ul className="flex flex-col space-y-2">
                        <Link to='/create' className="hover:underline">Create Code</Link>
                        <Link to='/companies' className="hover:underline">Request Brand</Link>
                        <Link to='/company/requests' className="hover:underline">Requests</Link>
                        <Link to='/plan' className="hover:underline">Subscribe</Link>
                    </ul>
                </div>

                {/* Account */}
                <div>
                    <h2 className="mb-3 text-lg font-semibold">Legal</h2>
                    <ul className="flex flex-col space-y-2">
                        <Link to='privacy' className="hover:underline">Privacy </Link>
                        <Link to='copyright' className="hover:underline">Copyright</Link>
                        <Link to='terms' className="hover:underline">Terms </Link>
                        <Link to='cookies' className="hover:underline">Cookies</Link>
                    </ul>
                </div>

                {/* Social */}
                <div>
                    <h2 className="mb-3 text-lg font-semibold">Follow Us</h2>
                    <ul className="flex space-x-4">
                        <a href="https://twitter.com/" className="hover:underline">X</a>
                        <a href="https://www.facebook.com/" className="hover:underline">Facebook </a>
                        <a href="https://www.instagram.com/" className="hover:underline">Instagram</a>
                    </ul>
                </div>
            </div>

            <div className="pt-4 mt-8 text-center border-t border-gray-700">
                <p>©2023 UniqShare, All Rights Reserved.</p>
            </div>
        </div>
    );
}

export default Footer;