

export  const  isPositiveNumber  = (value : string) => {
    const num = Number(value);
    return num > 0 && String(num) === value;
}


export const convertImageToBase64 = (file: File) :  Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
  
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
  
    reader.readAsDataURL(file);
  });
  


  