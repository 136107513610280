import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom'

import { Provider } from 'react-redux'
import { store , persistor} from './app/store/store';
import { searchApiSlice } from './features/search/searchSlice';
import { categoryApiSlice } from './features/categoryApiSlice/categoryApiSlice';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from 'react-error-boundary';
import { companyApiSlice } from './features/company/companySlice';
import { userApiSlice } from './features/user/userApiSlice';
import {SkeletonTheme}  from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { HelmetProvider } from 'react-helmet-async';

store.dispatch(categoryApiSlice.endpoints.getCategory.initiate());

// store.dispatch(searchApiSlice.endpoints.getPopularSearch.initiate());
// store.dispatch(companyApiSlice.endpoints.getCompany.initiate({page:0, limit: 5}));


const root = ReactDOM.createRoot(
  
  document.getElementById('root') as HTMLElement
);
root.render(
  
  <React.StrictMode>

    <HelmetProvider >
      <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
      <BrowserRouter>
          <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
              <App />
            </ErrorBoundary>
            </PersistGate>
          </Provider>
      </BrowserRouter>
      </SkeletonTheme>
    </HelmetProvider>
   
  </React.StrictMode>
);

