import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Helmet } from 'react-helmet-async';


    const faqs = [
        {
          question: "What unique features does UniqShare offer?",
          answer: "Our platform specializes in sharing and discovering referral codes. We offer a subscription-based model that eliminates bots, ensuring authenticity. Our unique algorithm ensures equal visibility for all referral codes."
        },
        {
          question: "How does the referral code system work?",
          answer: "Users can submit and find referral codes for various companies. Each code is displayed based on an algorithm that gives equal opportunity to all subscribers, with higher-tier subscribers receiving more visibility."
        },
        {
          question: "Can I request a company to be added for referral codes?",
          answer: "Yes, users can request new companies to be added to our platform. These requests are reviewed, and upon approval, the company will be added for sharing referral codes."
        },
        {
          question: "What are the subscription tiers available?",
          answer: "We offer multiple subscription tiers, each providing different levels of visibility for your referral codes and varying monthly coupon limits."
        },
        {
          question: "How does the subscription model benefit me?",
          answer: "Subscribing to our service gives you the ability to post referral codes, higher priority in code visibility based on your subscription level, and access to exclusive features."
        },
        {
          question: "What is the process for verifying my account?",
          answer: "For local registrations, email verification is required. This process ensures the authenticity of each user on our platform."
        },
        {
          question: "How do I manage my subscription and payments?",
          answer: "Subscriptions and payments can be managed directly through your account settings. Payments are securely processed via Stripe."
        },
        {
          question: "Is there a way to track the performance of my referral codes?",
          answer: "Yes, you can track the status and performance of your referral codes through your account page, including how often they are viewed and used."
        },
        {
          question: "Are there any guidelines for posting referral codes?",
          answer: "All referral codes must comply with our guidelines and not infringe on copyrights or trademarks of other parties. We review and approve codes to ensure compliance."
        },
        {
          question: "What happens if a referral code I posted is rejected?",
          answer: "If a referral code is rejected, it could be due to non-compliance with our guidelines or if it's deemed inappropriate. You will be notified and can make necessary changes for re-submission."
        },
        {
          question: "Can I edit or remove my referral codes?",
          answer: "Yes, you can edit or remove your referral codes at any time through your account."
        },
        {
          question: "How does UniqShare ensure the integrity of referral codes?",
          answer: "We have strict policies in place to prevent fraudulent activities and regularly monitor for suspicious behavior to maintain the integrity of the referral codes on our platform."
        }
        // Add any additional FAQs if needed
      ];
      
  

const Faq = () => {

  const title = "Uniqshare | FAQs";
  const description = "Our FAQs policy";
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/faqs`;

  return (

    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
    </Helmet>
    <div className="py-4 md:py-16 bg-background">
      <div className="px-4 mx-auto container-2">
        <div className="w-full max-w-2xl p-4 py-6 mx-auto md:shadow-2xl bg-background md:bg-card rounded-2xl">
          <h1 className="mb-6 text-2xl font-semibold text-center">Frequently Asked Questions</h1>
          <p className="mb-8 text-center">
            Welcome to ReferralCodes! Here, we provide detailed information about our site and answer common questions to help you make the most of our services.
          </p>

          {faqs.map((faq, index) => (
            <Disclosure key={index} as="div" className="mt-4">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-800 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <span>{faq.question}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-600">
                    {faq.answer}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
    </>
  );
}

export default Faq;
