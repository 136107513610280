import { useState } from 'react'
import Searchbar from '../../SearchBar'
import { useApproveCompanyMutation, useDisableCompanyMutation, useGetModeratorCompanyQuery, useRejectCompanyMutation } from '../../../features/moderator/moderatorApiSlice'
import {  Company } from '../../../types/modelType'
import CouponStore from '../../CouponStore'
import { useNavigate } from 'react-router-dom'
import RejectReasonModal from '../../RejectReasonModal'

const CompanyModerator = () => {
  const navigate  = useNavigate()
  // Use State
  const [page , setPage ] = useState(1)
  const [search , setSearch]  =  useState('')
  const [limit  , setLimit] = useState(5)
  const [sortField ]  = useState('name')
  const [sortOrder ] =  useState('asc')
  const [status, setStatus] =  useState('approved')
  // Modals
  const [isModalDisableOpen, setIsDisableModalOpen] = useState(false);
  const [clickedDisableId , setDisableClikedId]  = useState<string | undefined>(undefined)
  const [isModalRejectOpen, setIsRejectModalOpen] = useState(false);
  const [clickedRejectId , setRejectClikedId]  = useState<string | undefined>(undefined)

  // Api
  const object  = {page :  page, search : search, limit : limit, sort:sortField ,  order: sortOrder, status: status }
  const [approveCompany, {isLoading: isLoadingApproveCompany}] =  useApproveCompanyMutation()
  const [rejectCompany, {isLoading: isLoadingRejectCompany}] =  useRejectCompanyMutation()
  const [disableCompany, {isLoading: isLoadingDisableCompany}] =  useDisableCompanyMutation()
  const { data, error, isLoading } = useGetModeratorCompanyQuery(object);
  

  // Handlrs
  const handleApproveCompany = async(id : string) =>{
    try {
        const result  = await approveCompany(id)
        
    } catch (error) {}
  }

  // const handleRejectCompany = async(id : string) =>{
    //   try {
    //       const result  = await rejectCompany(id)
          
    //   } catch (error) {}
    // }
  const handleDisableCompany = async( disable_reason : string) =>{

    if (clickedDisableId === undefined){
      
      setIsDisableModalOpen(false)
      return
    }
    const object  =  {id : clickedDisableId, reason_disabled: disable_reason}
    try {
      
        const result  =  disableCompany(object)
        setIsDisableModalOpen(false)
        setDisableClikedId('')
        
    } catch (error) {}
  }
  const handleRejectCompany = async( rejected_reason : string) =>{
    if (clickedRejectId === undefined){
      setIsRejectModalOpen(false)
      return
    }
    const object  =  {id : clickedRejectId, reason_rejected: rejected_reason}
    try {
      
        const result  =  rejectCompany(object)
        setIsRejectModalOpen(false)
        setRejectClikedId('')
        
    } catch (error) {}
  }

  const handleNavigate = (id : string) =>{
      navigate(`/companies/${id}`);

  }
  const openModalDisable = ( id : string) =>{
    setIsDisableModalOpen(true)
    setDisableClikedId(id)
  }

  const openModalRejection = ( id : string) =>{
    setIsRejectModalOpen(true)
    setRejectClikedId(id)
  }

  const handleNextPage = () => {
    setPage(page  + 1 )
  }

  const handlePrevPage = () => {
    // Check if the current page is greater than 0 before decrementing
    if (page > 1) {
        setPage(page - 1);
    }
  }

  const handleSearchQueryChange = (query : string) => {
    setSearch(query)
  };

  return (

    <>
     <RejectReasonModal title='Disable Company' isOpen={isModalDisableOpen} setIsOpen={setIsDisableModalOpen} handleDisableCompany={handleDisableCompany} />
     <RejectReasonModal title='Reject Company' isOpen={isModalRejectOpen} setIsOpen={setIsRejectModalOpen} handleDisableCompany={handleRejectCompany} />
    
    <div>

        <div className='w-1/2'>
          <Searchbar required_click={false} placeholder={'Search Companies'} query={search} setQuery={setSearch}  onSearchQueryChange={handleSearchQueryChange} onSelecteCategoryChange={()=>{}}/>
       </div>

        <div className='flex flex-row justify-between mt-10'>
          <div className='flex flex-row gap-2 '>
            <button className={`px-4 py-1 text-white ${status === 'pending' ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus('pending')}> Pending </button>
            <button className={`px-4 py-1 text-white ${status === 'approved' ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus('approved')}> Approved </button>
            <button className={`px-4 py-1 text-white ${status === 'rejected' ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus('rejected')}> Rejected </button>
            <button className={`px-4 py-1 text-white ${status === 'disabled' ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus('disabled')}> Disabled </button>
          </div>

          <div>
            <p> The are total  {data?.data.total}</p>
          </div>
        </div>

        <div className=' md:h-[70vh] overflow-auto'>
            <div className='grid grid-cols-2 gap-8 mt-8 md:grid-cols-4 lg:grid-cols-6'>
                {data?.data?.companies && Array.isArray(data.data.companies) ? (
                data.data.companies.map((company: Company, index: number) => (
                  <div className=''>
                    <CouponStore key={index} company_data={company}  onClick={() => handleNavigate(company._id)}/>

                    {company.status === "pending" && <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                          <button className='w-1/2 bg-green-900 rounded-sm' onClick={()=> handleApproveCompany(company._id)}> Approve </button>
                          <button className='w-1/2 bg-red-800 rounded-sm'   onClick={()=> openModalRejection(company._id)}> Reject </button>
                      </div> 
                  
                    }

                    {company.status === "approved" && <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                          <button className='w-full bg-red-800 rounded-sm' onClick={()=> openModalDisable(company._id)}> Disable </button>
                      </div>
                  
                    }

                    {company.status === "rejected" && <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                            <button className='w-full bg-green-900 rounded-sm' onClick={()=> handleApproveCompany(company._id)}> ReApprove </button>
                        </div>
                    
                    }


                    {company.status === "disabled" && <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                        <button className='w-full bg-green-900 rounded-sm'  onClick={()=> handleApproveCompany(company._id)}> Approve </button>
                    </div>
                
                    }


                  </div>


                    
                ))
                ) : (
                <p>No companies to display</p>
                )}
            </div>
          </div>

        <div className='flex flex-row items-center justify-center gap-2 text-center'>
          <button className='px-4 py-3 text-white bg-primary' onClick={() => handlePrevPage()}>Previous </button>
          <span> {page}</span>
          <button className='px-4 py-3 text-white bg-primary'  onClick={() =>handleNextPage()}>Next</button>
        </div>

    </div>

    </>
  )
}

export default CompanyModerator