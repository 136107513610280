import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllCategory, selectCategoryIds } from '../../features/categoryApiSlice/categoryApiSlice';
import { useCreateCompanyMutation } from '../../features/company/companySlice';
import { Listbox, Transition } from '@headlessui/react';
import  userDefault  from '../../images/default.png'
import { convertImageToBase64 } from '../../utils/helperFunction';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateCompanySchema, TCreateCompany } from '../../types/formType';
import { Category } from '../../types/modelType';
import { showModal } from '../../features/modal/modalSlice';
import { selectCurrentUser } from '../../features/auth/authSlice';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

interface ModalFormProps {
  
}
const initialFormData = {
  name: '',
  description: '',
  website: '',
  category: '',
  founded: '',
  image: '' ,
};

const CompanyModal = () => {

  //Company
  const dispatch = useDispatch()
  // Categories
  const categories  = useSelector(selectAllCategory);
  const user  = useSelector(selectCurrentUser)
  // Api Calls
  const [createCompany, { isLoading }] = useCreateCompanyMutation();
  // Use State
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined); 
  // Form
  const {register, trigger, handleSubmit, clearErrors, setValue, formState: {errors, isSubmitting}, reset} = useForm <TCreateCompany>({resolver : zodResolver(CreateCompanySchema)})
  // Handlers
  
  // -Input changes
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement; // Cast to HTMLInputElement
    if (target.type === 'file') {
        const files = target.files;
        if (files && files.length > 0) {
          const file = files[0];
        try { 
          const base64String = await convertImageToBase64(file);
          setImagePreview(base64String); // Set image preview
        } catch (error) {}
      }
    } 
  };
  // -Select category
  const handleSelectChange = (value: Category | undefined) => {
    setSelectedCategory(value);

  };
  // - Cancel the creation
  const handleCancel = () => {

    setImagePreview(null);
    setSelectedCategory(undefined);
    reset()
    // onClose();
  };
  
  // - Create new company
  const onSubmit = async (data: TCreateCompany) => {
    if (selectedCategory === undefined){
      await trigger('category');
      return;
    }

    if (imagePreview === null){
      await trigger('image');
      return;
    }
    if (isLoading) return; // Prevent double submission
    if (!selectedCategory) {
      console.error("Please select a category before submitting.");
      return;
    }

    if (user.is_active !== true){
      dispatch(showModal({ type: 'error', message:  "You account has been disabled" ,
        redirectPath: '/plan', // The path where you want to redirect the user
        redirectButtonText: 'Contact us' // Optional custom text for the redirect button
      
      }));
      return
    }
    try {
      const result = await createCompany(data).unwrap();
      setImagePreview(null);
      setSelectedCategory(undefined)
      reset()
      // onClose();

      dispatch(showModal({ type: 'confirmation', message: 'We received your request. Please allow 24 hours to review the brand.' ,
          redirectButtonText: 'OK' // Optional custom text for the redirect button
  
        }));
    } catch (error) { }
  };

    //Use Effect
  useEffect(() => {
      if (selectedCategory !== undefined) {
        setValue('category', selectedCategory._id.toString(), { shouldValidate: true });
      }
    }, [selectedCategory, setValue]);

  useEffect(() => {
      if (imagePreview !== null) {
        setValue('image', imagePreview, { shouldValidate: true });
      }
    }, [imagePreview, setValue]);
  

    const title = "Uniqshare | Request Brand";
    const description = "Can't find a brand? Request a new brand to be featured on Uniqshare and start posting new refarrals and couponds.";
    const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/request/company`;
  
  return (

    <>
    
    
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    <div className="flex flex-col md:min-h-screen md:justify-center md:py-10 bg-background">
      {/* Card */}
      <div className='flex flex-col pt-8 pb-10 mx-auto md:rounded-lg md:shadow-2xl pop-sheet bg-background md:bg-card ' style={{ boxShadow: '0 10px 25px rgba(0,0,0,0.2)' }}>
        {/* Title*/}
        <form  className='flex flex-col gap-6 ' onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full p-2 text-center border-b-2 border-primary">
          <h1 className='text-2xl font-bold md:text-3xl'>
            REQUEST NEW BRAND
          </h1>
        </div>

        {
          user?.is_active !== true && 
            <div className="p-3 my-3 text-red-800 bg-red-100 rounded-md">
              <p>* Warning: Your account has been deactivated, please <Link to="/plan" className='underline'>contact us</Link>.</p>
            </div>
        }
        {/* Image Container */}
        <div className="flex flex-col items-center justify-center">
          <div className="w-1/2 mb-4 overflow-hidden bg-gray-100 rounded-lg md:w-1/3 aspect-square">
            {/* Image */}
            <img  src={imagePreview ? imagePreview.toString() : userDefault}  alt="Preview"  className="object-cover w-full h-full"  />
          </div>
          {/* Upload Button */}
          <label htmlFor="image-upload" className="px-3 py-2 text-sm font-medium text-white rounded-md cursor-pointer bg-primary hover:bg-blue-50 hover:text-blue-700 focus:outline-none focus:ring" >
            Upload Image
          <input  id="image-upload" type="file" accept="image/*" className="hidden" onChange={handleFileChange} />
          </label>
          { errors.image && <span  className='text-sm text-red-700'> * {errors.image.message}</span> }
        </div>
        {/* Name of the company */}
        <div className='flex flex-col gap-2'>
          <input type="text"placeholder="Brand Name" className="custom-input" {...register("name")} />
          { errors.name && <span  className='text-sm text-red-700'> * {errors.name.message}</span> }
        </div>
      
        {/* Category  */}
        <Listbox value={selectedCategory} onChange={handleSelectChange}>
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full py-2.5 pl-3 pr-10 text-left bg-white border-2 border-gray-200 rounded-md cursor-default focus:outline-none  sm:text-sm">
                <span className="block truncate">
                  {selectedCategory ? selectedCategory.name : "Select Category"}
                </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    {/* <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" /> */}
                  </span>
                </Listbox.Button>
                { errors.category && <span  className='text-sm text-red-700'> * {errors.category.message}</span> }
                <Transition
                  show={open}
                  as={React.Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {categories.map((category) => (
                      <Listbox.Option
                        key={category._id}
                        className={({ active }) =>
                          `cursor-default select-none relative py-2 pl-10 pr-4 ${
                            active ? 'text-white bg-primary' : 'text-gray-900'
                          }`
                        }
                        value={category}
                      >
                        {({ selected }) => (
                          <>
                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                              {category.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      {/* TextArea */}
      <div className='flex flex-col gap-2'>
        <textarea  rows={4} placeholder="Description" className="custom-input"  {...register("description")}  />
        { errors.description && <span  className='text-sm text-red-700'> * {errors.description.message}</span> }
      </div>
      {/* Url */}
      <div className='flex flex-col gap-2'>
        <input type="text"  placeholder="Website URL" className="custom-input" {...register("website")} />
        { errors.website && <span  className='text-sm text-red-700'> * {errors.website.message}</span> }
      </div>
      {/* Date  */}
      <div className='flex flex-col gap-2'>
        <input type="date"    placeholder="Founded Date" className="custom-input" {...register("founded")}  />
        { errors.founded && <span  className='text-sm text-red-700'> * {errors.founded.message}</span> }
      </div>
      {/* Button */}
       <div className='grid grid-cols-2 gap-4 text-sm'>
          <button className='py-2 text-white rounded-md bg-primary' onClick={handleCancel}> CANCEL </button>
          <button type="submit" className='py-2 text-white rounded-md bg-primary disabled:opacity-30' disabled={ !user?.is_active || isLoading  ||  isSubmitting}> SUBMIT </button>
       </div>
       </form>
      </div>
    </div>
    </>
  );
};

export default CompanyModal;
