import { AiFillAppstore } from 'react-icons/ai';
import { IoQrCodeSharp, IoAddCircle } from 'react-icons/io5';
import { MdAccountCircle } from 'react-icons/md';
import { Menu, Transition } from '@headlessui/react';
import { FaEye } from "react-icons/fa";
import { IoIosMore } from "react-icons/io";
import { IoExit } from "react-icons/io5";
import { MdAddBusiness } from "react-icons/md";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, selectCurrentEmail } from '../../features/auth/authSlice';
import { useLogoutMutation } from '../../features/auth/authApiSlice';
import { resetUserState } from '../../features/user/userSlice';
import { showModal } from '../../features/modal/modalSlice';
import { FaHome } from "react-icons/fa";

import { GrUpgrade } from "react-icons/gr";
import { useEffect, useState } from 'react';
import { hideMenu, selectMobileMenuIsShown, selectMobileModalIsOpen, showMenu } from '../../features/mobileMenu/mobileMenu';
const MobileMenu = () => {

    const dispatch  =  useDispatch()
    const navigate  =  useNavigate()
    // Selectors
    const userEmail  =  useSelector(selectCurrentEmail)
    // Api
    const [logout , {isLoading}] =  useLogoutMutation()
    // Handlers
    const handleLogout  = async () =>{

        try {
            const resultData  =  await logout({}).unwrap()
            dispatch(deleteUser())
            dispatch(resetUserState())

            dispatch(showModal({
                type: 'confirmation',
                message: 'You have been logged out.',
            
              }));
              
            
        } catch (error) {
            
        }

        
       
    }

    const handleUpgrade = () => {
        navigate('/plan'); // Navigate to /plan
        // Call the close function here if available
    };
    const handleRequestBrand = () => {
        navigate('/request/company'); // Navigate to /plan
        // Call the close function here if available
    };
    const handleBradRequests = () => {
        navigate('/company/requests'); // Navigate to /plan
        // Call the close function here if available
    };
    const handleSupport = () => {
        navigate('/contact'); // Navigate to /plan
        // Call the close function here if available
    };
    const handleHome = () => {
        navigate('/'); // Navigate to /plan
        // Call the close function here if available
    };


   const show  =  useSelector(selectMobileMenuIsShown)
    // const [show, setShow] = useState(true);
    // ... existing code ...

    const isModalOpen =  useSelector(selectMobileModalIsOpen)

    useEffect(() => {
        let lastScrollTop = 0;

        const debounce = (func: (...args: any[]) => void, delay: number): (() => void) => {
            let debounceTimer: NodeJS.Timeout;
            return function(...args: any[]) {
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => func(...args), delay);
            };
        };
        const handleScroll = debounce(() => {
            let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
            if (isModalOpen === false){
               
                if (currentScroll > lastScrollTop) {
                    dispatch(hideMenu());
                } else if (currentScroll < lastScrollTop && currentScroll < 400) { // Adjust the threshold as needed
                    dispatch(showMenu());
                }

            }
               
            
            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
        }, 15); // Adjust the delay as needed

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch,isModalOpen]);

    if (!show) {
        return null;
    }


    return (
        <div className={`sticky bottom-0 z-50 px-5 py-2 shadow-2xl ring-1 ring-black ring-opacity-10 bg-card md:hidden transition-transform duration-100 ease-in-out ${show ? 'translate-y-0 animate-bounceIn' : '-translate-y-full animate-bounceIn'}`}>
            <div className='grid max-w-md grid-cols-5 gap-5 mx-auto'>
                {/* Other menu items... */}
              
                <div className='flex flex-col items-center gap-1 text-primary '>
                    <Link to='/codes'><IoQrCodeSharp className='w-6 h-6' /></Link>
                    <Link to='/codes'><p className='text-xs'> Explore </p></Link>
                </div>

                <div className='flex flex-col items-center gap-1 text-primary'>
                    <Link to='/companies'><AiFillAppstore className='w-6 h-6' /></Link>
                    <Link to='/companies'><p className='text-xs'> Brands </p></Link>
                </div>
             
                <div className='flex flex-col items-center gap-1 text-primary'>
                    <Link to='/create'><IoAddCircle className='w-6 h-6' /></Link>
                    <Link to='/create'> <p className='text-xs'> Add </p></Link>
                </div>

                {userEmail? (<div className='flex flex-col items-center gap-1 text-primary'>
                    <Link to='/account'><MdAccountCircle className="w-6 h-6" /></Link>
                    <Link to='/account'><p className="text-xs">Account</p></Link>
                </div>): 
                        (<div className='flex flex-col items-center gap-1 text-primary'>
                        <Link to='/login'><MdAccountCircle className="w-6 h-6" /></Link>
                        <Link to='/login'><p className="text-xs">Log In</p></Link>
                    </div>)


                }
                <Menu as="div" className="relative inline-block text-left">
                        <Menu.Button as="div" className="flex flex-col items-center gap-1 cursor-pointer">
                            <IoIosMore className="w-6 h-6" />
                            <p className="text-xs">More</p>
                        </Menu.Button>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >



                         
                            <Menu.Items className="absolute right-0 w-56 mb-2 origin-bottom-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-opacity-5 ring-black bg-card bottom-full focus:outline-none">
                                <div className="py-2">

                                <Menu.Item>
                                        {({ active , close}) => (
                                            <div className={`${active ? 'bg-primary text-white' : 'text-primary'} flex flex-row items-center px-2 py-1`} onClick={() => {handleHome(); close()}}>
                                                <FaHome  className={` w-6 h-6`  }/>
                                                <p className={`group flex rounded-md items-center w-full px-2 py-2 text-sm`}>
                                                    Home
                                                </p>
                                            </div>
                                        )}
                            </Menu.Item>
                                    {userEmail != null ?
                                        <Menu.Item>
                                            {({ active, close }) => (
                                                <div className={`${active ? 'bg-primary text-white' : 'text-primary'} flex flex-row items-center px-2 py-1`} onClick={() => {handleUpgrade(); close()}}>
                                                    <GrUpgrade  className={` w-6 h-6`  }/>
                                                    <p className={`group  flex rounded-md w-full px-2 py-2 text-sm h-full `}>
                                                    Upgrade 
                                                    </p>
                                                </div>
                                            )}
                                        </Menu.Item> : null
                                    }

                                    {userEmail != null ?
                                        <Menu.Item>
                                            {({ active, close }) => (
                                                <div className={`${active ? 'bg-primary text-white' : 'text-primary'} flex flex-row items-center px-2 py-1`} onClick={() => {handleRequestBrand(); close()}}>
                                                    <MdAddBusiness  className={` w-6 h-6`  }/>
                                                    <p className={`group flex rounded-md items-center w-full px-2 py-2 text-sm`}>
                                                        Request Brand
                                                    </p>
                                                </div>
                                            )}
                                        </Menu.Item> : null
                                    }

                                    {userEmail != null ?
                                        <Menu.Item>
                                            {({ active, close }) => (
                                                <div className={`${active ? 'bg-primary text-white' : 'text-primary'} flex flex-row items-center px-2 py-1`} onClick={() => {handleBradRequests(); close()}}>
                                                    <FaEye  className={` w-6 h-6`  }/>
                                                    <p className={`group flex rounded-md items-center w-full px-2 py-2 text-sm`}>
                                                        Brand Requests
                                                    </p>
                                                </div>
                                            )}
                                        </Menu.Item> : null
                                    }


                                    <Menu.Item>
                                        {({ active , close}) => (
                                            <div className={`${active ? 'bg-primary text-white' : 'text-primary'} flex flex-row items-center px-2 py-1`} onClick={() => {handleSupport(); close()}}>
                                                <FaRegQuestionCircle  className={` w-6 h-6`  }/>
                                                <p className={`group flex rounded-md items-center w-full px-2 py-2 text-sm`}>
                                                    Support
                                                </p>
                                            </div>
                                        )}
                                    </Menu.Item>

                

                                    {userEmail != null ?
                                        <Menu.Item>
                                            {({ active, close }) => (
                                                <div className={`${active ? 'bg-primary text-white' : 'text-primary'} flex flex-row items-center px-2 py-1`} onClick={() => {handleUpgrade(); close()}}>
                                                    <IoExit  className={` w-6 h-6`  } onClick={()=> { handleLogout(); close(); }}/>
                                                    <button className={`group flex rounded-md items-center w-full px-2 py-2 text-sm`} onClick={()=>{ handleLogout(); close(); }}>
                                                        Logout
                                                    </button>
                                                </div>
                                            )}
                                        </Menu.Item>  : null
                                    }
                                    {/* Add more Menu.Items as needed */}
                                </div>
                            </Menu.Items>
                        </Transition>
                </Menu>
            </div>

      
        </div>
    );
};

export default MobileMenu;
