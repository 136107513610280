import React, { useState } from 'react';

import { Link, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Layout from './layouts/Layout';
import Account from './pages/Account';
import Plan from './pages/Plan';
import AllCompanies from './pages/AllCompanies';
import AllCodes from './pages/AllCodes';
import CreateCode from './pages/CreateCode';
import Login from './pages/Login';
import Register from './pages/Register';
import PasswordReset from './pages/PasswordReset';
import VerifyAccount from './pages/VerifyAccount';
import SendPasswordReset from './pages/SendPasswordReset';
import PrivateRoutes from './pages/PrivateRoutes';
import GlobalModal from './components/GlobalModal';
import AuthSuccess from './pages/AuthSuccess';
import NotFound from './pages/NotFound';
import CreateCode2 from './pages/CreateCode2';
import StripePlan from './pages/StripePlan';
import ViewInvidualCompany from './pages/ViewInvidualCompany';
import SearchResults from './pages/SearchResults';
import Amazon from './pages/Amazon';
import Users from './pages/Users';
import SingleUserView from './pages/SingleUserView';
import Moderator from './pages/Moderator';
import { useGetCurrentUserQuery } from './features/auth/authApiSlice';
import ScrollToTop from './components/ScrollTop';
import CompanyRequests from './pages/CompanyRequests';
import ContactForm from './pages/ContactForm';
import AboutUs from './pages/AboutUs';
import Privacy from './pages/Privacy';
import Copyright from './pages/Copyright';
import Terms from './pages/Terms';
import Cookies from './pages/Cookies';
import Faq from './pages/Faq';
import CompanyModal from './components/CompanyModal';
import Unauthorized from './pages/Unauthorized';
import ModeratorRoutes from './pages/ModeratorRoutes';
import { LayoutType } from './types/modelType';



interface HeadingProps {
  title: string;
}

function App() {


  return (
  <>     
 
  {/* Place the GlobalModal outside the Routes so that it can be accessible from anywhere in the app */}
  <GlobalModal />
  <ScrollToTop />
  <Routes>
   
      <Route element={<Layout type={LayoutType.Home}/>}>
        <Route path="/" element={<Home  />} />
      </Route>

      <Route element={<Layout/>}>
        <Route element={<PrivateRoutes/>}>
        <Route path="/account" element={<Account  />} />
        </Route>
      </Route>


      {/* <Route element={<Layout/>}>
        <Route path="/plan" element={<Plan  />} />
      </Route> */}

      <Route element={<Layout/>}>
        <Route element={<PrivateRoutes/>}>
       
          <Route path="/plan" element={<StripePlan  />} />
        </Route>
      </Route>

      <Route element={<Layout/>}>
        <Route path="/companies" element={<AllCompanies  />} />
      </Route>

      <Route element={<Layout/>}>
        <Route path="/companies/:id" element={<ViewInvidualCompany  />} />
      </Route>

      <Route element={<Layout/>}>
        <Route path="/codes" element={<AllCodes  />} />
      </Route>

      <Route element={<Layout/>}>
        <Route element={<PrivateRoutes/>}>
          <Route path="/create" element={<CreateCode2  />} />
        </Route>
      </Route>

      <Route element={<Layout/>}>
        <Route element={<PrivateRoutes/>}>
          <Route path="/request/company" element={<CompanyModal  />} />
        </Route>
      </Route>

      <Route element={<Layout/>}>
        <Route element={<ModeratorRoutes/>}>
          <Route path="/moderator" element={<Moderator  />} />
        </Route>
      </Route>

      <Route element={<Layout/>}>
        <Route path="/users" element={<Users  />} />
      </Route>

      <Route element={<Layout/>}>
        <Route path="/users/:id" element={<SingleUserView  />} />
      </Route>



  

      <Route >
        <Route path="/login" element={<Login  />} />
      </Route>

      <Route >
        <Route path="/register" element={<Register  />} />
      </Route>

      <Route >
        <Route path="/password-reset/:id/:token" element={<PasswordReset  />} />
      </Route>

      <Route >
        {/* <Route element={<PrivateRoutes/>}> */}
        <Route path="/verify" element={<VerifyAccount  />} />
        {/* </Route> */}
      </Route>

      <Route >
        {/* <Route element={<PrivateRoutes/>}> */}
        <Route path="/amazon" element={<Amazon  />} />
        {/* </Route> */}
      </Route>

      <Route >
        <Route path="/send-password-link" element={<SendPasswordReset  />} />
      </Route>


      <Route >
        <Route path="/auth/success" element={<AuthSuccess  />} />
      </Route>

      <Route element={<Layout type={LayoutType.Search}/>} >
        <Route path="/search" element={<SearchResults  />} />
      </Route>

      <Route element={<Layout/>} >
      <Route element={<PrivateRoutes/>}>
       
        <Route path="/company/requests" element={<CompanyRequests  />} />

      </Route>
      </Route>


      <Route element={<Layout/>} >
        <Route path="/contact" element={<ContactForm  />} />
      </Route>

      <Route element={<Layout/>} >
        <Route path="/forbidden" element={<Unauthorized  />} />
      </Route>

      <Route element={<Layout/>} >
        <Route path="/about" element={<AboutUs  />} />
      </Route>

      <Route element={<Layout/>} >
        <Route path="/privacy" element={<Privacy  />} />
      </Route>


      <Route element={<Layout/>} >
        <Route path="/copyright" element={<Copyright  />} />
      </Route>


      <Route element={<Layout/>} >
        <Route path="/terms" element={<Terms  />} />
      </Route>

      <Route element={<Layout/>} >
        <Route path="/cookies" element={<Cookies  />} />
      </Route>
      <Route element={<Layout/>} >
        <Route path="/faqs" element={<Faq  />} />
      </Route>


      <Route element={<Layout/>} >
        <Route path="/404" element={<NotFound  />} />
      </Route>



      

      <Route element={<Layout/>} >
      <Route path="*" element={<NotFound />} />
      </Route>


     





   

   
    </Routes>
  
  
  
  
    </>

  );
}

export default App;
