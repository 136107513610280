import { createEntityAdapter, createSelector, createSlice, EntityState } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { FetchBaseQueryError, RootState } from "@reduxjs/toolkit/query";
import { ApiResponse } from "../../types/apiType";
import { Company } from "../../types/modelType";


// Define a type for Company





type CompanyFiltered ={
    total : number,
    page : number,
    limit : number,
    totalPages: number,
    isLastPage: boolean,

    companies : Company[]
}

type CompanyPopular ={
    _id : string,
    totalViews : number,
    company: Company
}
type CompanyResponse = ApiResponse<CompanyFiltered>;


type SingleCompanyResponse = ApiResponse<Company>;





interface CreateCompanyRequest {
    name: string;
    description: string;
    website: string;
    category: string;
    founded: string,
    image?: string
}

interface ServerError {
    status: 'error';
    message: string;
    statusCode: number;
    errorType: string;
}

interface CompanyStats {
    totalClicks: number;
    totalCoupons: number;
    totalViews: number;
    userCount: number;
    maxEarnings: number;
    avgEarnings: number;
}

const companyAdapter = createEntityAdapter<Company>({
    selectId: (company) => company._id 
});

// Define the state shape for categories
type CompanyState = EntityState<Company>;

const initialCompanyState: CompanyState = companyAdapter.getInitialState();

const initialCompaniesFiltered  =  {
    page : 1,
    limit : 10,
    search : "",
    total: 0,
    sortField: "createdAt", // Default sort field
    sortOrder: "asc",  // Default sort order
    companyCategory :  "all",
    totalPages: 0,
    isLastPage: false,
    companies : initialCompanyState

}


export const companySlice  = createSlice({
    name : "company",
    initialState : initialCompaniesFiltered,
    reducers : {
        setCompanySearch : (state, action) => {
            state.search = action.payload
        },
        nextCompanyPage : (state) => {
            state.page += 1
        },
        prevCompanyPage : (state) => {
            if (state.page > 1){
                state.page -= 1
            }
      
        },
        goToCompanyPage   : (state, action) => {
            state.page = action.payload
        },
        resetCurrentCompanyPage : (state) => {
            state.page = 1
        },
        resetCompanySearch : (state) => {
            state.search = ""
        },
        setCompanyLimit : (state, action) => {
            state.limit = action.payload
        },
        resetCompanyLimit : (state) => {
            state.limit = 10
        },

          // New reducer to set the sorting field
        setCompanySortField: (state, action) => {
            state.sortField = action.payload;
        },

        // New reducer to set the sorting order
        setCompanySortOrder: (state, action) => {
            state.sortOrder = action.payload;
        },

        setCompanyCategory: (state, action) => {
            state.companyCategory = action.payload;
        },


        resetCompanyFilters: (state) => {
            state.limit = 10;
            state.search = "";
            state.page = 1;
            state.sortField = "createdAt";
            state.sortOrder = "asc";  
            state.companyCategory = "all";
            // state.totalPages = 0; // Reset totalPages to its initial state
            // state.isLastPage = false; // Reset isLastPage to its initial state
        }


        
    },
    extraReducers: (builder) => {
        builder
          // Handle actions related to coupons, like setting them after fetching
          .addMatcher(
            companyApiSlice.endpoints.getCompany.matchFulfilled,
            (state, action) => {
              // Ensure to use the couponsUserAdapter's setAll method correctly
            //   couponsUserAdapter.setAll(state.coupons, action.payload.data);
      
                state.total =  action.payload.data.total
                state.totalPages = action.payload.data.totalPages;
                state.isLastPage = action.payload.data.isLastPage;
            })
    }

   

})


export const getCompanySearchValue  = (state : any) => state.company.search;
export const getCompanyPageValue  = (state : any) => state.company.page;

export const getCompanyLimitValue  = (state: any) => state.company.limit;
export const getCompanyCouponTotal  = (state: any) => state.company.total;

export const getCompanySortField  = (state: any) => state.company.sortField;
export const getCompanyCSortOrder  = (state: any) => state.company.sortOrder;
export const getCompanyCategory  = (state: any) => state.company.companyCategory;
export const getCompanyTotalPages  =(state: any) => state.company.totalPages;
export const getCompanyLastPage  =(state: any) => state.company.isLastPage;




export const {setCompanySearch,nextCompanyPage ,  prevCompanyPage,goToCompanyPage, resetCurrentCompanyPage, resetCompanySearch, setCompanyLimit , resetCompanyLimit, resetCompanyFilters, setCompanySortField, setCompanySortOrder, setCompanyCategory} =  companySlice.actions
export const compayReducer  =  companySlice.reducer

export const companyApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCompany: builder.query<CompanyResponse, { page: number; limit: number; search?: string; sort?: string;  order?: string; category? : string}>({
            query: ({ page, limit, search = '', sort = 'createdAt', order = 'asc' , category = ''}) => ({
                url: `/company/all?page=${page}&limit=${limit}&search=${search}&sort=${sort}&order=${order}&category=${category}`,
                method: 'GET',
                keepUnusedDataFor: 300,
                
            }
            
            ),
            transformResponse: (baseQueryReturnValue: unknown) => {
                const responseData = baseQueryReturnValue as CompanyResponse;

                console.log(responseData);
                return responseData;
            },
            providesTags: (result?: CompanyResponse, error?: FetchBaseQueryError) => {
                if (error) {
                    return [];
                }
                
                if (result) {
                    return [
                        { type: 'Company' as const, id: "LIST" },
                        ...result.data.companies.map(Company => ({ type: 'Company' as const, id: Company._id }))
                    ];
                }
                
                return [];
            },
            keepUnusedDataFor: 60 * 5
        }),


        getCompanyById: builder.query<SingleCompanyResponse, {id : string | undefined}>({
            query: ({ id}) => ({
                url: `/company/${id}`,
                method: 'GET',
            }),
            // transformResponse: (baseQueryReturnValue: unknown) => {
            //     const responseData = baseQueryReturnValue as SingleCompanyResponse;
            //     console.log(responseData);
            //     companyAdapter.addOne(responseData.data)
                
            // },

        }),
        createCompany: builder.mutation<Company, CreateCompanyRequest>({
            query: (object) => ({
                url: 'company',
                method: 'POST',
                body: object
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Company", id: "LIST" }
            ]
        }),

        getPopularCompany: builder.query<ApiResponse<CompanyPopular[]>, {limit: number}>({
            query: ({limit}) => ({
                url: `/company/popular?limit=${limit}`,
                method: 'GET',
            }),
           
        }),

        getUserCompanyRequests: builder.query<ApiResponse<Company[]>, void>({
            query: () => ({
                url: `/company/requests/`,
                method: 'GET',
            }),
           
        }),



        getCompanyStats: builder.query<ApiResponse<CompanyStats>, string | undefined>({
            query: (id) => ({
                url: `/company/stats/${id}`,
                method: 'GET',
            }),
           
        }),
        updateCompanyViews: builder.mutation<ApiResponse<Company>, string | undefined>({
            query: (id) => ({
                url: `/company/views/${id}`,
                method: 'PUT'
            }),
            
        }),




    })
});

export const {
    useGetCompanyQuery,
    useCreateCompanyMutation,
    useGetCompanyByIdQuery,
    useGetPopularCompanyQuery,
    useUpdateCompanyViewsMutation,
    useGetCompanyStatsQuery,
    useGetUserCompanyRequestsQuery
} = companyApiSlice;








const transformCompanyResponseToState = (CompanyResponse: CompanyResponse): CompanyState => {
    // Use the CompanyAdapter to transform the array of categories into the desired state shape
    return companyAdapter.setAll(initialCompanyState, CompanyResponse.data.companies);
}



// export const selectCompanyResponseStatusAndMessage = createSelector(
//     selectCompanyResults,
//     (CompanyResult) => {
//         if (CompanyResult && CompanyResult.data) {
//             const { status, message } = CompanyResult.data;
//             return { status, message };
//         }
//         return { status: "", message: "" };
//     }
// );



export const {
    selectAll: selectAllCompany,
    selectById: selectCompanyById,
    selectIds: selectCompanyIds,
} = companyAdapter.getSelectors((state: any) =>  {
    const search = state.company.search
    const page  = state.company.page
    const limit = state.company.limit
    const sort  = state.company.sortField
    const order = state.company.sortOrder

    const category = state.company.companyCategory

    const selectCompanyResults = companyApiSlice.endpoints.getCompany.select( {page,limit, search, sort, order, category});
    const selectCompanyData = createSelector(
        selectCompanyResults,
        (companyResult) => {
            if (companyResult && companyResult.data) {
                return transformCompanyResponseToState(companyResult.data);
            }
            return initialCompanyState;
        }
    );

    

    return selectCompanyData(state) ?? initialCompanyState



})





