import React from 'react'
import { useSelector } from 'react-redux'
import { selectAllUsers, selectAllUsersIds, useGetAllUsersQuery } from '../features/allUsers/allUserApiSlice'
import  userDefault  from "../images/default.png";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
const Users = () => {



    const {data}  = useGetAllUsersQuery()
    const allUsers  = useSelector(selectAllUsers)
    const navigate  =  useNavigate()

    const handleOnUserClick  = (id : string) =>{
      navigate(`/users/${id}`)


      
  }

  const title = "Uniqshare | Users";
  const description = "Browse through Uniqshare  to discover friends' coupons, referral codes, and more. Connect and share to maximize savings and benefits!";
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/users`;


 
  return (

    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    
  
    <div className='min-h-screen py-10'> 
   
    <div className='container flex flex-col gap-10 p-10 mx-auto md:p-5'>

        <h1 className='text-4xl'> All users</h1>
        
        {/* Companies */}
        <div className='flex flex-col gap-4'>
          <div className='grid grid-cols-3 gap-10 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7'>
            {allUsers.map((user) =>(
              <div className='flex flex-col items-center gap-2 p-2'  onClick={() =>handleOnUserClick(user._id)}>
                <img src={user.image ? user.image :  userDefault } className='rounded-full' />


                <div className=''>
                  {user.username}
                </div>

              </div>


            ))}


          </div>




        </div>

      </div>

      
    </div>
    </>
  )
}

export default Users