import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className='min-h-screen py-4 bg-background'> 
      <div className='container flex flex-col h-[80vh] gap-10 p-10 mx-auto md:p-5'>

         <div className='flex flex-col items-center justify-center h-full '>
                {/* <span className='mb-2 text-3xl text-gray-800'>
                    <FcSearch  className='w-28 h-28' />
                </span> */}
                <h1 className='mb-3 font-semibold text-gray-800 text-7xl'>404</h1>
                <h2 className='mb-3 text-3xl font-semibold text-gray-800'>Page Not Found</h2>
                <p className='mb-5 text-base text-center text-gray-600'> We're sorry, the page you requested could not found!</p>
                <Link  to={"/"} className='px-4 py-1.5 font-bold text-white bg-primary rounded-sm' >
                  BACK HOME
                </Link>
              </div>



      </div>



    </div>
  )
}

export default NotFound