import  { useState } from 'react'
import Searchbar from '../../SearchBar'
import { useActivateUserMutation , useDeactivateUserMutation, useGetModeratorUsersQuery } from '../../../features/moderator/moderatorApiSlice'
import { User } from '../../../types/modelType'
import { useNavigate } from 'react-router-dom'
import RejectReasonModal from '../../RejectReasonModal'
import  userDefault  from "../../../images/default.png";

const UserModerator = () => {
    const navigate  = useNavigate()


    const [page , setPage ] = useState(1)
    const [search , setSearch]  =  useState('')
    const [limit  , setLimit] = useState(5)
    const [sortField ]  = useState('name')
    const [sortOrder ] =  useState('asc')
    const [status, setStatus] =  useState(true)

    const object  = {page :  page, search : search, limit : limit, sort:sortField ,  order: sortOrder, status: status }
    const [activateUser, {isLoading: isLoadingActivateUser}] =  useActivateUserMutation()
    const [deactivateUser, {isLoading: isLoadingDeaActivateUser}] =  useDeactivateUserMutation()
    const { data, error, isLoading } = useGetModeratorUsersQuery(object);
    

    const [isModalDisableOpen, setIsDisableModalOpen] = useState(false);
    const [clickedDisableId , setDisableClikedId]  = useState<string | undefined>(undefined)

   

    const handleActivateUser = async(id : string) =>{
      try {
          const result  = await activateUser(id)
          
      } catch (error) {}
    }

    // const handleRejectCompany = async(id : string) =>{
    //   try {
    //       const result  = await rejectCompany(id)
          
    //   } catch (error) {}
    // }

    
    const handleDeactiveUser = async( disable_reason : string) =>{

      if (clickedDisableId === undefined){
        
        setIsDisableModalOpen(false)
        return
      }
      const object  =  {id : clickedDisableId, reason_deactivate: disable_reason}
      try {
        
          const result  =  deactivateUser(object)
          setIsDisableModalOpen(false)
          setDisableClikedId('')
          
      } catch (error) {}
    }


    




 

  const openModalDisable = ( id : string) =>{
    setIsDisableModalOpen(true)
    setDisableClikedId(id)
  }

  const handleNextPage = () => {

    // dispatch(nextCompanyPage())
    setPage(page  + 1 )
  }


  const handlePrevPage = () => {
    // Check if the current page is greater than 0 before decrementing
    if (page > 1) {
        setPage(page - 1);
    }
  }


  
  const handleSearchQueryChange = (query : string) => {
    setSearch(query)
    // Additional logic for searchQuery
  };

  // const [queryValue, setQueryValue] = useState<string>( '');
  // const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);


  return (

    <>
     <RejectReasonModal title='Deactivate  User' isOpen={isModalDisableOpen} setIsOpen={setIsDisableModalOpen} handleDisableCompany={handleDeactiveUser} />

    
    <div>

        <div className='w-1/2'>
          <Searchbar  required_click={false} placeholder={'Search users'} query={search} setQuery={setSearch} onSearchQueryChange={handleSearchQueryChange} onSelecteCategoryChange={()=>{}}/>

       </div>

        <div className='flex flex-row gap-2 mt-10'>
            <button className={`px-4 py-1 text-white ${status === true ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus(true)}> Activate </button>
            <button className={`px-4 py-1 text-white ${status === false ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus(false)}> Non-active </button>
         
        </div>

        <div className=' md:h-[70vh] overflow-auto'>
            <div className='grid grid-cols-2 gap-8 mt-8 md:grid-cols-4 lg:grid-cols-6'>
                {data?.data?.users && Array.isArray(data.data.users) ? (
                data.data.users.map((user: User, index: number) => (
                  <div className=''>
                     <div className='flex flex-col items-center gap-2 p-2'  onClick={() =>{}}>
                        <img src={user.image ? user.image :  userDefault } className='rounded-full' />


                        <div className=''>
                        {user.username}
                        </div>

                    </div>
                    <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                        {user.is_active ? (
                            <button className='w-full bg-red-800 rounded-sm' onClick={() => openModalDisable(user._id)}>Disable</button>
                           
                        ) : (

                            <button className='w-full bg-green-900 rounded-sm' onClick={() => handleActivateUser(user._id)}>Re Activate</button>
                            
                        )}
                    </div>


                    
                  

                  </div>


                    
                ))
                ) : (
                <p>No companies to display</p>
                )}
            </div>
            </div>

            <div className='flex flex-row items-center justify-center gap-2 text-center'>
                <button className='px-4 py-3 text-white bg-primary' onClick={() => handlePrevPage()}>Previous </button>
                <span> {page}</span>
                <button className='px-4 py-3 text-white bg-primary'  onClick={() =>handleNextPage()}>Next</button>
                
            </div>


    </div>

    </>
  )
}

export default UserModerator