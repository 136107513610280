import  userDefault  from '../../images/default.png';
import { Company } from '../../types/modelType';
import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectCompanyById } from '../../features/company/companySlice';

// Define the expected properties of the company object
interface CompanyProps {
    company_data? :  Company
    company_id?: EntityId
    onClick  : () => void
}

// Modify the component to accept props of type CompanyProps
const CouponStore = ({ company_data ,company_id,  onClick}: CompanyProps) => {
    // Selector
    const company = useSelector(state => {
        if (company_id) {
            // Convert to a number if your EntityId is a number
            return selectCompanyById(state, company_id);
            
        }
        return company_data; // or a suitable default/fallback
    });
    return (
    <div 
    className='flex flex-col gap-3 px-3 py-3 text-center transition-shadow duration-300 ease-in-out bg-white border-2 border-gray-100 shadow-xl rounded-xl hover:shadow-2xl'  onClick={onClick}>
        <div className='flex flex-col items-center'>
            <img 
                src={company?.image ? company.image : userDefault} 
                alt="Company logo" 
                className='object-cover w-24 h-24 border-gray-100 rounded-md border-1 '
            />
        </div>

        <div className='flex flex-col overflow-hidden '>
            <p className='text-xl font-semibold truncate'>{company?.name}</p>
        </div>

        <div className='flex flex-col overflow-hidden '>
            <p className='text-xs truncate'>{company?.activeCouponsCount} active coupons</p>
        </div>

        <div className='flex flex-col overflow-hidden '>
            <p className='truncate text-md'>Earn Up To ${company?.maximumEarnings || 0}</p>
        </div>
    </div>
    );
};

export default CouponStore;
