import  { useState } from 'react';
import logo from '../../images/logo.png';
import { MdAccountCircle } from 'react-icons/md';
import { AiFillAppstore, AiOutlinePlus } from 'react-icons/ai';
import { useLogoutMutation } from '../../features/auth/authApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, selectCurrentEmail, selectCurrentUser } from '../../features/auth/authSlice';
import { showModal } from '../../features/modal/modalSlice';
import { Link } from 'react-router-dom';
import { resetUserState, selectSubscription } from '../../features/user/userSlice';
import SearchBarMain from '../SearchBarMain';
import { IoQrCodeSharp } from "react-icons/io5";
import { LayoutType } from '../../types/modelType';



interface HeaderProps{
    type?: LayoutType;
}
const Header = ({type}: HeaderProps) => {
    // Use State
    const dispatch  =  useDispatch()
    const [isOpen, setIsOpen] = useState(false);
   
    // Selectors 
    const userEmail  =  useSelector(selectCurrentEmail)
    const user=  useSelector(selectCurrentUser)
    const activeSubscription  =  useSelector(selectSubscription)
    // const popularSearches  =  useSelector(selectAllPopularSearches)

    // Api Calls
    const [logout , {isLoading}] =  useLogoutMutation()
    // Handlers 
    const handleLogout  = async () =>{
        try {
            const resultData  =  await logout({}).unwrap()
            dispatch(deleteUser())
            dispatch(resetUserState())

            dispatch(showModal({
                type: 'confirmation',
                message: 'You have been logged out.',
            
              }));
        } catch (error) {}
       
    }
    // const handleSearchQueryChange = (query : string) => {
    //     console.log(query)
    //     // Additional logic for searchQuery
    // };

    return (
        <div className={ type === LayoutType.Home || type === LayoutType.Search ? `sticky top-0 z-50 flex flex-row items-center justify-between gap-2 px-3 py-3 bg-white shadow-md` : `hidden  sticky top-0 z-50 md:flex flex-row items-center justify-between gap-2 px-3 py-3 bg-white shadow-md`}>
            <div className='hidden w-2/12 md:block'>
                <Link to='/'>
                    <img src={logo} alt="Logo" className='object-contain h-9' />
                </Link>
            </div>
            <div className='flex-1 m-0 bg-slate-800'>
                <SearchBarMain  />
            </div>

            <div className='flex-row justify-around hidden w-4/12 gap-3 bg-transparent md:flex'>
                <div className='flex flex-row items-center gap-1 px-2 '>
                    <Link to='/create'><AiOutlinePlus className='w-5 h-5' /></Link>
                    <Link to='/create'><h1 className='hidden lg:block'>Add Code</h1></Link>
                </div>

                <div className='flex flex-row items-center gap-1 px-2 '>
                    <Link to="/companies"><AiFillAppstore className='w-6 h-6' /></Link>
                    <Link to="/companies"><h1 className='hidden lg:block'>Brands</h1></Link>
                </div>

                <div className='flex flex-row items-center gap-1 px-2 '>
                    <Link to='/codes'><IoQrCodeSharp className='w-6 h-6' /></Link>
                    <Link to='/codes'><h1 className='hidden lg:block'>Codes</h1></Link>
                </div>

                <div className='relative flex flex-row items-center'>
                    <MdAccountCircle className='w-8 h-8 cursor-pointer' onClick={() => setIsOpen(!isOpen)} />

                    {isOpen && (
                        <div className="absolute right-0 z-50 p-6 mt-3 bg-white border-2 rounded-lg shadow-xl top-full w-96">
                            <ul className="divide-y divide-gray-200">
                                <li className="py-2">
                                    {userEmail ? 
                                        <Link to='/account' onClick={() => setIsOpen(!isOpen)}><button className="w-full text-left">Account {user.is_active === false && '(Deactivated)' }</button></Link> : 
                                        <Link to='/login' onClick={() => setIsOpen(!isOpen)}><button className="w-full text-left">Log In</button></Link>
                                    }
                                
                                </li>

                                {userEmail ? 
                                <li className="py-2">
                                    {activeSubscription? 
                                         <Link to='/plan' onClick={() => setIsOpen(!isOpen)}><button className="w-full text-left">Manage your subscription</button></Link>:
                                        <Link to='/plan' onClick={() => setIsOpen(!isOpen)}><button className="w-full text-left">Upgrade to Pro </button></Link>
                                       
                                    }
                                   
                                </li>: null
                                
                                }
                            
                                {userEmail ? 
                                <li className="py-2">
                                    <Link to='/request/company' > <button className="w-full text-left">Request Company</button></Link>
                                </li> : null

                                }

                                {userEmail ? 
                                <li className="py-2">
                                     <Link to='/company/requests' > <button className="w-full text-left">Company Requests</button> </Link>
                                </li> : null
                                 }
                                <li className="py-2">
                                    <Link to='/contact' > <button className="w-full text-left">Support</button></Link>
                                </li>
                                {userEmail ?  
                                <li className="py-2">
                                    <button className="w-full text-left text-red-500" onClick={handleLogout}>Log Out</button>
                                </li> : null
                                }
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Header;
