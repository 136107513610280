import React from 'react';
import { Helmet } from 'react-helmet-async';

const Terms = () => {
   //SEO
   const title = "Uniqshare | Terms & Conditions";
   const description = "Uniqshare terms policy";
   const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/terms`;


  return (

        
    <>

      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    
    <div className="min-h-screen py-10 bg-background">
      <div className="px-4 mx-auto container-2">
        <h1 className="mb-6 text-3xl font-bold text-center">Terms of Service for UniqShare</h1>
        
        <p className="mb-4">Effective Date: 2024</p>

        <h2 className="mb-3 text-2xl font-semibold">Legal Notice</h2>
        <p className="mb-4">
          By accessing any part of UniqShare, from any platform (including mobile phone and tablet), 
          you shall be deemed to have accepted these Terms in full. If you do not accept these Terms in full, 
          you must leave the Website immediately. 
        </p>

        <h2 className="mb-3 text-2xl font-semibold">1. Acceptance of Terms</h2>
        <p className="mb-4">
          Welcome to UniqShare. By using our website, you agree to be bound by these Terms of Service, 
          all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">2. Registration and Account Security</h2>
        <p className="mb-4">
          Users may register using local registration or third-party services like Google. Email verification is 
          required for users registering locally. Users are responsible for maintaining the confidentiality of their 
          account and password.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">3. Subscriptions and Payments</h2>
        <p className="mb-4">
          UniqShare offers various subscription tiers with corresponding benefits. Subscriptions are charged 
          on a monthly basis and are processed through Stripe. Users must adhere to the payment terms and conditions of Stripe.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">4. User-Generated Content</h2>
        <p className="mb-4">
          Users may post referral codes, requests, and other content. We reserve the right to review, approve, or reject 
          user-submitted content. Users grant UniqShare a non-exclusive, royalty-free license to use the submitted content.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">5. Prohibited Activities</h2>
        <p className="mb-4">
          Users are prohibited from engaging in activities that violate our policies, including botting, submitting fraudulent 
          codes, or conducting suspicious activities. We reserve the right to disable user accounts and content for policy violations.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">6. Third-Party Services</h2>
        <p className="mb-4">
          Registration and login via third-party services are subject to the terms and privacy policies of those services. 
          UniqShare is not responsible for the practices of any third-party service.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">7. GDPR Compliance</h2>
        <p className="mb-4">
          We comply with the GDPR and respect users' rights to access, correct, request deletion, or restrict the use of their personal information.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">8. Termination of Service</h2>
        <p className="mb-4">
          UniqShare reserves the right to terminate or suspend access to our service immediately, without prior notice 
          or liability, for any reason.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">9. Limitation of Liability</h2>
        <p className="mb-4">
          UniqShare is not liable for any indirect, incidental, or punitive damages arising from the use of our service.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">10. Changes to Terms</h2>
        <p className="mb-4">
          We reserve the right to modify these terms at any time. Your continued use of the site following any changes indicates 
          your acceptance of the new terms.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">11. Contact Information</h2>
        <p>
          If you have any questions about these Terms, please contact us at contact@uniqshare.com.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">12. Account Suspension and Termination</h2>
        <p className="mb-4">
          UniqShare reserves the right to disable or suspend your account in the event of suspicious activity 
          or exploitation of our system. In such cases, you will no longer be able to post referral codes, 
          but you may still browse the website. Additionally, your subscription will be automatically cancelled 
          at the next billing period.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">13. Brand Submission Requirements</h2>
        <p className="mb-4">
          When submitting a brand referral, ensure that all provided information is accurate. We do not host 
          brands that engage in illegal activities. We reserve the right to reject any code listings if the 
          information is not coherent with the selected brand.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">14. Use of Email for Communication</h2>
        <p className="mb-4">
          By using UniqShare, you agree to receive emails related to your account, transactional updates, 
          and promotional materials. We will use the email address you provide for these communications.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">15. Subscription Requirement for Visibility</h2>
        <p className="mb-4">
          To ensure visibility of your referral codes across our platform, an active subscription is required. 
          This policy helps maintain the quality and reliability of content shared on UniqShare.
        </p>
      </div>
    </div>

    </>
  );
}

export default Terms;
