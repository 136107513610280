import React, { useState } from 'react'
import { Tab } from '@headlessui/react'
import Searchbar from '../components/SearchBar'
import { useApproveCouponMutation, useDisableCouponMutation, useGetModeratorCouponQuery, useRejectCouponMutation } from '../features/moderator/moderatorApiSlice'
import CouponCard from '../components/CouponCard'
import CompanyModerator from '../components/Moderator/CompanyModerator'
import UserModerator from '../components/Moderator/UserModerator'
import { Category } from '../types/modelType'
import RejectReasonModal from '../components/RejectReasonModal'
import { Helmet } from 'react-helmet-async'

const Moderator = () => {
    function classNames(...classes:any) {
        return classes.filter(Boolean).join(' ')
      }


      const [page , setPage ] = useState(1)
      const [search , setSearch]  =  useState('')
      const [limit  , setLimit] = useState(8)
      const [sortField ]  = useState('link')
      const [sortOrder ] =  useState('asc')
      const [status, setStatus] =  useState('pending')
    
    
      const object  = {page :  page, search : search, limit : limit, sort:sortField ,  order: sortOrder, status: status }
      const [approveCoupon, {isLoading: isLoadingApproveCoupon}] =  useApproveCouponMutation()
      const [rejectCoupon, {isLoading: isLoadingRejectCoupon}] =  useRejectCouponMutation()
      const [disableCoupon, {isLoading: isLoadingDisableCoupon}] =  useDisableCouponMutation()
      const { data, error, isLoading } = useGetModeratorCouponQuery(object);

      const [isModalDisableOpen, setIsDisableModalOpen] = useState(false);
      const [clickedDisableId , setDisableClikedId]  = useState<string | undefined>(undefined)
  
      const [isModalRejectOpen, setIsRejectModalOpen] = useState(false);
      const [clickedRejectId , setRejectClikedId]  = useState<string | undefined>(undefined)
  
      


      const handleApproveCoupon = async(id : string) =>{
        try {
            const result  = await approveCoupon(id)
            
        } catch (error) {}
      }

      const handleRejectCoupon = async(disable_reason : string) =>{
        if (clickedRejectId === undefined){
          
            setIsRejectModalOpen(false)
            return
          }
          const object  =  {id : clickedRejectId, reason_disabled: disable_reason}
        try {
            const result  = await rejectCoupon(object)
            setIsRejectModalOpen(false)
            setRejectClikedId('')
            
        } catch (error) {}
      }

      const handleDisableCoupon = async(disable_reason : string) =>{
        if (clickedDisableId === undefined){
          
            setIsDisableModalOpen(false)
            return
          }

          const object  =  {id : clickedDisableId, reason_disabled: disable_reason}
        try {
            const result  = await disableCoupon(object)
            setIsDisableModalOpen(false)
            setDisableClikedId('')
            
        } catch (error) {}
      }

      

      const handleNextPage = () => {
        // dispatch(nextCompanyPage())
        setPage(page  + 1 )
      }
    
    
      const handlePrevPage = () => {
        // Check if the current page is greater than 0 before decrementing
        if (page > 1) {
            setPage(page - 1);
        }
      }
    
    //   const [queryValue, setQueryValue] = useState<string>( '');
    //   const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);

      const handleSearchQueryChange = (query : string) => {
        setSearch(query)
        // Additional logic for searchQuery
      };


    
      const openModalDisable = ( id : string) =>{
        setIsDisableModalOpen(true)
        setDisableClikedId(id)
      }
    
      const openModalRejection = ( id : string) =>{
        setIsRejectModalOpen(true)
        setRejectClikedId(id)
      }
    
  return (

    <>

    <Helmet>
        <meta name="robots" content="noindex" />


    </Helmet>
        <RejectReasonModal title='Disable Company' isOpen={isModalDisableOpen} setIsOpen={setIsDisableModalOpen} handleDisableCompany={handleDisableCoupon} />
        <RejectReasonModal title='Reject Company' isOpen={isModalRejectOpen} setIsOpen={setIsRejectModalOpen} handleDisableCompany={handleRejectCoupon} />
    
    
    
  
    <div className='min-h-screen py-10'> 
   
    <div className='container flex flex-col gap-10 p-10 mx-auto md:p-5'>

                   {/* Bottom Part*/}
                   <div className="w-full mt-5 text-sm">
            <Tab.Group>
                <Tab.List className="flex space-x-1 border-t-2 border-primary">
                    {/* My Codes */}
                    <Tab
                
                        className={({ selected }) =>
                            classNames(
                            'w-full r py-2.5 text-sm text-primary font-medium leading-5 outline-none',
                        
                            selected
                                ? 'bg-primary shadow'
                                : ''
                            )
                        }
                        >
                        {({ selected }) => (
                            /* Use the `selected` state to conditionally style the selected tab. */
                            <button
                            className={
                                selected ? ' text-white' : ' text-primary'
                            }
                            >
                            <div className='flex flex-row items-center justify-center gap-1'>
                              
                                COUPONS
                            </div>
                            </button>
                        )}
                    </Tab>
                    {/* Requests */}
                    <Tab
                
                        className={({ selected }) =>
                            classNames(
                            'w-full r py-2.5 text-sm text-primary font-medium leading-5 outline-none',
                        
                            selected
                                ? 'bg-primary shadow'
                                : ''
                            )
                        }
                        >
                        {({ selected }) => (
                            /* Use the `selected` state to conditionally style the selected tab. */
                            <button
                            className={
                                selected ? ' text-white' : ' text-primary'
                            }
                            >
                            <div className='flex flex-row items-center justify-center gap-1'>
                               
                                COMPANY
                            </div>
                            </button>
                        )}
                    </Tab>
                    {/* Saved Codes */}
                    <Tab
                
                        className={({ selected }) =>
                            classNames(
                            'w-full r py-2.5 text-sm text-primary font-medium leading-5 outline-none',
                        
                            selected
                                ? 'bg-primary shadow'
                                : ''
                            )
                        }
                        >
                        {({ selected }) => (
                            /* Use the `selected` state to conditionally style the selected tab. */
                            <button
                            className={
                                selected ? ' text-white' : ' text-primary'
                            }
                            >
                            <div className='flex flex-row items-center justify-center gap-1'>
          
                            USERS
                            </div>
                            </button>

                            
                        )}
                    </Tab>
                </Tab.List>
                {/* PAnesl */}
                <Tab.Panels className='mt-4 '>
                    {/* codes */}
                    <Tab.Panel >
                        <div className='w-1/2'>
                            {/* <Searchbar onSearchQueryChange={()=>{}} onSelecteCategoryChange={()=>{}}/>   */}
                            <Searchbar  required_click={false} placeholder={'Searcg codes'} query={search} setQuery={setSearch}  onSearchQueryChange={handleSearchQueryChange} onSelecteCategoryChange={()=>{}}/>

                        </div>
                        <div className='flex flex-row gap-2 mt-10'>
                            <button className={`px-4 py-1 text-white ${status === 'pending' ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus('pending')}> Pending </button>
                            <button className={`px-4 py-1 text-white ${status === 'approved' ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus('approved')}> Approved </button>
                            <button className={`px-4 py-1 text-white ${status === 'rejected' ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus('rejected')}> Rejected </button>
                            <button className={`px-4 py-1 text-white ${status === 'disabled' ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus('disabled')}> Disabled </button>
                            <button className={`px-4 py-1 text-white ${status === 'deactivated' ? 'bg-gray-400' : 'bg-primary'}`} onClick={() => setStatus('deactivated')}> Deactivated </button>
                        </div>



                        {/* Grid for CouponCards */}
                        <div className=' md:h-[70vh] overflow-auto'>
                        <div className='grid grid-cols-1 gap-5 mt-8 md:grid-cols-2 lg:grid-cols-4'>
                            {data?.data.coupons.map((coupon) => (
                                <div className=''>
                                    <p> {coupon.status}</p>
                                    <CouponCard key={coupon._id} coupon_data={coupon} onClick={()=>{}} />
                                    {coupon.status === "pending" && <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                                        <button className='w-1/2 bg-green-900 rounded-sm' onClick={()=> handleApproveCoupon(coupon._id)}> Approve </button>
                                        <button className='w-1/2 bg-red-800 rounded-sm'  onClick={()=> openModalRejection(coupon._id)}> Reject </button>
                                    </div> 
                                
                                    }

                                    {coupon.status === "approved" && <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                                        <button className='w-full bg-red-800 rounded-sm' onClick={()=> openModalDisable(coupon._id)}> Disable </button>
                                    </div>
                                
                                    }

                                    {coupon.status === "rejected" && <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                                        <button className='w-full bg-green-900 rounded-sm' onClick={()=> handleApproveCoupon(coupon._id)}> ReApprove </button>
                                    </div>
                                
                                    }


                                    {coupon.status === "disabled" && <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                                        <button className='w-full bg-green-900 rounded-sm'  onClick={()=> handleApproveCoupon(coupon._id)}> Approve </button>
                                    </div>
                                
                                    }

                                     {coupon.status === "deactivated" && <div className='flex flex-row justify-between w-full gap-2 mt-4 text-white'>
                                        <button className='w-full bg-green-900 rounded-sm '  onClick={()=> handleApproveCoupon(coupon._id)}> Activate  </button>
                                    </div>
                                
                                    }
                                    
                                    
                                </div>
                            ))}
                        </div>
                        </div>

                        <div className='flex flex-row items-center justify-center gap-2 text-center'>
                            <button className='px-4 py-3 text-white bg-primary' onClick={() => handlePrevPage()}>Previous </button>
                            <span> {page}</span>
                            <button className='px-4 py-3 text-white bg-primary'  onClick={() =>handleNextPage()}>Next</button>
                            
                        </div>


                        
                        {/* <div className='flex flex-col items-center justify-center h-[50vh] text-white bg-primary '>
                           
                        </div>
                       */}
                    </Tab.Panel>
                    {/* requests */}
                    <Tab.Panel>

                        <CompanyModerator />
                       

                    </Tab.Panel>
                    {/* saved */}
                    <Tab.Panel>
                        <UserModerator />
                    </Tab.Panel>
                    {/* ... */}
                </Tab.Panels>
            </Tab.Group>

            </div>

    </div>


    </div>
    </>
  )
}

export default Moderator