import { apiSlice } from "../../app/api/apiSlice";
import { ApiResponse } from "../../types/apiType";
import { LoginRequest, PasswordLinkRequest, RegisterRequest } from "../../types/formType";





interface UserResponseT{
    _id : string
    is_active : boolean
    role : string
    email : string
    verified: boolean
    username : string
    name : string
    bio? : string 
    image? : string 
    
  

}



interface UpdateUserRequest{
    username? : string
    name? : string
    bio? : string 
    image? : string | null

}
type UserResponse = ApiResponse<UserResponseT>;







interface VerificationRequest{
    verification_token : string
    email : string

}







export const authApiSlice  =  apiSlice.injectEndpoints({
    endpoints : builder => ({
        login : builder.mutation<UserResponse, LoginRequest>({ 
            query: credentials =>({
                url : '/auth/login',
                method : 'POST',
                body : {... credentials},
    

            })
            
        }),

    
        register : builder.mutation<UserResponse, RegisterRequest >({
            query : credentials =>({
                url : '/auth/signup',
                method : 'POST',
                body : {... credentials}
            })
        }),  

        sendVerification : builder.mutation<string, string>({
            query : email  =>({
                url : '/auth/sendVerifyLink',
                method : 'POST',
                body : {email}
            })
        }), 

        verify : builder.mutation<UserResponse,VerificationRequest>({
            query : details =>({
                url : '/auth/verify',
                method : 'POST',
                body : {...details}
            })
        }), 

        successAuth : builder.query<UserResponse, void>({
            query : () =>({
                url : '/auth/success',
                method : 'GET',
                keepUnusedDataFor: 0, // Disables caching

            
            }),
            
        }), 
        logout : builder.mutation({
            query : () =>({
                url : '/auth/logout',
                method : 'POST',
                // body : {... credentials}
            })
        }),
        requestPasswordResetLink : builder.mutation<string, PasswordLinkRequest>({
            query : email =>({
                url : '/auth/sendPasswordLink',
                method : 'POST',
                body : {... email}
            })
        }),

        verifyPasswordResetLink : builder.query ({
            query : credentials => ({
               
                url : `/auth/verifyPasswordLink/${credentials.id}/${credentials.token}`,
                method : 'GET',
                // body : {... email}
            })
        }),

        resetPassword : builder.mutation({
            query : credentials => ({
                
                url : `/auth/resetPassword/${credentials.id}/${credentials.token}`,
                method : 'POST',
                body : { password :  credentials.password}
            })
        }),

     




        clientStripeSecret : builder.mutation({
            query : () => ({
                url : '/create-payment-intent',
                method : 'POST'
                
            })
        }),

        updateUserInfo  :  builder.mutation<UserResponse,UpdateUserRequest>({
            query : (info) => ({
                url : '/user/info',
                method : 'PUT',
                body : {... info}
                
            })
        }),

        getCurrentUser :  builder.query<UserResponse, void>({ 
            query: credentials =>({
                url : '/user/current',
                keepUnusedDataFor: 1800, // 30 minutes

                method : 'GET',
     

            })        
        }),


    })
})


export const {
    useLoginMutation,
    useRegisterMutation,
    useSuccessAuthQuery,
    useLazySuccessAuthQuery,
    useSendVerificationMutation,
    useLogoutMutation,
    useVerifyMutation,
    useRequestPasswordResetLinkMutation,
    useVerifyPasswordResetLinkQuery,
    useResetPasswordMutation,
    useClientStripeSecretMutation,
    useUpdateUserInfoMutation,
    useGetCurrentUserQuery

} =  authApiSlice