import React from 'react'
import { useGetUserCompanyRequestsQuery } from '../features/company/companySlice'
import CouponStore from '../components/CouponStore'
import { useNavigate } from 'react-router-dom'
import { FcSearch } from 'react-icons/fc'

const CompanyRequests = () => {

    const navigate = useNavigate()
    const {data : companies, isLoading, isFetching } = useGetUserCompanyRequestsQuery()
    const handleNavigate = (id : string) =>{
        navigate(`/companies/${id}`);
    
        }

    const handleRequestBrand = () =>{
        navigate('/request/company')
    }
  return (
    <div className='min-h-screen px-5 py-10 bg-background'> 
    {/* Containr */}


       
       
        <div className={`container  mx-auto ${companies?.data.length === 0 ? 'h-[75vh]'  : 'h-full'}`}>

                <div className={`text-3xl font-bold  ${companies?.data.length != 0  && !isFetching ? 'block'  : 'hidden'}`}>
                    Company Requests 
                </div>


                {  companies?.data.length === 0  && isFetching === false ?
              <div className='flex flex-col items-center justify-center h-full p-1'>
                <span className='mb-2 text-3xl text-gray-800'>
                    <FcSearch  className='w-28 h-28' />
                </span>
                <h3 className='mb-3 text-2xl font-semibold text-gray-800'>No Brand Requests Found</h3>
                <p className='mb-5 text-base text-center text-gray-600'>You haven't made any brand requets yet<br/>Can't find a brand, request it on here.</p>
                <button className='px-4 py-1.5 font-bold text-white bg-primary rounded-sm' onClick={()=>handleRequestBrand()}>
                  REQUEST BRAND 
                </button>
              </div>
            : null
            }
                <div className="grid grid-cols-2 gap-5 mt-4 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6">
                    {companies?.data.map((company) => (
                        <div className='flex flex-col gap-2' key={company._id}>
                            <CouponStore onClick={() => handleNavigate(company._id)} company_data={company}  key={company._id}/>
                            <div className={`w-full text-center text-white rounded-sm ${
                                company.status === 'pending' ? 'bg-blue-900' :
                                company.status === 'approved' ? 'bg-green-900' :
                                company.status === 'rejected' ? 'bg-red-900' :
                                company.status === 'disabled' ? 'bg-gray-700' :
                                'bg-primary' // Fallback color
                            }`}>
                                {company.status?.toUpperCase()}
                            </div>
                        </div>
                    ))}
                </div>


        </div>
    </div>

  )
}

export default CompanyRequests