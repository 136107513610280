import React, { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectCurrentEmail, selectCurrentUser } from '../features/auth/authSlice';
import { ClipLoader } from 'react-spinners';
import logo from '../images/logo.png';
const PrivateRoutes = () => {
    const currentUserEmail = useSelector(selectCurrentEmail);
    const currentUser = useSelector(selectCurrentUser);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {
    //     // Simulate an asynchronous check (like fetching from an API)
    //     const checkAuthStatus = async () => {
    //         // Your logic to verify auth status
    //         // For example, you could wait for currentUserEmail and currentUser to be defined
    //         await new Promise(resolve => setTimeout(resolve, 1000)); // Simulating async operation
    //         setIsLoading(false);
    //     };

    //     checkAuthStatus();
    // }, [currentUserEmail, currentUser]);

     
    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "black",
    };


    let [color, setColor] = useState("#ffffff");


    // if (isLoading) {
    //     // Render a loading spinner or a placeholder
    //     return (
    //         <div className='flex items-center justify-center w-screen h-[85vh] px-5 bg-background'>
    //         {/* Card container  */}
    //         <div className='max-w-xl px-5'>
    //           <div className='flex flex-col gap-6 px-3 mx-3 '>
    //               {/* Logo */}
    //                 {/* <div className='flex justify-center w-full '>
    //                     <img src={logo} alt="Logo" className='object-contain w-2/3'/>
    //                 </div> */}
      
    //                 <ClipLoader
    //                   color={color}
    //                   loading={isLoading}
    //                   cssOverride={override}
    //                   size={40}
    //                   aria-label="Loading Spinner"
    //                   data-testid="loader"
    //                 />
                    
      
    //           </div>
    //         </div>
    //       </div>
      
    //     )
    // }

    if (!currentUserEmail) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!currentUser.verified) {
        return <Navigate to="/verify" state={{ from: location }} />;
    }

    // If the user is authenticated, render the nested routes
    return <Outlet />;
};

export default PrivateRoutes;
