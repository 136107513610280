import React from 'react'
import { useSelector } from 'react-redux'
import userDefault from '../../images/default.png';
import { EntityId } from '@reduxjs/toolkit';
import { selectCompanyById } from '../../features/company/companySlice';

interface  SingleCompanyCard{
    companyId: EntityId
    selected: EntityId | undefined,
    onClick : () => void
}
  
  const SingleCompanyCard =  ({companyId, selected, onClick}: SingleCompanyCard) =>{
  
   
    const company  =  useSelector(state => selectCompanyById(state , companyId))
    // console.log(company)
   
    return(
      <div className='flex flex-col items-center' onClick={onClick}>
          <div className='w-full'>
              <img src={ company?.image ?  company.image :  userDefault} className='object-cover w-full h-full aspect-square' />
           </div>
           <div className={`w-full text-center text-white  ${selected === companyId ? 'bg-green-700' : 'bg-primary'}`}>
            <p className='truncate '> {company?.name}</p>
           </div>
  
  
  
      </div>
    )
  }
  
export default SingleCompanyCard