import { apiSlice } from "../../app/api/apiSlice";
import { ApiResponse } from "../../types/apiType";








export const supportApiSlice  =  apiSlice.injectEndpoints({
    endpoints :  (builder) =>({


        sendSupportEmail: builder.mutation <ApiResponse<boolean>, {email: string, subject : string, message : string }>({
            query: (data) => ({
                url:  `/support/contact`,
                method: 'POST', 
                body : {...data}
            }),

        }),

    })

})

export const {
    useSendSupportEmailMutation
} = supportApiSlice;

