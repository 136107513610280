import { Outlet } from "react-router-dom"

import Header2 from "../../components/Header"
import MobileMenu from "../../components/MobileMenu"
import Footer from "../../components/Footer"
import { LayoutType } from "../../types/modelType";



interface LayoutProps {
    type?: LayoutType;
}

const Layout = ({type}: LayoutProps) => {
    return  (

        <>
            
            <Header2  type={type}/>
         
        
            <div >

            <Outlet />

            </div>

            <Footer />

            <MobileMenu  />






        
        
        
        </>
     
    )


}

export default Layout