

import React, { Fragment, useState } from 'react'
import  userDefault  from '../images/default.png'
import AccountCode from '../components/AccountCode'
import { Tab } from '@headlessui/react'
import { useGetNoAuthCouponsQuery, useGetUserCouponsQuery, useGetUserSubscriptionQuery, usePortalBillingMutation } from '../features/user/userApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectSubscription, selectUserCouponIds } from '../features/user/userSlice'
import CouponCard from '../components/CouponCard'
import { EntityId } from '@reduxjs/toolkit'
import InvidualCode from './InvidualCode'
import SubscriptionDetails from '../components/SubscriptionDetails'
import { showModal } from '../features/modal/modalSlice'
import { MdModeEdit } from "react-icons/md";
import EditableInput from '../components/EditableInput'
import EditableTextArea from '../components/EditableTextArea'
import { selectCurrentUser, setUser } from '../features/auth/authSlice'
import { convertImageToBase64 } from '../utils/helperFunction'
import { useUpdateUserInfoMutation } from '../features/auth/authApiSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetUserByIdQuery } from '../features/allUsers/allUserApiSlice'
import { IoQrCodeSharp } from 'react-icons/io5'
import { FaCircleQuestion } from 'react-icons/fa6'
import { FaBookmark } from 'react-icons/fa'
import ViewDetailedCode from './ViewDetailedCode'
import { Coupon } from '../types/modelType'
import { Helmet } from 'react-helmet-async'


function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
  }
  
const SingleUserView = () => {
    const {id} =  useParams()

    // const safeUserId = id ?? 'defaultUserId'; // Replace 'defaultUserId' with an appropriate fallback value



    const {data,isError, isLoading, error} =  useGetNoAuthCouponsQuery({id})
    // const couponsIds  = useSelector(selectUserCouponIds) 

    const {data: dataUser, isError: isUserLoading, error: errorUser } = useGetUserByIdQuery(id)
    const  user  =  dataUser?.data



    const [couponSelected, setCouponSelected] =  useState<Coupon |  undefined>(undefined)
    const [showCoupon , setShowCoupon] =  useState(false)

    const subscription =  useSelector(selectSubscription)

    const handleOnClick  =  (coupon : Coupon) =>{        
        setCouponSelected(coupon)
        setShowCoupon(true)

        
    }

    const navigate =  useNavigate ()



    

   
    let title = "Uniqshare | User Profile";
    let description = "Explore user profiles on Uniqshare.";
    let canonicalUrl = `${process.env.REACT_APP_BASE_URL}/users/${id}`;

    // Update SEO tags if user data is available
    if (user && !isUserLoading) {
        title = `Uniqshare | Codes of ${user.name}`;
        description = `Discover coupons, referrals, and deals shared by ${user.name} on Uniqshare.`;
    }
    
  return (

    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>


    {showCoupon ?  <ViewDetailedCode open={showCoupon} coupon_data={couponSelected} setShowCoupon={setShowCoupon}/> : null}
    <div className='min-h-screen py-10 bg-background'> 
        {/* Card Container  */}
        <div className='mx-auto container-2'>
            {/* First */}

            <div className='flex flex-col items-center gap-5 px-2 py-10 md:justify-start md:flex-row'>
           
                <div className='relative w-1/2 md:w-1/5 aspect-square'>
                    <img src={ user?.image ? user.image : userDefault} className='object-cover w-full h-full rounded-lg'  alt="User" />
                </div>


                <div className='flex-1 w-full text-base'>
                    {/* Top Part right */}
                    <div className='flex flex-row justify-between '>
                        <div>
                          <p>{user?.name}'s Profile</p>
                        </div>
                    </div>

                    {/* Code */}
                         {/* Number of codes, requests, and saved */}
                    <div className='flex flex-row justify-between mt-5 '>
                        <div>
                            {data?.data.length ?  data?.data.length  :  0} codes
                        </div>

                        <div>
                            0 requests
                        </div>

                        <div>
                            0  saved
                        </div>
                    </div>

                    {/* Desctiption */}
                    <div className='flex flex-col gap-2 mt-5'>

                        <div className='flex flex-col justify-center font-semibold'>
                             <p>   @{user?.username}</p>
                           
                        </div>
                        
                        <div>
                            <p>{user?.bio ? user?.bio : "Checkout my codes"}</p>
                        </div>


                    </div>

                    

                </div>

            </div>
     
          {/* <SubscriptionDetails subscription={subscription} onClickManage =  {handleManageAccount}/> */}


            {/*  Nav bar*/}
            <div className="w-full text-sm">
            <Tab.Group>
                <Tab.List className="flex space-x-1 border-t-2 border-primary">
                <Tab
            
                            className={({ selected }) =>
                            classNames(
                            'w-full r py-2.5 text-sm text-primary font-medium leading-5 outline-none',
                        
                            selected
                                ? 'bg-primary shadow'
                                : ''
                            )
                        }
                    >
                    {({ selected }) => (
                            /* Use the `selected` state to conditionally style the selected tab. */
                            <button
                            className={
                                selected ? ' text-white' : ' text-primary'
                            }
                            >
                            <div className='flex flex-row items-center justify-center gap-1'>
                                <IoQrCodeSharp />
                                CODES
                            </div>
                            </button>
                        )}
                </Tab>

                <Tab
                
                className={({ selected }) =>
                    classNames(
                    'w-full r py-2.5 text-sm text-primary font-medium leading-5 outline-none',
                
                    selected
                        ? 'bg-primary shadow'
                        : ''
                    )
                }
                >
                {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                    className={
                        selected ? ' text-white' : ' text-primary'
                    }
                    >
                    <div className='flex flex-row items-center justify-center gap-1'>
                        <FaCircleQuestion />
                        REQUESTS
                    </div>
                    </button>
                )}
            </Tab>


            <Tab
                
                className={({ selected }) =>
                    classNames(
                    'w-full r py-2.5 text-sm text-primary font-medium leading-5 outline-none',
                
                    selected
                        ? 'bg-primary shadow'
                        : ''
                    )
                }
                >
                {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                    className={
                        selected ? ' text-white' : ' text-primary'
                    }
                    >
                    <div className='flex flex-row items-center justify-center gap-1'>
                        <FaBookmark />
                    SAVED
                    </div>
                    </button>

                    
                )}
            </Tab>

                    


                    
                  
                </Tab.List>
                
                <Tab.Panels className='mt-5'>
                    <Tab.Panel>


                    <div className='w-full  min-h-[50vh] overflow-y-auto '>
                        {data?.data.length == 0 ?   <div className='flex flex-col items-center justify-center min-h-[50vh] text-white bg-gradient-to-r from-primary to-blue-300'>
                        <p className='font-semibold'> {'NO CODES'}</p>
                        </div>: null
                        }

                        <div className='grid grid-cols-3 md:grid-cols-4 '>  
                                {data?.data.map((coupon: Coupon) => (
                                    <AccountCode   logged={false} coupon_data={coupon} onClick={() => handleOnClick(coupon)} />
                                ))}
                        </div>
                                


                    </div>
                   
                


                    </Tab.Panel>

                    <Tab.Panel>
                        <div className='flex flex-col items-center justify-center h-[50vh] text-white bg-gradient-to-r from-primary to-blue-300'>
                        <p className='font-semibold'> UPCOMING </p>
                        </div>
                    </Tab.Panel>

                    <Tab.Panel>
                        <div className='flex flex-col items-center justify-center h-[50vh] text-white bg-gradient-to-r from-primary to-blue-300'>
                            <p className='font-semibold'>  UPCOMING </p>
                        </div>
                    </Tab.Panel>
                    {/* ... */}
                </Tab.Panels>
            </Tab.Group>

            </div>
            {/* Codes */}

         

        </div>
    </div>

    </>

  )
}

export default SingleUserView