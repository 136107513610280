import React, { useState, ChangeEvent, useCallback, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { selectAllCategory, selectCategoryById } from '../../features/categoryApiSlice/categoryApiSlice';
import { Category, Company } from '../../types/modelType';
import { BiSearch } from 'react-icons/bi';
import { debounce } from 'lodash';
import { FaSortDown } from 'react-icons/fa';

interface SearchbarProps {
    onSearchQueryChange: (query: string) => void;
    onSelecteCategoryChange: (query: string) => void;
    placeholder: string;
    query: string,
    setQuery : React.Dispatch<React.SetStateAction<string>>,
    selectedCategory?: Category | undefined,
    setSelectedCategory?: React.Dispatch<React.SetStateAction<Category | undefined>>
    required_click: boolean

}

const Searchbar = ({ query, setQuery,placeholder, selectedCategory ,required_click , setSelectedCategory,  onSearchQueryChange, onSelecteCategoryChange }: SearchbarProps) => {
    
    // State and Selector Declarations
    const defaultCategory = { _id: '', name: 'All Categories', description: '', createdAt: '', updatedAt: '' };
    const categoriesData = useSelector(selectAllCategory);
    let categories = [defaultCategory, ...categoriesData];

    const [searchBarActive, setSearchBarActive] =  useState(false);

    // useEffect Hooks
    useEffect(() => {
        if (selectedCategory === undefined && setSelectedCategory) {
            setSelectedCategory(categories[0]);
        }
    }, [selectedCategory, setSelectedCategory, categories]);

    const debouncedSearch = useCallback(debounce(onSearchQueryChange, 300), []);

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    // Callback and Handler Functions
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value || ''; // Ensuring value is always a string
        setQuery(value);
        if (required_click === false){
            debouncedSearch(value);
        }
    };

    const handleSearchButtonClick = () => {
        console.log("The query", query)
        setSearchBarActive(true);
        onSearchQueryChange(query);
    };

    const handleSelectedCategory = (category: Category) => {
        setSelectedCategory!(category);
        onSelecteCategoryChange(category._id);
    };

    return (
        <div className='relative flex flex-col flex-1'>
            <div className='flex flex-row items-center py-0 bg-button'>

                {setSelectedCategory && selectedCategory  && 
                    <div className='relative text-sm text-white bg-button md:text-base'>
                    <Listbox value={selectedCategory} onChange={handleSelectedCategory}>
                    <Listbox.Button className='flex flex-row items-center justify-center w-full gap-1 px-3 py-1.5 '>
                            {selectedCategory && selectedCategory._id ? selectedCategory.name : "All"}
                            <FaSortDown className='w-4 h-4'/>
                        </Listbox.Button>
                        <Listbox.Options className="absolute z-40 w-48 px-2 py-2 overflow-auto text-white shadow-lg rounded-b-md max-h-60 bg-button">
                            {categories.map((category) => (
                                <Listbox.Option key={category._id} value={category} className='px-1 py-2 overflow-hidden hover:bg-white hover:text-primary'>
                                    {category.name}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Listbox>
                </div>
                

                }

                <div className='flex-1'>
                    <input
                        type="text"
                        className='w-full h-full rounded-none search-input'
                        value={query}
                        onChange={handleSearch}
                        placeholder={placeholder + "..."}
                    />
                </div>

                {/* <div className='flex flex-row items-center px-1 text-white bg-primary '> */}
                <div className='flex flex-row items-center px-1 text-white bg-primary ' onClick={required_click ?  ()=>handleSearchButtonClick() :  ()=>{}}>
                   <BiSearch className='w-6 h-6' />
                </div>
            </div>

            {/* Suggestions list (commented out for now) */}
            {/* <div className="absolute left-0 z-10 w-full bg-white border suggestions-list top-full">
                {suggestions.map((company, index) => (
                    <div key={index} className="px-3 py-2 border-b suggestion hover:bg-gray-200">
                        <p> {company.name}</p>
                    </div>
                ))}
            </div> */}
        </div>
    );
};

export default Searchbar;
