import gooogle  from '../../images/google.png'
import { FaHandshake } from "react-icons/fa";
import { RiCouponFill } from "react-icons/ri";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa6";
const AlgorithmCard = () => {
    return (

      <div className='flex flex-col gap-4 mt-14'>
        <div className='mx-auto'>
          <h1 className='text-3xl font-semibold md:text-4xl text-primary '> How it Works</h1>
        </div>
      <div className='grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3'>
        <div className="p-6 transition-all duration-300 border rounded-lg bg-darkCard border-lightCardBorder hover:border-lightCardHover">
          <div className="py-1 rounded-full text-primary">
              <FaMoneyCheckAlt size={44} />
            </div>
          <div className="flex items-center space-x-4">
        
            <div>
              <h3 className="text-lg font-semibold ">{'Earn Money'}</h3>
              <p className="mt-1 text-lightCardText">{'Change how you connect with brands! Dive into our extensive selection of referral links and easily earn rewards. Just one click could open up a world of unexpected bonuses and exciting opportunities.'}</p>
            </div>
          </div>
          <Link to='/codes' className="flex items-center mt-4 font-semibold text-primary">
            {'Discover Codes'}
            {/* Assuming you're using Heroicons for the arrow, adjust as needed */}
            <svg className="w-5 h-5 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
          </Link>
        </div>

       

        <div className="p-6 transition-all duration-300 border rounded-lg bg-darkCard border-lightCardBorder hover:border-lightCardHover">
          <div className="py-1 rounded-full text-primary">
              <FaHandshake size={44} />
            </div>
          <div className="flex items-center space-x-4">
        
            <div>
              <h3 className="text-lg font-semibold ">{'Post Your Referral'}</h3>
              <p className="mt-1 text-lightCardText">{'Join our exclusive community and choose a subscription plan that suits you. Share your referral and reach savvy shoppers in a bot-free space, ensuring real engagement. Earn passive income easily, we handle the rest.'}</p>
            </div>
          </div>
          <Link to='/create' className="flex items-center mt-4 font-semibold text-primary">
            {'Post Referral'}
            {/* Assuming you're using Heroicons for the arrow, adjust as needed */}
            <svg className="w-5 h-5 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
          </Link>
        </div>


        <div className="p-6 transition-all duration-300 border rounded-lg bg-darkCard border-lightCardBorder hover:border-lightCardHover">
          <div className="py-1 rounded-full text-primary">
              <FaStar size={44} />
            </div>
          <div className="flex items-center space-x-4">
        
            <div>
              <h3 className="text-lg font-semibold ">{'Referral Spotlight'}</h3>
              <p className="mt-1 text-lightCardText">{'Say goodbye to lost referrals in endless forums.Our platform uniquely highlights one referral at a time, chosen by our advanced algorithm. This ensures your referral gets exclusive attention, increasing its usage.'}</p>
            </div>
          </div>
          <Link to='/codes' className="flex items-center mt-4 font-semibold text-primary">
            {'Try it out'}
            {/* Assuming you're using Heroicons for the arrow, adjust as needed */}
            <svg className="w-5 h-5 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
          </Link>
        </div>
        
      </div>

      </div>
    )
  }
  
  export default AlgorithmCard;
  