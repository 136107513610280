import React from 'react';
import { ISubscription } from '../../types/modelType';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/auth/authSlice';


type SubscriptionDetailsProps = {
    subscription : ISubscription | null
    onClickManage :  () => void
}

const SubscriptionDetails = ({subscription, onClickManage}: SubscriptionDetailsProps) => {

  const date =  dayjs(subscription?.stripeCurrentPeriodEnd).format('DD/MM/YYYY')
  const date_cancel_at =  dayjs(subscription?.cancel_at).format('DD/MM/YYYY')
  const user  = useSelector(selectCurrentUser)
  return (
    <div className='flex flex-row items-center justify-between gap-4 px-2 py-2 mt-10 text-sm text-white md:text-base bg-gradient-to-r from-primary to-blue-300 md:flex-row ring-1 ring-black ring-opacity-5 md:mt-4'>
      <div className='flex flex-col'>
        {/* Display subscription ID if it exists */}
       
        
        <div>
          {/* Conditional rendering based on subscription properties */}
          {!subscription ? (
            <p> You don't have any active subscription</p>
            ) : subscription.cancel_at_period_end && subscription.canceled_at ? (
            <p> You have a  <span className='font-medium'>{subscription.stripeProductName} </span>subscription that will be cancelled on <span className='font-medium'>{date_cancel_at}</span></p>
            ) : subscription.status === 'active' && subscription.stripeCurrentPeriodEnd ? (
            <p> You have a <span className='font-medium'>{subscription.stripeProductName} </span> active subscription renew on <span className='font-medium'>{date} </span>  </p>
            ) : subscription.status === 'unpaid' ? (
            <p> You have an unpaid <span className='font-medium'>{subscription.stripeProductName} </span>active subscription, please update your payment method</p>
            ) : <p> <span className='font-medium'>{ subscription.status}</span> </p>}
        </div>
      </div>

      {/* Upgrade button will always be displayed */}
      <div className=''>

        {!subscription  ?  ( <Link to='/plan' ><button className='px-4 py-1 text-sm text-white bg-primary disabled:opacity-30' disabled={!user.is_active}> Upgrade </button> </Link> ): 
            subscription.status === 'active' && subscription.stripeCurrentPeriodEnd ? (
                <button className='px-4 py-1 text-sm text-white bg-primary disabled:opacity-30' onClick={()=> onClickManage()} disabled={!user.is_active}> Manage </button> 
            ):
            subscription.status === 'unpaid' && subscription.stripeCurrentPeriodEnd ? (
              <button className='px-4 py-1 text-sm text-white bg-primary disabled:opacity-30' onClick={()=> onClickManage()} disabled={!user.is_active}> Update </button> 
            ) : null 
        
        }
      
      </div>
    </div>
  );
};

export default SubscriptionDetails;
