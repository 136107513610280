import { Listbox } from '@headlessui/react';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { selectAllCategory, useGetCategoryQuery } from '../../features/categoryApiSlice/categoryApiSlice';
import { Category, SearchQuery } from '../../types/modelType';
import { selectAllPopularSearches, selectPopularResults, useLazyGetSearchRecommendationQuery } from '../../features/search/searchSlice';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { MdCategory } from "react-icons/md";
import { FaCaretDown, FaSortDown } from "react-icons/fa";

const SearchBarMain = () => {
    const navigate =  useNavigate()
    const [query, setQuery] = useState<string>('');
    // const [suggestions, setSuggestions] = useState<SearchQuery[]>([]);
    // const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    // const [triggerGetSearchRecommendation, { data }] = useLazyGetSearchRecommendationQuery();

    // Debounced function to trigger the search
    // const debouncedSearch = debounce((searchValue: string) => {
    //     triggerGetSearchRecommendation({ search: searchValue });
    // }, 300);

    // useEffect(() => {
    //     if (query && data?.data) {
    //         setSuggestions(data.data);
    //         setShowSuggestions(true);
    //     } else if (!query) {
    //         setShowSuggestions(false);
    //         setSuggestions([]);
    //     }
    // }, [data, query]);

    // useEffect(() => {
    //     // Cleanup for the debounced function
    //     return () => {
    //         debouncedSearch.cancel();
    //     };
    // }, []);

    // const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     setQuery(value);

    //     if (value.length >= 1) {
    //         debouncedSearch(value);
    //         setShowSuggestions(true);
    //     } else {
    //         setShowSuggestions(false);
    //     }
    // };



   

    //Must add all and define as undined
    const categoriesData  =  useSelector(selectAllCategory)

    const categories  = [undefined, ...categoriesData ]

    const defaultCategory = { _id: '', name: 'All', description: '', createdAt: '', updatedAt: '' };

    const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(defaultCategory);

    // Handles
    const handleSelectedCatefory  = (category :  Category | undefined) =>{
        setSelectedCategory(category ?? defaultCategory)
       
        //Dispatch to update the category value
    }
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
        // onSearchQueryChange(value); // Invoke the callback passed from the parent

        // ... filter logic ...
    };

   
    const submitSearchQuery  =  ()  =>{
        if (selectedCategory  != undefined){
            navigate(`/search?query=${query}&category=${selectedCategory._id}`)
        }else {
            navigate(`/search?query=${query}`)
    
        }
        setQuery('')
        setSelectedCategory(defaultCategory)
    }

     // const onClickRecommendationQuery  =  (searchValue  : string)  =>{
    //     setShowSuggestions(false);
       
    //     if (selectedCategory  !== undefined){
    //         navigate(`/search?query=${searchValue}&category=${selectedCategory._id}`)
    //     }else {
    //         navigate(`/search?query=${searchValue}`)
    //     }
       

    // }

   
    return (
       
        <div className='relative flex flex-col'>
            <div className='flex flex-row items-center py-0 bg-button '>
                <div className='relative text-sm text-white bg-button md:text-base'>
                    <Listbox value={selectedCategory} onChange={handleSelectedCatefory}>
                        <Listbox.Button className='flex flex-row items-center justify-center w-full gap-1 px-3 py-1.5 '>
                            {selectedCategory ? selectedCategory.name : "All"}
                            <FaSortDown className='w-4 h-4'/>
                        </Listbox.Button>
                        <Listbox.Options className="absolute z-40 w-48 px-2 py-2 overflow-auto text-white shadow-lg rounded-b-md max-h-60 bg-button">

                            {categories.map((category) => (
                                <Listbox.Option
                                    key={category != undefined ? category._id : "all"}
                                    value={category}
                                    className='px-1 py-2 overflow-hidden hover:bg-white hover:text-primary'
                                >
                                    {category != undefined ? category.name : "All Categories"}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Listbox>
                </div>

                <div className='flex-1'>
                    <input
                        type="text"
                        className='w-full h-full search-input'
                        value={query}
                        onChange={handleSearch}
                        placeholder="Search companies..."
                    />
                </div>

                <div className='flex flex-row items-center px-1 text-white bg-primary'>
                    <button onClick={submitSearchQuery}><BiSearch className='w-7 h-7' />  </button>
                </div>
            </div>

            {/* {showSuggestions && (
                <div className="absolute left-0 z-10 w-full bg-white border suggestions-list top-full">
                    {suggestions.map((search, index) => (
                        <div key={index} className="px-3 py-2 border-b suggestion hover:bg-gray-200" onClick={()=> onClickRecommendationQuery(search.query)}>
                            <p>{search.query}</p>
                          
                        </div>
                    ))}
                </div>
            )} */}

        </div>
    )
}

export default SearchBarMain;
