


export const  formatNumber  = (num: number)  =>{
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, '') + 'T';
    }
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num.toString();
  }
  
  export const formatDate = (dateStr: string): string => {
    try {
        // Parsing the date string
        const date = new Date(dateStr);
        // Options for formatting
        const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
        // Formatting the date
        return date.toLocaleDateString('en-US', options);
    } catch (error) {
        // Handling potential parsing errors
        return `Invalid date format: ${error}`;
    }
};

export const formatDateWithTime = (dateStr: string): string => {
  try {
      // Parsing the date string
      const date = new Date(dateStr);

      // Options for formatting the date
      const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };

      // Options for formatting the time with seconds and milliseconds
      const timeOptions: Intl.DateTimeFormatOptions = { 
          hour: '2-digit', 
          minute: '2-digit', 
          second: '2-digit', 
          hour12: false,
          fractionalSecondDigits: 3 // This will include milliseconds
      };

      // Formatting the date and time
      return `${date.toLocaleDateString('en-US', dateOptions)} ${date.toLocaleTimeString('en-US', timeOptions)}`;
  } catch (error) {
      // Handling potential parsing errors
      return `Invalid date format: ${error}`;
  }
};



export const formatDateWithYear = (dateStr: string): string => {
  try {
      // Parsing the date string
      const date = new Date(dateStr);
      // Options for formatting - only show the year
      const options: Intl.DateTimeFormatOptions = { year: 'numeric' };
      // Formatting the date
      return date.toLocaleDateString('en-US', options);
  } catch (error) {
      // Handling potential parsing errors
      return `Invalid date format: ${error}`;
  }
};


export const formatDateWithMonth = (dateStr: string): string => {
  try {
      // Parsing the date string
      const date = new Date(dateStr);
      // Options for formatting - only show the month and year
      const options: Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric', };
      // Formatting the date
      return date.toLocaleDateString('en-US', options);
  } catch (error) {
      // Handling potential parsing errors
      return `Invalid date format: ${error}`;
  }
};


export const limitTextLength = (text = '', maxLength = 0) => text.slice(0, maxLength);
