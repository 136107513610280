import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store/store";

// Define the state type
interface AuthState {
    _id: string | null
    email: string | null
    verified: boolean | null
    username : string |  null
    name : string | null
    bio : string | null
    image : string | null
    is_active : boolean |  null
    role : string |  null
    deactivation_reason: string| null

   

}

const initialState: AuthState = {
    _id : null,
    email: null,
    verified : null,
    username: null,
    name: null,
    bio: null,
    image: null,
    is_active:  null,
    role:  null,
    deactivation_reason: null
   
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
          setUser: (state, action: PayloadAction<Partial<AuthState>>) => {
            const { email, verified, username, name, bio, image, _id, is_active, role, deactivation_reason } = action.payload;
            state.email = email ?? null;
            state.verified = verified ?? null;
            state.username = username ?? null;
            state.name = name ?? null;
            state.bio = bio ?? null;
            state.image = image ?? null;  // Set image to null if it's undefined
            state._id = _id ?? null;
            state.is_active = is_active ?? null;
            state.role = role ?? null;
            state.deactivation_reason = deactivation_reason ?? null;
        },
    
        deleteUser: (state) => {
            state.email = null;
            state.verified = null;
            state.username = null;
            state.name = null;
            state.bio = null;
            state.image = null;
            state._id =  null;
            state.is_active =  null;
            state.role  = null
            state.deactivation_reason =  null
          },
    }
});

export const { setUser, deleteUser } = authSlice.actions;
export default authSlice.reducer;


export const selectCurrentEmail  = (state: any) => state.auth.email
// export const selectCurrentUser = (state: any) => ({
//   email: state.auth.email,
//   verified: state.auth.verified,
//   username: state.auth.username,
//   name: state.auth.name,
//   bio: state.auth.bio,
//   image: state.auth.image,
//   _id: state.auth._id,
//   is_active: state.auth.is_active,
//   deactivation_reason: state.auth.deactivation_reason,
//   role:  state.auth.role
// });

export const selectCurrentUser = createSelector(
  [(state: RootState) => state.auth],
  (auth) => {
    return {
      email: auth.email,
      verified: auth.verified,
      username: auth.username,
      name: auth.name,
      bio: auth.bio,
      image: auth.image,
      _id: auth._id,
      is_active: auth.is_active,
      deactivation_reason: auth.deactivation_reason,
      role: auth.role,
    };
  }
);

