import {ZodType, string, z} from 'zod'


export interface LoginRequest{
    email: string
    password :string
}


export const LoginSchema  :  ZodType<LoginRequest> =  z.object({
    email : z.string().email("Enter a valid email"),
    password: z.string().min(1, "Enter password ")
  })





  export interface RegisterRequest{
    username: string
    password :string
    email : string,
    name : string,
    termsAndConditions: boolean
}
export interface ExtendedRegisterRequest extends RegisterRequest {
    confirm_password: string; 
}

export const SignUpSchema  :  ZodType<ExtendedRegisterRequest> = z.object({
    username : z.string().min(4, "Minimum 4 letters username").regex(/^\S*$/, "Username must not contain spaces"),
    password : z.string().min(3, "The password must be greater than 6").max(30, "The password must be less than 6"),
    confirm_password : z.string().min(3, "The password must be greater than 6").max(30, "The password must be less than 6"),
    name : z.string().min(3, "Minimum 3 letters name"), 
    email:   z.string().email("Enter a valid email"),
    termsAndConditions: z.boolean().refine(val => val === true, {
        message: "You must agree to the terms and conditions",
        path: ["termsAndConditions"]
    })


}).refine(data  => data.password === data.confirm_password, {
    message:  "Password must match",
    path: ["confirm_password"]
})


export interface PasswordLinkRequest{
    email : string

}



export const PasswordLinkSchema  :  ZodType<PasswordLinkRequest> =  z.object({
    email : z.string().email("Enter a valid email"),
  })



export interface ResetPassword{
    password : string,
    confirm_password : string
}



export const ResetPasswordSchema  :  ZodType<ResetPassword> =  z.object({
    password : z.string().min(3, "The password must be greater than 6").max(30, "The password must be less than 6"),
    confirm_password : z.string().min(3, "The password must be greater than 6").max(30, "The password must be less than 6"),

}).refine(data  => data.password === data.confirm_password, {
    message:  "Password must match",
    path: ["confirm_password"]
})



export type TVerificationCode=  {
    code: string 
}

export const VerificationCodeSchema : ZodType<TVerificationCode> = z.object({
    code : z.string().min(6, "Verification code is 6 digit").max(6, "Verification code is 6 digit")
})


// export type TCreateCode =  {
//     code : string
//     link : string
//     value : number 
//     company: strign 
//     offer : string
// }


export interface TCreateCoupon{
    company: string
    link: string,
    description: string,
    code?: string,
    minValue: number,
}

export const CreateCouponSchema: ZodType<TCreateCoupon> = z.object({
    company: z.string(),
    link: z.string().min(3, "Enter at least 3 letters"),
    description: z.string().min(3, "Enter at least 3 letters").max(75, "The maximum is 75 letters"),
    code: z.string().optional(),
    minValue: z.number({
        required_error: "Offer is required",
        invalid_type_error: "Offer is required",
      }).positive("Offer must be positive").min(1,"Enter a number greater than 0")
});

export interface TCreateCompany {
    name: string;
    description: string;
    website: string;
    category: string;
    founded: string;
    image?: string 
}

export const CreateCompanySchema: ZodType<TCreateCompany> = z.object({
    name: z.string().min(3, "Minimum 3 letters"),
    description: z.string().min(10, "Minimum 10 letters").max(300, "The maximum is 300 letters"),
    website: z.string().url().min(3, "Minimum 3 letters"),
    category: z.string(),
    image: z.string(),
    founded: z.string().refine((date) => {
        // Simple regex for 'YYYY-MM-DD' format; adjust as needed for other formats
        return /^\d{4}-\d{2}-\d{2}$/.test(date);
      }, {
        message: "Enter a date",
      }),

});