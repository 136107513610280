import React from 'react'
import Skeleton from 'react-loading-skeleton'


type CouponCardSkeletonProps ={
    cards: number
}
const CouponCardSkeleton = ({cards}: CouponCardSkeletonProps) => {
  return (

    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-4">
    {

    Array(cards).fill(0).map((item, i)  => 


    
    <div className='flex flex-col gap-2 px-3 py-3 transition-shadow duration-300 ease-in-out bg-white border-2 border-gray-100 shadow-xl rounded-xl hover:shadow-2xl' key={i} >
    {/* <p> {coupon?.impression}</p>
    <p> {coupon?.priority}</p>
    <p> {formatDateWithTime( coupon!.lastSeen_search_placemenet || "")}</p> */}
    {/* Top Part  */}
  
    <div className='flex flex-row h-14'>
        <Skeleton width={56} height={56} />
        {/* <img src= {coupon?.company.image ?  coupon?.company.image  : userDefault}  className='h-full rounded-md w-14 '/> */}
        <div className='flex flex-col ml-3 '>
            
            <Skeleton  containerClassName='w-20 text-xl' />
                {/* <p className='text-xl truncate'> {coupon?.company.name }</p> */}
       
            <Skeleton  containerClassName=' w-20 text-sm' />
           

        </div>
   
    </div>
    {/* Middle Part */}
    <div className='py-2 min-h-[40px] md:h-[60px] flex'>
         <Skeleton  containerClassName=' flex-1' count={2}  />

        {/* <p> {limitTextLength( coupon?.description, 50)} </p> */}
    </div>
    {/* BUtton Part */}
    <div className='flex flex-row justify-end'>
        {/* <Skeleton  width={81} height={32}  /> */}

        {/* <button className='px-4 py-1 font-medium text-white rounded-md bg-button'> SHOW </button> */}
    </div>





    </div>
    )}

    </div>
  )
}

export default CouponCardSkeleton