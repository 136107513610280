import { EntityId } from '@reduxjs/toolkit';
import React, { CSSProperties, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyCSortOrder, getCompanyCategory, getCompanyLimitValue, getCompanyPageValue, getCompanySearchValue, getCompanySortField, nextCompanyPage, prevCompanyPage, resetCompanyFilters, resetCompanyLimit, selectCompanyIds, setCompanyCategory, setCompanyLimit, setCompanySearch, useGetCompanyQuery } from '../../features/company/companySlice';
import SingleCompanyCard from '../SingleCompanyCard';
import Searchbar from '../SearchBar';
import { Link, useNavigate } from 'react-router-dom';
import { Category } from '../../types/modelType';


type  SearchCompanyProps  = {
    setSelected: React.Dispatch<React.SetStateAction<EntityId | undefined>>
    onClose: () => void;
}
  
const SelectCompanyModal = ({setSelected, onClose} : SearchCompanyProps) =>{
    // Hooks
    const navigate  = useNavigate()
    const dispatch = useDispatch()
    // Use Effect
    useEffect(() => {
      dispatch(setCompanyLimit(10))
    }, []);

    // Selector
    const page  =  useSelector(getCompanyPageValue)
    const search  =  useSelector(getCompanySearchValue)
    const limit  =  useSelector(getCompanyLimitValue)
    const sortField  =  useSelector(getCompanySortField)
    const sortOrder =  useSelector(getCompanyCSortOrder)
    const categoryFilter =  useSelector(getCompanyCategory)
    
    // Server Calls
    const object  = {page :  page, search : search, limit : limit, sort:sortField ,  order: sortOrder, category: categoryFilter }
    const { data, isLoading, isError, error } = useGetCompanyQuery(object);
    // Selector
    const companiesIds  =  useSelector(selectCompanyIds)
  
    // User state
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
      
      setModalOpen(true);
    }
    const closeModal = () => setModalOpen(false);
    const [selectedBrand, setBrandSelected ]  =  useState<EntityId | undefined>(undefined)

  
    // Handler
    const handleSubmit = () =>{
        setSelected(selectedBrand)
        onClose();
    }
    const handleCancel  =  () =>{
        onClose();
    }
    const handleNavigate = (id : string) =>{
        navigate(`/companies/${id}`);
  
    }
    const handleNextPage = () => {
      dispatch(nextCompanyPage())
    }
    const handlePrevPage = () => {
    
      dispatch(prevCompanyPage())
    }
  
    const handleSearchQueryChange =  (query: string) =>{
      dispatch(setCompanySearch(query))
    }
    const handleOnCompanyCategoryChange  = (category_id : string) =>{
      dispatch(setCompanyCategory(category_id))

    } 

    const [queryValue, setQueryValue] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);
    const handleResetFilter= ()=>{
   
      setQueryValue('')
      setSelectedCategory(undefined)
      dispatch(resetCompanyFilters())
      
     
  
    }

    const override: CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "black",
    };
    let [color, setColor] = useState("#ffffff");
  
  
      return (
          <>
            {/* Create new company if needed */}
            {/* <CompanyModal isOpen={isModalOpen} onClose={closeModal}/> */}
            <div className="fixed inset-0 z-40 flex items-center justify-center px-4 py-20 overflow-auto bg-opacity-50 bg-primary ">
              <div className="relative flex flex-col w-full gap-4 p-3 m-auto rounded-lg shadow-2xl md:p-6 container-2 bg-card">
              
              <Searchbar required_click={false} placeholder={'Search brands'} query={queryValue} setQuery={setQueryValue} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} onSearchQueryChange={handleSearchQueryChange} onSelecteCategoryChange={handleOnCompanyCategoryChange}/>
                    {/* Companies grid */}
                {/* <div className='min-h-[30vh] md:min-h-[30vh] overflow-auto'>

                  {isLoading && <div className='flex flex-col items-center justify-center h-full p-10 '>

                  <ClipLoader
                  color={color}
                  loading={isLoading}
                  cssOverride={override}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  />
                </div>} */}
                {/* Display Items */}
                <div className='grid grid-cols-3 sm:grid-cols-3 gap-3 py-3 max-h-[60vh]  md:max-h-[50vh]   overflow-auto md:grid-cols-4 lg:grid-cols-5'>
                  {companiesIds.map((id) => (
                    <SingleCompanyCard companyId={String(id)} onClick={() => setBrandSelected(id)} selected={selectedBrand} key={id}/>
                  ))}
                </div>
                {/* Save Items */}
                <div className='flex flex-row items-center justify-center gap-3 '>
                  <button className='px-6 py-1.5 text-white bg-button rounded-md hover:bg-gray-700' onClick={handleCancel}>CLOSE</button>
                  <button className='px-6 py-1.5 text-white bg-button rounded-md hover:bg-gray-700 disabled:bg-gray-400' onClick={handleSubmit}  disabled={selectedBrand === undefined}>SAVE</button>
                </div>
                 {/* Pagination Controls */}
                {/* <div className='flex items-center justify-center gap-4'>
                  <button className='px-4 py-2 text-white rounded bg-primary hover:bg-gray-700' onClick={() => handlePrevPage()}>Prev</button>
                  <span>Page {page}</span>
                  <button className='px-4 py-2 text-white rounded bg-primary hover:bg-gray-700' onClick={() => handleNextPage()}>Next</button>
                </div> */}

                {/* Additional Link */}
                <div className='text-center'>
                  <p>
                    Can't find a brand? You can request one <Link to={'/request/company'} className='underline cursor-pointer'>here </Link>
                  </p>
                </div>
              </div>
            </div>
          </>
      )    
  }
export default SelectCompanyModal