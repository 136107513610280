import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { z, ZodError, object, string } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSendSupportEmailMutation } from '../features/support/supportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../features/modal/modalSlice';
import { selectCurrentEmail } from '../features/auth/authSlice';
import { Helmet } from 'react-helmet-async';

// Define your schema using zod
const contactFormSchema = object({
  email: string().nonempty({ message: "Email is required" }).email({ message: "Email is invalid" }),
  subject: string().nonempty({ message: "Subject is required" }),
  message: string().min(1, { message: "Message is required" }),
});

// Create a type for the form based on the schema
type ContactFormInputs = z.infer<typeof contactFormSchema>;

const ContactForm= () => {
  const dispatch =  useDispatch()
  const [sendSupportEmail , {isLoading}] =  useSendSupportEmailMutation()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ContactFormInputs>({
    resolver: zodResolver(contactFormSchema),
  });

  const onSubmit: SubmitHandler<ContactFormInputs> = async (data) => {
    try {
      // Handle the form submission

      const result  = await sendSupportEmail (data)
      reset(); // Reset the form after successful submission
      dispatch(showModal({ type: 'confirmation', message: 'We have received your message and will be in touch within the next 24 hours.' ,
      redirectButtonText: 'OK' // Optional custom text for the redirect button

    }));
    } catch (error) {
      if (error instanceof ZodError) {
        // Handle Zod errors
        console.error(error.errors);
      } else {
        console.error("An unexpected error occurred", error);
      }
    }
  };


     //SEO
     const title = "Uniqshare | Contact Us";
     const description = "Reach out to Uniqshare for any questions, support, or feedback. Our team is ready to assist you with your referral code and coupon needs.";
     const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/contact`;
 
  return (
    <>
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
    </Helmet>

    <div className='flex flex-col py-6 md:py-10 md:min-h-screen md:justify-center bg-background'> 
    {/* Containr */}
        <div className='flex flex-col max-w-2xl mx-auto container-2'>

            <div className="py-5 text-black ">
            <div className="flex flex-col items-center justify-center gap-2 text-center">
                <h1 className="text-4xl font-bold md:text-5xl">Contact Us</h1>
                <p className="mt-3 text-base md:text-lg">
                Got a technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.
                </p>
            </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
            <div className='flex flex-col gap-1'>
                <label htmlFor="email" className='text-sm md:text-base'> Your Email</label>
                <input
                type="email"
                {...register('email')}
                placeholder="example@gmail.com"
                className="custom-input"
                />
                {errors.email && <p className="text-red-500">{errors.email.message}</p>}
            </div>

            <div className='flex flex-col gap-1'>
                <label htmlFor="subject" className='text-sm md:text-base'> Subject</label>
                <input
                type="text"
                {...register('subject')}
                placeholder="Let us know how we can help you"
                className="custom-input"
                />
                {errors.subject && <p className="text-red-500">{errors.subject.message}</p>}
            </div>

           
            <div className='flex flex-col gap-1'>
                <label htmlFor="message" className='text-sm md:text-base'> Your Message</label>
                <textarea
                {...register('message')}
                placeholder="Leave a comment..."
                className="custom-input"
                rows={4}
                ></textarea>
                {errors.message && <p className="text-red-500">{errors.message.message}</p>}
            </div>


            <div className='flex justify-center w-full'>
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="px-4 py-2 text-white rounded-md bg-primary"
                >
                    Send message
                </button>

            </div>
            </form>


            </div>
    </div>
    </>
  );
};

export default ContactForm;
