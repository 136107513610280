import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store/store';

interface ModalState {
  isShown: boolean;
  modalIsOpen: boolean
}

const initialState: ModalState = {
    isShown: true,
    modalIsOpen: false

};

const mobileMenu = createSlice({
  name: 'mobileMenu',
  initialState,
  reducers: {
    showMenu: (state) => {
      // Using Partial<ModalState> to allow for partial updates
      if (state.isShown === false){
        state.isShown = true;
        console.log("show")
      }
     
   
      
    },
    hideMenu: (state) => {
    if (state.isShown === true){
      state.isShown = false;
      console.log("hude")
    }

   

    },
    isModalOpenTrue: (state) =>{
        state.modalIsOpen = true

    },
    isModalOpenFalse: (state) =>{
        state.modalIsOpen = false

    },
    toggleMenu: (state) => {
        state.isShown = !state.isShown;
      },
  },
});

export const selectMobileMenuIsShown = (state: RootState) => state.mobileMenu.isShown;

export const selectMobileModalIsOpen = (state: RootState) => state.mobileMenu.modalIsOpen;
export const { showMenu, hideMenu , toggleMenu, isModalOpenTrue, isModalOpenFalse} = mobileMenu.actions;
export default mobileMenu.reducer;
