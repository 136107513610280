import React from 'react';
import { Helmet } from 'react-helmet-async';

const Copyright = () => {

  const title = "Uniqshare | Copyright";
  const description = "Our copyright policy";
  const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/copyright`;

  return (
    <>
     <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
    </Helmet>
    <div className="min-h-screen py-10 bg-background">
      <div className="px-4 mx-auto container-2">
        <h1 className="mb-6 text-3xl font-bold text-center">Copyright Notice for UniqShare</h1>
        
        <p className="mb-4">Effective Date: 2024</p>

        <h2 className="mb-3 text-2xl font-semibold">1. Ownership of Site Material</h2>
        <p className="mb-4">
          UniqShare holds the copyright and ownership of all materials on this site, including texts, graphics, logos, icons, images, audio clips, digital downloads, software, and any other content. This includes the exclusive rights to all images and logos used on the site, whether they are part of the site design, company branding, or user interfaces. Unauthorized use, reproduction, or distribution of these materials may constitute a violation of copyright and other intellectual property laws.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">2. User-Generated Content</h2>
        <p className="mb-4">
          Users may submit referral codes, comments, images, and other content to UniqShare. By doing so, they grant UniqShare a non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media. This does not transfer the ownership of users' original content but does allow UniqShare extensive rights to use the content.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">3. Prohibited Uses</h2>
        <p className="mb-4">
          The use of UniqShare's site materials, especially images and logos, for any unauthorized purpose is strictly prohibited. This includes copying, reproducing, or modifying any content, including images and logos, without explicit permission; using data mining, robots, or similar data gathering and extraction tools; and infringing upon the intellectual property rights of UniqShare or any third party.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">4. Trademarks</h2>
        <p className="mb-4">
          The trademarks, logos, and service marks displayed on UniqShare, including the design of images and logos, are the property of UniqShare and/or third parties. They must not be used without the express written consent of UniqShare or the respective trademark owners.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">5. Copyright Infringement Claims</h2>
        <p className="mb-4">
          If you believe that your work has been copied in a manner constituting copyright infringement on our site, please provide us with a written notice including detailed identification of the copyrighted work and the alleged infringing material, your contact information (name, address, telephone number, and email address), a statement of good faith belief that the disputed use is unauthorized, your signature, and a statement under penalty of perjury that you are authorized to act on behalf of the copyright owner.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">6. Modifications and Updates</h2>
        <p className="mb-4">
          UniqShare reserves the right to modify this copyright notice. We will update the effective date and post changes on this page.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">7. Contact Information</h2>
        <p>
          For questions or concerns regarding copyright, please reach out to us at contact@uniqshare.com.
        </p>
      </div>
    </div>
    </>
  );
}

export default Copyright;
