import React from 'react'

const Amazon = () => {
  return (
    <div className='bg-slate-600'>
            aom
        {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" className="w-1/12 h-2/6"  src="//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=GB&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=navid09-21&language=en_GB&marketplace=amazon&region=GB&placement=B0BR4DC32K&asins=B0BR4DC32K&linkId=cb2c8ecfb9a64598c71359ef5939530c&show_border=true&link_opens_in_new_window=true"></iframe> */}
    </div>
  )
}

export default Amazon