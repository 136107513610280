import React from 'react'
import Skeleton from 'react-loading-skeleton';

type CouponStoreSkeletonProps ={
    cards: number
}

const CouponStoreSkeleton = ({cards}: CouponStoreSkeletonProps) => {
    // Selector
  
    return (

        <div className="grid grid-cols-2 gap-2 md:gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        {
      Array(cards).fill(0).map((item, i)  => 
    <div 
    className='flex flex-col gap-3 px-3 py-3 text-center transition-shadow duration-300 ease-in-out bg-white border-2 border-gray-100 shadow-xl rounded-xl hover:shadow-2xl'  >
        <div className='flex flex-col items-center '>
            <Skeleton height={96} containerClassName='object-cover w-24 h-24 border-gray-100 rounded-md border-1'/>
            {/* <img 
                src={company?.image ? company.image : userDefault} 
                alt="Company logo" 
                className='object-cover w-24 h-24 border-gray-100 rounded-md border-1 '
            /> */}
        </div>

        <div className='flex flex-col overflow-hidden '>
            <Skeleton containerClassName='flex-1 '/>
            {/* <p className='text-xl font-semibold truncate'>{company?.name}</p> */}
        </div>

        <div className='flex flex-col overflow-hidden '>
            <Skeleton containerClassName='flex-1 '/>
            {/* <p className='text-xs truncate'>{company?.activeCouponsCount} active coupons</p> */}
        </div>

        <div className='flex flex-col overflow-hidden '>
            <Skeleton containerClassName='flex-1 '/>
            {/* <p className='truncate text-md'>Earn Up To ${company?.maximumEarnings || 0}</p> */}
        </div>
    </div>
    )}
    </div>
    );
};

export default CouponStoreSkeleton;
