import React from 'react';
import { Helmet } from 'react-helmet-async';

const Cookies = () => {

    //SEO
    const title = "Uniqshare | Cookies";
    const description = "Our cookies policy";
    const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/cookies`;

  return (
    <>
     <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
    </Helmet>
    <div className="min-h-screen py-10 bg-background">
      <div className="px-4 mx-auto container-2">
        <h1 className="mb-6 text-3xl font-bold text-center">Cookie Policy for UniqShare</h1>
        
        <p className="mb-4"><strong>Effective Date:</strong> 2024</p>

        <h2 className="mb-3 text-2xl font-semibold">1. Introduction</h2>
        <p className="mb-4">
          This Cookie Policy explains how UniqShare ("we", "us", and "our") uses cookies and similar 
          technologies to recognize you when you visit our website at uniqshare.com. It explains what these 
          technologies are and why we use them, as well as your rights to control our use of them.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">2. What are Cookies?</h2>
        <p className="mb-4">
          Cookies are small data files that are placed on your computer or mobile device when you visit a website. 
          Cookies are widely used by website owners to make their websites work, as well as to provide reporting information.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">3. Why Do We Use Cookies?</h2>
        <p className="mb-4">
          We use cookies for several reasons. Some cookies are required for technical reasons in order for our website 
          to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies enable us to 
          track and target the interests of our users to enhance the experience on our website. For example, UniqShare 
          keeps track of the referral codes and subscriptions you view, so that we can send you news about those types of products, 
          or to understand your preferences better.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">4. Types of Cookies We Use</h2>
        <p className="mb-4">
          - <strong>Essential Cookies:</strong> These cookies are necessary to provide you with services available through our website 
          and to use some of its features, such as access to secure areas. <br/>
          - <strong>Analytics and Performance Cookies:</strong> These cookies are used to collect information about traffic to our site 
          and how users use our site. The information gathered does not identify any individual visitor.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">5. Third-Party Cookies</h2>
        <p className="mb-4">
          In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the service, 
          deliver advertisements on and through the service, and so on.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">6. Control of Cookies</h2>
        <p className="mb-4">
          You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your 
          preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies 
          you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">7. Changes to Our Cookie Policy</h2>
        <p className="mb-4">
          We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or 
          for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay 
          informed about our use of cookies and related technologies.
        </p>

        <h2 className="mb-3 text-2xl font-semibold">8. Contact Us</h2>
        <p>
          If you have any questions about our use of cookies or other technologies, please email us at contact@uniqshare.com.
        </p>
      </div>
    </div>

    </>
  );
}

export default Cookies;