import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  isVisible: boolean;
  type: 'error' | 'confirmation';
  message: string;
  redirectPath?: string; // Optional path for redirection
  redirectButtonText?: string; // Optional text for the redirect button
}

const initialState: ModalState = {
  isVisible: false,
  type: 'error',
  message: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<Partial<ModalState>>) => {
      // Using Partial<ModalState> to allow for partial updates
      state.isVisible = true;
   
      // Set provided values or keep existing ones if not provided
      state.type = action.payload.type ?? state.type;
      state.message = action.payload.message ?? state.message;
      state.redirectPath = action.payload.redirectPath;
      state.redirectButtonText = action.payload.redirectButtonText;
    },
    hideModal: (state) => {
      state.isVisible = false;
      state.message = '';
      // Optional: Reset type and redirectPath when hiding the modal
    
      state.redirectPath = undefined;
      state.redirectButtonText = undefined;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;
