import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Image imports
import logo from '../images/logo.png';
import facebooklogo from '../images/facebook.png';
import googlelogo from '../images/google.png';
import twitter from '../images/twitter.png';
import giftcard from '../images/giftcard.png';
import { useSendVerificationMutation, useVerifyMutation } from '../features/auth/authApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentEmail, setUser } from '../features/auth/authSlice';
import { useForm } from 'react-hook-form';
import { TVerificationCode, VerificationCodeSchema } from '../types/formType';
import { zodResolver } from '@hookform/resolvers/zod';



const VerifyAccount = () => {
  const navigate = useNavigate();
  const dispatch =  useDispatch()
  // Selectors
  const email  =  useSelector(selectCurrentEmail)

  // Server Calls
  const [sendVerification , {isLoading: isSendingLoading}] =  useSendVerificationMutation()
  const [verifyAccount , {isLoading : isLoadingVerify}] =  useVerifyMutation()
  // Form
  const {register,handleSubmit, formState: {errors, isSubmitting}, reset} = useForm <TVerificationCode>({resolver : zodResolver(VerificationCodeSchema)})
  // Timer
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  // Handlers
  const onVerification = async (data:TVerificationCode ) => {
    try{
      const result  = await  verifyAccount({verification_token: data.code, email: email}).unwrap()
      dispatch(setUser(result.data))
      navigate('/auth/success')
    }catch(error){
    }
  };
  const handleResendCode = async () => {
    setTimeRemaining(30);  
    try {
      const result  = await sendVerification(email).unwrap()
    
    } catch (error) {
      setTimeRemaining(0);  
      
    }
   
    
  };

  //User Effect
  useEffect(() => {
    if (timeRemaining > 0) {
      const timerId = setTimeout(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      },1000);
      return () => clearTimeout(timerId);  // Clean up the timeout when unmounting
    }
  }, [timeRemaining]);

  return (
    <div className='py-4 bg-background'> 
   
    <div className='container flex flex-col items-center justify-center min-h-screen px-2 mx-auto'>
        {/* Card  */}
        <div className='flex flex-col justify-center max-w-lg gap-6 px-6 py-16 shadow-2xl md:py-16 h-fit bg-card ring-2 ring-black ring-opacity-5 '>
        {/* Card container */}
        <div className='flex flex-col justify-center gap-12 mx-3'>
          {/* Logo */}
          <div className='flex justify-center w-full px-4 '>
            <img src={logo} alt="" className='object-contain w-2/3'/>
          </div>
          {/* Form */}
          <form onSubmit={handleSubmit(onVerification)} className='flex flex-col h-full gap-6 py-8'>
            <div className='flex flex-col gap-2'>
              <input type="text" placeholder='Verification Code' className='custom-input'   {...register("code")}/>
              { errors.code && <span  className='text-sm text-red-700'> * {errors.code.message}</span> }
            </div>
            <button type="submit" className="w-full px-6 py-2.5 text-base font-semibold text-center text-white rounded-md bg-primary"> VERIFY</button>
          </form>
          {/*  Timer*/}
          <div className='flex flex-col justify-center gap-6 '>
            {timeRemaining > 0 ? (
              <p className='text-base font-normal text-center text-gray-500'>You can resend the code in {timeRemaining} seconds.</p>
            ) : (
              <div className='flex flex-row items-center justify-center gap-1'>
                <p> Haven't received your code? </p> <button onClick={handleResendCode} className='text-base font-normal text-center underline'>Click Here</button>
              </div>
            )}
          </div>
        </div>
        {/* End Card Container */}
      </div>
      {/* End Card */}
    </div>
    </div>
  );
};

export default VerifyAccount;
