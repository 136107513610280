import { EntityState, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { Company, Coupon, SearchQuery, SearchResult } from "../../types/modelType";
import { apiSlice } from "../../app/api/apiSlice";
import { ApiResponse } from "../../types/apiType";







type QueryResponse  = ApiResponse<SearchQuery[]>


type SearchResulResponse  = ApiResponse<SearchResult>

const searchesRecommendationAdapter = createEntityAdapter<SearchQuery>({
    selectId: (search) => search._id 
});


const searchesPopularAdapter = createEntityAdapter<SearchQuery>({
    selectId: (search) => search._id 
});


type SearchQueryState = EntityState<SearchQuery>;

const initialRecommendationState: SearchQueryState = searchesRecommendationAdapter.getInitialState();


const initialsearchesPopularState: SearchQueryState = searchesPopularAdapter.getInitialState();


type intialSearchStateProp = {
    query: string,
    popularSearch: SearchQueryState,
    searchesRecommendation:  SearchQueryState,
   
}
 // searchResults :  SearchResult | undefined

const intialSearchState : intialSearchStateProp= {
    query : "",
    popularSearch  : initialsearchesPopularState,
    searchesRecommendation : initialRecommendationState,
    


}

// searchResults : undefined


 // clearSearchResults: (state) => {
        //     state.searchResults = undefined;
        // },


export const searchSlice  = createSlice({
    name : "search",
    initialState :  intialSearchState,
    reducers: {
        updateQuery: (state, action) => {
            state.query = action.payload;
        },
       

    },
    extraReducers: (builder) => {
        // builder
            // Handle actions related to coupons, like setting them after fetching
            // .addMatcher(
            //     searchApiSlice.endpoints.getSearchResult.matchFulfilled,
            //     (state, action) => {
            //         // Assuming you want to set the searchResults in the state
            //         // with the payload received from the action
            //         state.searchResults = action.payload.data;
            //     }
            // );
    },


})

//Export the value 





export const getQuery  = (state: any) => state.search.query;
export const getSearchResult  = (state:any) => state.search.searchResults;


export const {updateQuery,   /* clearSearchResults export your reducers here if any */ } = searchSlice.actions;

// Export the reducers
export default searchSlice.reducer;

//Export the reducers 





export const searchApiSlice  =  apiSlice.injectEndpoints({
    endpoints :  (builder) =>({


        getPopularSearch: builder.query <QueryResponse, void>({
            query: () => ({
                url:  `/search/all`,
                method: 'GET'
            }),

        }),
     



        getSearchRecommendation: builder.query <QueryResponse, {search : string}>({
            query: ({search}) => ({
                url:  `/search/filter?search=${search}`,
                method: 'GET'
            })
        }),


        getSearchResult: builder.query<SearchResulResponse, { search: string | null , category : string | null}>({
            query: ({ search , category}) => {
                // If search is null, use an empty string instead
                const searchString = search === null ? '' : search;
                const categoryString = category === null ? 'all' : category;
                return {
                    url: `/search/perform?search=${searchString}&category=${categoryString}`,
                    method: 'GET'
                };
            }
        }),
        






    })



})


export const {
    useGetPopularSearchQuery,
    useLazyGetSearchRecommendationQuery,
    useGetSearchResultQuery
} = searchApiSlice;


export const selectPopularResults = searchApiSlice.endpoints.getPopularSearch.select();

const transformPopularSearch = (searchResponse: QueryResponse): SearchQueryState => {
    // Use the CompanyAdapter to transform the array of categories into the desired state shape
    return searchesPopularAdapter.setAll(initialsearchesPopularState, searchResponse.data);
}

const selectSearchData = createSelector(
    selectPopularResults,
    (popularResult) => {
        if (popularResult && popularResult.data) {
            return transformPopularSearch(popularResult.data);
        }
        return initialsearchesPopularState;
    }
);


export const {
    selectAll: selectAllPopularSearches,
    selectById: selectPopularSearchById ,
    selectIds: selectPopularSearchIds,
} = searchesPopularAdapter.getSelectors((state: any) => selectSearchData(state) ?? initialsearchesPopularState);



