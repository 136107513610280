import React, { CSSProperties, Fragment, useEffect, useState } from 'react';
import CouponStore from '../components/CouponStore';

import { getCompanyCSortOrder, getCompanyCategory, getCompanyCouponTotal, getCompanyLimitValue, getCompanyPageValue, getCompanySearchValue, getCompanySortField, getCompanyTotalPages, nextCompanyPage, prevCompanyPage, resetCompanyFilters, selectAllCompany, setCompanyCategory, setCompanySearch, setCompanySortOrder, useGetCompanyQuery, useUpdateCompanyViewsMutation } from '../features/company/companySlice';
import { Category, Company } from '../types/modelType';
import CompanyModal from '../components/CompanyModal';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@headlessui/react';
import {MdArrowDropDown, MdOutlineSort} from 'react-icons/md'
import Searchbar from '../components/SearchBar';
import { setCouponCategory, setCouponSearch } from '../features/coupon/couponApiSlice';
import { ClipLoader } from 'react-spinners';
import { FcSearch } from 'react-icons/fc';
import { selectCategoryById } from '../features/categoryApiSlice/categoryApiSlice';
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import { Helmet } from 'react-helmet-async';
const AllCompanies = () => {


  // const  [search, setSearch] =  useState('')

  const location = useLocation();
  const navigate  = useNavigate()
  const dispatch  = useDispatch()


  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('query');
  const category = searchParams.get('category');

  const existingValueCategory  =  useSelector(state => selectCategoryById(state, category! ))
  useEffect(() => {
    if (query) {
     

        dispatch(setCompanySearch(query))
    }
    if (category) {
        dispatch(setCompanyCategory(category));
        
        setSelectedCategory(existingValueCategory)
       
    }
  }, [query, category, existingValueCategory, dispatch])
  
 
  

    // Replace the URL without query and category
    // navigate({
    //     pathname: window.location.pathname,
    //     search: '', // This removes the search query from the URL
    // }, { replace: true });

 

  useEffect(() => {
      // Function to call when leaving the current route
      const handleRouteChange = () => {
          console.log('Leaving route:', location.pathname);
          // Your function logic here
          dispatch(resetCompanyFilters())
        

     
      };
     

      // Set up the effect
      return () => {
        handleRouteChange();
      };
  }, []); // Dependency on 'location' means this effect runs when the location (route) changes

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "black",
  };


  let [color, setColor] = useState("#ffffff");



  const page  =  useSelector(getCompanyPageValue)
  const search  =  useSelector(getCompanySearchValue)
  const limit  =  useSelector(getCompanyLimitValue)
  const sortField  =  useSelector(getCompanySortField)
  const sortOrder =  useSelector(getCompanyCSortOrder)
  const categoryFilter =  useSelector(getCompanyCategory)
  

  const object  = {page :  page, search : search, limit : limit, sort:sortField ,  order: sortOrder, category: categoryFilter }
  const { data, isLoading, isError, error, isFetching } = useGetCompanyQuery(object);

  const allCompanies  =  useSelector(selectAllCompany)
 
 
  const total  =  useSelector(getCompanyCouponTotal)



  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const value  =  useSelector(getCompanySearchValue)
  const valueCategory  = useSelector(getCompanyCategory)

  

  // // Handle loading state
  // if (isLoading) {
  //   return <div>Loading...</div>; // You can replace this with a spinner or loading component
  // }

  // // Handle error state
  // if (isError) {
  //   return <div>Error:</div>; // Display the error message
  // }


  const [  updateViews , {isLoading: isLaodingUpdatingView}]   = useUpdateCompanyViewsMutation()

  const handleNavigate = async (id : string) =>{
      const result = await updateViews(id); 
      
      navigate(`/companies/${id}`);
   

  }


  const handleNextPage = () => {
    dispatch(nextCompanyPage())
  }


  const handlePrevPage = () => {
    dispatch(prevCompanyPage())
  }


  const handleSortOrder  = (type : string) =>{
    if (type === "desc" ||  type === "asc"){
      dispatch(setCompanySortOrder(type))
    }
  
  }






    const handleSearchQueryChange = (query: string) => {
     
      const newParams = new URLSearchParams(searchParams);
      if (query !== '') {
          newParams.set('query', query);
      } else {
          newParams.delete('query');
      }

      // Replace the current entry in the history stack
      navigate({
          pathname: window.location.pathname,
          search: newParams.toString()
      }, { replace: true });
      console.log("The query ", query)
      dispatch(setCompanySearch(query));
  };

  const handleOnCompanyCategoryChange = (category_id: string) => {
    const newParams = new URLSearchParams(searchParams);
    if (category_id !== '') {
        newParams.set('category', category_id);
    } else {
        newParams.delete('category');
    }

    // Replace the current entry in the history stack
    navigate({
        pathname: window.location.pathname,
        search: newParams.toString()
    }, { replace: true });

    dispatch(setCompanyCategory(category_id));
  };

 

  const [queryValue, setQueryValue] = useState<string>(query || '');

  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(  existingValueCategory ||  undefined);
  const handleResetFilter= ()=>{
    setSearchParams(undefined)
    setQueryValue('')
    setSelectedCategory(undefined)
    dispatch(resetCompanyFilters())
    
   

  }
 

  const totalPages=  data?.data.totalPages || 0

     //SEO
     const title = "Uniqshare | Explore Top Brands with Exclusive Referral Codes";
     const description = "Browse an extensive selection of top brands on Uniqshare. Discover exclusive referral and coupon codes to save on your favorite products.";
     const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/companies`;
 
  return (
    <> 

    <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={canonicalUrl} />
      </Helmet>
     {/* <CompanyModal isOpen={isModalOpen} onClose={closeModal} /> */}
    <div className='min-h-screen px-5 py-4 md:py-10 bg-background'> 
   
      <div className='container mx-auto'>

        {/* Top Container */}
        <div className='flex flex-col justify-between gap-5 lg:items-center lg:flex-row'>


          <div className='flex flex-col gap-2'>
            <p className='text-4xl'>Discover all brands </p>
            <p>Rewards applicable in these stores. Check individuals terms and conditions</p>
          </div>

          <div className='flex flex-row gap-2 h-1/2 ' >
          <p>Don’t see a brand? <Link to={'/request/company'}><span  className='font-medium underline'> Make a request </span> </Link> </p>
          </div>

        </div>

        {/* Middle with fields */}
        <div className='flex flex-row gap-2 mt-5 lg:gap-5 lg:grid lg:grid-cols-2'>
          <div className="flex-1">
          <Searchbar required_click={true} placeholder={'Search brands'} query={queryValue} setQuery={setQueryValue} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} onSearchQueryChange={handleSearchQueryChange} onSelecteCategoryChange={handleOnCompanyCategoryChange}/>
          </div>

          {/* <div className='flex flex-row justify-end gap-4 md:justify-end '>
            <Menu as="div" className="relative inline-block text-left ">
              <Menu.Button className="inline-flex justify-center w-full text-sm font-medium hover:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <MdOutlineSort  className='w-8 h-8' />
              </Menu.Button>
              <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={()=> handleSortOrder("asc")}
                      >
                        Newest
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}

                        onClick={()=> handleSortOrder("desc")}
                      >
                        Oldest
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Menu>
            </div> */}


          </div>

          
          

          

          {/* <div className='flex flex-row justify-end gap-4'> */}

      
            {/* <button className='px-5 text-white rounded-md bg-primary'> Newest </button>
            <button className='px-5 text-white rounded-md bg-primary'> Most Popular </button>
            <button className='px-5 text-white rounded-md bg-primary'> Oldest </button> */}
          {/* </div> */}

      

     
        {/* Companies grid */}
       
        <div className={`min-h-[70vh]  ${isLoading || ( (data?.data.companies.length === 0 || data?.data.companies === undefined)&& isFetching === false) ? 'flex flex-col items-center justify-center' : ''} ` } >

        {isLoading && <div className='flex flex-col items-center justify-center h-full p-10 '>
        
        <ClipLoader
        color={color}
        loading={isLoading}
        cssOverride={override}
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
     </div>}
    
    
    
    {(data?.data.companies.length === 0 || data?.data.companies === undefined) && isFetching === false ?
          <div className='flex flex-col items-center justify-center h-full p-10 '>
            <span className='mb-2 text-3xl text-gray-800'>
                <FcSearch  className='w-28 h-28' />
            </span>
            <h3 className='mb-3 text-2xl font-semibold text-gray-800'>No Result Found</h3>
            <p className='mb-5 text-base text-center text-gray-600'>We've searched through hundreds of codes<br/>Unfortunately, there are no codes matching your criteria right now.</p>
            <button className='px-4 py-1.5 font-bold text-white bg-primary rounded-sm' onClick={()=>handleResetFilter()}>
              RESET
            </button>
          </div>
        : null
        }
        <div className='grid grid-cols-2 gap-4 mt-8 sm:grid-cols-3 md:gap-6 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6'>
          {data?.data.companies.map((company: Company, index: number) => (
            <CouponStore key={index} company_data={company}  onClick={() =>handleNavigate(company._id)}/>
        ))}
        </div>

        </div>

      </div>
     
      {(totalPages> 1  && data?.data.companies !== undefined) ?
      <div className='flex flex-row items-center justify-center gap-2 mt-10 text-center'>
       
            <button 
                className={`text-white bg-primary ${page <= 1 ? 'opacity-50 cursor-not-allowed' : ''}`} 
                onClick={() => handlePrevPage()} 
                disabled={page <= 1}
            >
                <MdNavigateBefore className='w-8 h-8' />
            </button>
            <span 
                  className='font-medium'
                  title={`You are on page ${page} out of ${totalPages} total pages.`}
              >
                  {totalPages > 1 ? `Page ${page} of ${totalPages}` : `Page ${page}`}
              </span>
            <button 
                className={`text-white bg-primary ${page >= totalPages ? 'opacity-50 cursor-not-allowed' : ''}`} 
                onClick={() => handleNextPage()} 
                disabled={page >= totalPages}
            >
                <MdNavigateNext className='w-8 h-8' />
            </button>
      </div>: null
    }

       

    </div>
    
    </>
    
  )
}

export default AllCompanies;
