import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';

type ModalType = 'error' | 'confirmation';

interface ModalProps {
  isVisible: boolean;
  type: ModalType;
  title?: string;
  message: string;
  onClose: () => void;
  redirectPath?: string;
  redirectButtonText?: string;
}

const Modal = ({
  isVisible,
  type,
  title,
  message,
  onClose,
  redirectPath,
  redirectButtonText
}: ModalProps) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    onClose();
    if (redirectPath) {
      setTimeout(() => navigate(redirectPath), 200);
    }
  };

  const getTitle = () => title || (type === 'error' ? 'Error' : 'Confirmation');

  const buttonStyles = {
    error: 'bg-primary hover:bg-gray-200',
    confirmation: 'bg-primary hover:bg-gray-200',
  };

  return (
    <Transition show={isVisible} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-[100] overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-primary bg-opacity-50" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-out duration-500"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 scale-90"
          >
            <div className="inline-block w-full max-w-sm overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
             
                    <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900">
                      {getTitle()}
                    </Dialog.Title>

                    <div className="mt-4">
                      <p className="text-sm text-gray-500">{message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row-reverse gap-4 px-4 py-3 bg-gray-50">
                <button
                  type="button"
                  className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-primary hover:bg-gray-50   sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ${buttonStyles[type]}`}
                  onClick={onClose}
                >
                  Close
                </button>
                {redirectPath && (
                  <button
                    onClick={handleRedirect}
                    className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-gray-50  hover:text-primary   sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ${buttonStyles[type]}`}
                  >
                    {redirectButtonText || 'Go to Path'}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
