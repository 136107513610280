import React, { useState, ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useResetPasswordMutation, useVerifyPasswordResetLinkQuery } from '../features/auth/authApiSlice';
import logo from '../images/logo.png';
import { ResetPassword, ResetPasswordSchema } from '../types/formType';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Helmet } from 'react-helmet-async';

interface Params {
    id: string;
    token: string;
}

const PasswordReset = () => {
    const { id, token } = useParams();
    const navigate = useNavigate();
    //Servers Calls
    const { data, isLoading, isError, error } = useVerifyPasswordResetLinkQuery({ id, token });
    const [resetPassword, { isLoading: isResetting }] = useResetPasswordMutation();
    // Form
    const {register,handleSubmit, formState: {errors, isSubmitting}, reset} = useForm <ResetPassword>({resolver : zodResolver(ResetPasswordSchema)})
   
    
    // Handlers
    const onResetPassword = async (data: ResetPassword) => {
        const {password}  = data
        const request  =  { id , token, password}
        try {
            await resetPassword( request ).unwrap();
            navigate("/login");
        } catch (error : any) {
       
        }
    };

    return (
        <>
        <Helmet>
            <meta name="robots" content="noindex" />
        </Helmet>
        <div className='py-4 bg-background'> 
   
        <div className='container flex flex-col items-center justify-center min-h-screen px-2 mx-auto'>
            {/* Card  */}
            <div className='flex flex-col justify-center max-w-lg gap-6 px-6 py-16 shadow-2xl md:py-16 h-fit bg-card ring-2 ring-black ring-opacity-5 '>
                {/* Logo */}
                <div className='flex justify-center w-full py-2 lg:px-8 '>
                    <img src={logo} alt="Logo" className='object-contain w-2/3'/>
                </div>
                {/* Form */}
                <form className='flex flex-col gap-6 py-12' onSubmit={handleSubmit(onResetPassword)}>
                    <div className='flex flex-col gap-2'>
                        <input
                            type="password"
                            placeholder='Password'
                            className='custom-input'
                            {...register("password")} 
                        />
                        { errors.password && <span className='text-sm text-red-700 '> * {errors.password.message}</span> }
                    </div>

                    <div className='flex flex-col gap-2'>
                        <input
                            type="password"
                            placeholder='Confirm Password'
                            className='custom-input'
                            {...register("confirm_password")}
                
                        />
                        { errors.confirm_password && <span className='text-sm text-red-700 '> * {errors.confirm_password.message}</span> }
                     </div>
                    <button
                        type="submit"
                        className="w-full py-2.5 text-base  font-semibold text-white bg-primary rounded-md"
                       
                        disabled={isSubmitting}
                    >
                        {isResetting ? "RESETTING" : "RESET PASSWORD"}
                    </button>
                </form>
            </div>
            {/* End card */}
        </div>
        </div>
   

        </>
    );
}

export default PasswordReset;
