
import { Link, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import { useRegisterMutation } from '../features/auth/authApiSlice';
import { useDispatch } from 'react-redux';
import { setUser } from '../features/auth/authSlice';
import { ExtendedRegisterRequest, SignUpSchema } from '../types/formType';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Helmet } from 'react-helmet-async';



const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Servers Calls
    const [signUp, { isLoading }] = useRegisterMutation();
    // Form
    const {register,handleSubmit, formState: {errors, isSubmitting}, reset} = useForm <ExtendedRegisterRequest>({resolver : zodResolver(SignUpSchema)})
    // Handlers
    const onRegister = async (data: ExtendedRegisterRequest) => {
        try {
            const userData = await signUp(data).unwrap();
         
            dispatch(setUser(userData.data))
            navigate('/auth/success')

           
        } catch (err) {
        }
    };

    const title = "Uniqshare | Register";
    const description = "Create an account in Uniqshare and start posting your referrals and earn money ";
    const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/register`;
  
    return (
      <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>


      <div className='py-4 bg-background'> 
   
      <div className='container flex flex-col items-center justify-center min-h-screen px-2 mx-auto'>
      {/* <div className='flex items-center justify-center w-screen h-screen px-5 bg-background'> */}
        {/* Card container  */}
        <div className='max-w-lg px-2 py-8 mx-auto shadow-2xl md:px-6 md:py-10 ring-2 ring-black ring-opacity-5 bg-card'>
          {/* Top Part */}
          <div className='flex flex-col justify-center gap-12 py-5 mx-3'>
            {/* Logo */}
            <div className='flex justify-center w-full'>
              <img src={logo} alt="Logo" className='object-contain w-2/3'/>
            </div>
            {/* Form */}
            <form className='flex flex-col gap-8 ' onSubmit={handleSubmit(onRegister)}>
              <div className='flex flex-col gap-2'>
                    <input type="text" placeholder='Full Name' className='custom-input'   {...register("name")}/>
                    { errors.name && <span className='text-sm text-red-700 '> * {errors.name.message}</span> }
              </div>

              <div className='flex flex-col gap-2'>
                   <input type="text" placeholder='Username' className='custom-input' {...register("username")}/>
                   { errors.username && <span className='text-sm text-red-700 '> * {errors.username.message}</span> }
              </div>

              <div className='flex flex-col gap-2'>
                <input type="email" placeholder='Email' className='custom-input' {...register("email")} />
                { errors.email && <span className='text-sm text-red-700 '> * {errors.email.message}</span> }
              </div>

              <div className='flex flex-col gap-2'>
                <input type="password" placeholder='Password' className='custom-input' {...register("password")} />
                { errors.password && <span className='text-sm text-red-700 '> * {errors.password.message}</span> }
              </div>

              <div className='flex flex-col gap-2'>
                <input type="password" placeholder='Confirm Password' className='custom-input' {...register("confirm_password")}  />
                { errors.confirm_password && <span className='text-sm text-red-700 '> * {errors.confirm_password.message}</span> }
              </div>


              <div className='flex flex-col items-center gap-2'>
                <div className='flex flex-row gap-2'>
                  <input type="checkbox" {...register("termsAndConditions")} />
                  <label htmlFor="termsAndConditions" className='text-sm text-primary'>
                    I agree to the <Link to="/terms" className='font-bold'>Terms and Conditions</Link>
                  </label>
                </div>
                { errors.termsAndConditions && <span className='text-sm text-red-700 '> * You must agree to the terms and conditions</span> }
              </div>




              <button type="submit" className="w-full px-6 py-2.5 text-base font-semibold text-center text-white bg-primary rounded-md" disabled={isSubmitting}>SIGN UP</button>
            </form>
          </div>

          {/* Divider */}
          <div className='relative flex items-center mx-3 my-4'>
              <div className="flex-grow border-t border-primary"></div>
              <span className="flex-shrink mx-4 text-primary">OR</span>
              <div className="flex-grow border-t border-primary"></div>
          </div>
          {/* Bottom Part - Google */}

          <div className='py-2 mx-3 '>
          <a href={`${process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL_PRODUCTION : process.env.REACT_APP_URL_DEVELOPMENT}/auth/google`} className="flex items-center justify-center w-full py-2.5 text-base transition duration-300 ease-in-out transform bg-white border border-gray-300 rounded-md hover:scale-105 hover:shadow-lg">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="w-6 h-6" viewBox="0 0 48 48">
                    <defs>
                        <path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/>
                    </defs>
                    <clipPath id="b">
                        <use xlinkHref="#a" overflow="visible"/>
                    </clipPath>
                    <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/>
                    <path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/>
                    <path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/>
                    <path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/>
                </svg>
                  <span className="ml-4 font-medium text-primary">Sign up with Google</span>
              </a>
          </div>
          {/* Bottom Part - Login */}
          <div className='flex flex-col justify-center gap-6 px-3 py-5 mx-3 mt-4'>
            <p className='text-base font-normal text-center text-primary'> Do you already have an account? <Link to="/login" className='font-bold'>Login</Link></p>
          </div>

        </div>
      </div>
      </div>
      </>
    )
}

export default Register;
