import React, { CSSProperties, useEffect, useState } from 'react';
import CouponStore from '../components/CouponStore'; // Make sure you need this component as it's not used below
import Searchbar from '../components/SearchBar';
import CouponCard from '../components/CouponCard';
import { getCouponCategory, getCouponLimitValue, getCouponPageValue, getCouponSearchValue, getCouponSortField, getCouponSortOrder, getCouponTotalPages, nextCouponPage, prevCouponPage, resetCouponFilters, selectAllCoupon, selectCouponIds, setCouponCategory, setCouponSearch, setCouponSortOrder, useGetCouponQuery } from '../features/coupon/couponApiSlice';
import {Category, Coupon} from '../types/modelType'
import { useDispatch, useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import ViewDetailedCode from './ViewDetailedCode';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getCompanyCouponTotal, resetCompanyFilters } from '../features/company/companySlice';
import { FcSearch } from "react-icons/fc";
import { Menu } from '@headlessui/react';
import { ClipLoader } from 'react-spinners';
import { selectCategoryById } from '../features/categoryApiSlice/categoryApiSlice';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { MdOutlineSort } from "react-icons/md";
import { Helmet } from 'react-helmet-async';
const AllCodes = () => {

  const dispatch  = useDispatch()
  const location = useLocation();


  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('query');
  const category = searchParams.get('category');

  const existingValueCategory  =  useSelector(state => selectCategoryById(state, category! ))
  useEffect(() => {
    if (query) {
        dispatch(setCouponSearch(query))
    }
    if (category) {
        dispatch(setCouponCategory(category));
        
        setSelectedCategory(existingValueCategory)
       
    }
  }, [query, category, existingValueCategory, dispatch])
  
 
  
 

  useEffect(() => {
      // Function to call when leaving the current route
      const handleRouteChange = () => {
          // console.log('Leaving route:', location.pathname);
          // Your function logic here

         
          dispatch(resetCouponFilters())
      };

      // Set up the effect
      return () => {
          handleRouteChange();

          
      };
  }, []); // Dependency on 'location' means this effect runs when the location (route) changes


  const page  =  useSelector(getCouponPageValue)
  const search  =  useSelector(getCouponSearchValue)
  const limit  =  useSelector(getCouponLimitValue)
  const sortField  =  useSelector(getCouponSortField)
  const sortOrder =  useSelector(getCouponSortOrder)
  const categoryFilter =  useSelector(getCouponCategory)


  const object  = {page :  page, search : search, limit : limit, sort:sortField ,  order: sortOrder, category: categoryFilter }
  const { data , error, isLoading, isFetching } = useGetCouponQuery(object, {
    skip: false,

    // refetchOnMountOrArgChange: true, // This will ensure the query does not read from or write to the cache
  });


  
  const couponsIds =  useSelector(selectCouponIds)

  const navigate  = useNavigate()

  const [couponSelected, setCouponSelected] =  useState<EntityId |  undefined>(undefined)
  const [showCoupon , setShowCoupon] =  useState(false)

  const handleOnClick  =  (id : EntityId) =>{

        
    setCouponSelected(id)
    setShowCoupon(true)

    
  }

  const handleNextPage = () => {
    dispatch(nextCouponPage())
  }


  const handlePrevPage = () => {
    dispatch(prevCouponPage())
  }


  const handleSortOrder  = (type : string) =>{
    if (type === "desc" ||  type === "asc"){
      dispatch(setCouponSortOrder(type))
    }
  
  }

  const handleSearchQueryChange = (query: string) => {
     const newParams = new URLSearchParams(searchParams);
      if (query !== '') {
          newParams.set('query', query);
      } else {
          newParams.delete('query');
      }

      // Replace the current entry in the history stack
      navigate({
          pathname: window.location.pathname,
          search: newParams.toString()
      }, { replace: true });

      dispatch(setCouponSearch(query))
  };


  const handleOnCompanyCategoryChange = (category_id: string) => {
    const newParams = new URLSearchParams(searchParams);
    if (category_id !== '') {
        newParams.set('category', category_id);
    } else {
        newParams.delete('category');
    }

    // Replace the current entry in the history stack
    navigate({
        pathname: window.location.pathname,
        search: newParams.toString()
    }, { replace: true });

    dispatch(setCouponCategory(category_id))

  };

 
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "black",
  };


  let [color, setColor] = useState("#ffffff");


  

  const [queryValue, setQueryValue] = useState<string>(query || '');
  // Look up
  // const existingValueCategory  =  useSelector(state => selectCategoryById(state, category! ))
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(  existingValueCategory ||  undefined);

  const handleResetFilter= ()=>{
    setSearchParams({})
    setQueryValue('')
    setSelectedCategory(undefined)
    dispatch(resetCouponFilters())
    
   

  }

  // const totalPages=  useSelector(getCouponTotalPages)

  // Do not store in the slice because it uses caching sometimes, we get directly the data
  const totalPages=  data?.data.totalPages || 0


    //SEO
    const title = "Uniqshare | All Referrals Codes";
    const description = "Discover a wide range of referral codes on Uniqshare. Earn money by using codes";
    const canonicalUrl = `${process.env.REACT_APP_BASE_URL}/codes`;

  return (
    <>


  <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
  </Helmet>
    {showCoupon ?  <ViewDetailedCode open={showCoupon}  coupon_id={couponSelected}   setShowCoupon={setShowCoupon}/> : null}
    
    <div className='min-h-screen px-5 py-4 md:py-10 bg-background '> 
      <div className='container mx-auto'>
        {/* ... other components ... */}

            {/* Top Container  */}
            <div className='flex flex-col justify-between gap-5 md:flex-row'>

            <div className='flex flex-col gap-2 '>
                <p className='text-4xl'>Discover all codes </p>
                <p>Rewards applicable in these stores. Check individuals terms and conditions</p>

            </div>


          

            </div>

            {/* Middle with fields */}
            <div className='flex flex-row gap-2 mt-5 lg:gap-5 lg:grid lg:grid-cols-2'>

         
            <Searchbar  required_click = {true} placeholder={'Search codes'} query={queryValue} setQuery={setQueryValue} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} onSearchQueryChange={handleSearchQueryChange} onSelecteCategoryChange={handleOnCompanyCategoryChange}/>
              {/* Replace with the menu with headless */}
            {/* <div className='flex flex-row justify-center gap-4 md:justify-end 0 '>
            <Menu as="div" className="relative inline-block text-left ">
              <Menu.Button className="inline-flex justify-center w-full text-sm font-medium bg-opacity-95 hover:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <MdOutlineSort  className='w-8 h-8' />
              </Menu.Button>
              <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={()=> handleSortOrder("asc")}
                      >
                        Newest
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}

                        onClick={()=> handleSortOrder("desc")}
                      >
                        Oldest
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Menu>
            </div> */}


            </div>
      
        {/* Grid for CouponCards */}
        <div className={`min-h-[70vh]  ${isLoading || (couponsIds.length === 0 && isFetching === false) ? 'flex flex-col items-center justify-center' : ''} ` } >
       
        {isLoading && <div className='flex flex-col items-center justify-center h-full p-10'>
        
            <ClipLoader
            color={color}
            loading={isLoading}
            cssOverride={override}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>}
        
        
        
        {couponsIds.length === 0 && isFetching === false ?
              <div className='flex flex-col items-center justify-center h-full p-1'>
                <span className='mb-2 text-3xl text-gray-800'>
                    <FcSearch  className='w-28 h-28' />
                </span>
                <h3 className='mb-3 text-2xl font-semibold text-gray-800'>No Result Found</h3>
                <p className='mb-5 text-base text-center text-gray-600'>We've searched through hundreds of codes<br/>Unfortunately, there are no codes matching your criteria right now.</p>
                <button className='px-4 py-1.5 font-bold text-white bg-primary rounded-sm' onClick={()=>handleResetFilter()}>
                  RESET
                </button>
              </div>
            : null
            }
          <div className='grid grid-cols-1 gap-5 mt-8 md:grid-cols-2 lg:grid-cols-4'>
            {couponsIds.map((id: EntityId) => (
    
             
              <CouponCard key={id} coupon_id={id} onClick={()=> handleOnClick(id)} />
          
            ))}
          </div>
        </div>

 


        {totalPages > 1 && (
          <div className='flex flex-row items-center justify-center gap-2 mt-10 text-center'>
              <button 
                  className={`text-white bg-primary ${page <= 1 ? 'opacity-50 cursor-not-allowed' : ''}`} 
                  onClick={handlePrevPage} 
                  disabled={page <= 1}
              >
                  <MdNavigateBefore className='w-8 h-8' />
              </button>
              <span 
                  className='font-medium'
                  title={`You are on page ${page} out of ${totalPages} total pages.`}
              >
                  Page {page} {totalPages > 1 && `of ${totalPages}`}
              </span>
              <button 
                  className={`text-white bg-primary ${page >= totalPages ? 'opacity-50 cursor-not-allowed' : ''}`} 
                  onClick={handleNextPage} 
                  disabled={page >= totalPages}
              >
                  <MdNavigateNext className='w-8 h-8' />
              </button>
          </div>
      )}

      </div> 
      



   
    </div>

    </>
  );
};


export default AllCodes