import { createEntityAdapter, createSelector, createSlice, EntityState } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ApiResponse } from "../../types/apiType";
import {  Coupon } from "../../types/modelType";
import { AnyARecord } from "dns";

// Define a type for Coupon





type CouponsFiltered ={
    total : number,
    page : number,
    limit : number,
    totalPages: number,
    isLastPage: boolean,

    coupons : Coupon[]
}
type CouponResponse = ApiResponse<CouponsFiltered>;
type CouponResponseSingle = ApiResponse<Coupon>;

interface CreateCouponRequest {
    company: string,
    link: string,
    description: string,
    code?: string,
    minValue: number,
}




interface ServerError {
    status: 'error';
    message: string;
    statusCode: number;
    errorType: string;
}

const couponAdapter = createEntityAdapter<Coupon>({
    selectId: (coupon) => coupon._id
});




// Define the state shape for categories
type CouponState = EntityState<Coupon>;

const initialCouponState: CouponState = couponAdapter.getInitialState();

const initialCouponsFiltered  =  {
    page : 1,
    limit : 10,
    search : "",
    total: 0,
    sortField: "link", // Default sort field
    sortOrder: "asc",  // Default sort order
    couponCategory :  "all",
    totalPages: 0,
    isLastPage: false,
    coupons : initialCouponState

}


export const couponSlice  = createSlice({
    name : "coupon",
    initialState: initialCouponsFiltered,
    reducers: {
        // Reducer to set the search term
        setCouponSearch: (state, action) => {
            state.search = action.payload;
        },
        // Reducer to go to the next page
        nextCouponPage: (state) => {
            state.page += 1;
        },
        // Reducer to go to the previous page
        prevCouponPage: (state) => {
            if (state.page > 1) {
                state.page -= 1;
            }
        },
        // Reducer to jump directly to a page
        goToCouponPage: (state, action) => {
            state.page = action.payload;
        },
        // Reducer to reset to the first page
        resetCurrentCouponPage: (state) => {
            state.page = 1;
        },
        // Reducer to reset the search term
        resetCouponSearch: (state) => {
            state.search = "";
        },
        // Reducer to set the limit of coupons per page
        setCouponLimit: (state, action) => {
            state.limit = action.payload;
        },
        // Reducer to reset the limit to default
        resetCouponLimit: (state) => {
            state.limit = 10;
        },
        // Reducer to set the sorting field
        setCouponSortField: (state, action) => {
            state.sortField = action.payload;
        },
        // Reducer to set the sorting order
        setCouponSortOrder: (state, action) => {
            state.sortOrder = action.payload;
        },
        // Reducer to set the coupon category
        setCouponCategory: (state, action) => {
            state.couponCategory = action.payload;
        },
        // Reducer to reset all filters to their defaults
        resetCouponFilters: (state) => {
            state.page = 1;
            state.limit = 10;
            state.search = "";
            state.sortField = "link";
            state.sortOrder = "asc";
            state.couponCategory = "all";
            // state.totalPages = 0; // Reset totalPages to its initial state
            // state.isLastPage = false; // Reset isLastPage to its initial state
        }
    },
    extraReducers: (builder) =>{
        builder
        // Handle actions related to coupons, like setting them after fetching
        .addMatcher(
            couponApiSlice.endpoints.getCoupon.matchFulfilled,
          (state, action) => {
            // Ensure to use the couponsUserAdapter's setAll method correctly
          //   couponsUserAdapter.setAll(state.coupons, action.payload.data);
    
              state.total =  action.payload.data.total
              state.totalPages = action.payload.data.totalPages;
              state.isLastPage = action.payload.data.isLastPage;
          })
        
    }
})


// Selector Functions
export const getCouponSearchValue = (state: any) => state.coupon.search;
export const getCouponPageValue = (state : any) => state.coupon.page;
export const getCouponLimitValue = (state : any) => state.coupon.limit;
export const getCouponTotal = (state : any) => state.coupon.total;
export const getCouponSortField = (state: any) => state.coupon.sortField;
export const getCouponSortOrder = (state : any) => state.coupon.sortOrder;
export const getCouponCategory = (state : any) => state.coupon.couponCategory;
export const getCouponTotalPages  =(state: any) => state.coupon.totalPages;
export const getCouponLastPage  =(state: any) => state.coupon.isLastPage;


// Export actions from the slice
export const {
    setCouponSearch,
    nextCouponPage,
    prevCouponPage,
    goToCouponPage,
    resetCurrentCouponPage,
    resetCouponSearch,
    setCouponLimit,
    resetCouponLimit,
    setCouponSortField,
    setCouponSortOrder,
    setCouponCategory,
    resetCouponFilters
} = couponSlice.actions;

// Export the reducer
export const couponReducer = couponSlice.reducer;


export const couponApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCoupon: builder.query<CouponResponse, { page: number; limit: number; search?: string; sort?: string;  order?: string; category? : string}>({
            query: ({ page, limit, search = '', sort = 'link', order = 'asc' , category = ''}) => ({
                url: `/coupon?page=${page}&limit=${limit}&search=${search}&sort=${sort}&order=${order}&category=${category}`,
                method: 'GET',
            }),
            transformResponse: (baseQueryReturnValue: unknown) => {
                const responseData = baseQueryReturnValue as CouponResponse;
                return responseData;
            },
            providesTags: (result?: CouponResponse, error?: FetchBaseQueryError) => {
                if (error) {
                    return [];
                }
                
                if (result) {
                    return [
                        { type: 'Coupon' as const, id: "LIST" },
                        ...result.data.coupons.map(coupon => ({ type: 'Coupon' as const, id: coupon._id }))
                    ];
                }
                
                return [];
            }
        }),
        createCoupon: builder.mutation<CouponResponseSingle, CreateCouponRequest>({
            query: (object) => ({
                url: '/coupon',
                method: 'POST',
                body: object
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Coupon", id: "LIST" }
            ]
        }),

        getCouponByCompanyId : builder.query < ApiResponse<Coupon[]>, string| undefined > ({
            query : (id) =>({
                url :  `/coupon/company/${id}`
            })

        }),

        getPopularCoupons: builder.query< ApiResponse<Coupon[]>, {limit?: number;}>({
            query: ({limit}) => ({
                url: `/coupon/popular?&limit=${limit}`,
                method: 'GET',
            }),
        }),

        getNewestCoupons: builder.query< ApiResponse<Coupon[]>, {limit?: number;}>({
            query: ({limit}) => ({
                url: `/coupon/newest?&limit=${limit}`,
                method: 'GET',
            }),
        }),


        updateCouponViews: builder.mutation<ApiResponse<Coupon>, string | undefined>({
            query: (id) => ({
                url: `/coupon/views/${id}`,
                method: 'PUT'
            }),
            
        }),

        updateCouponVewClicks: builder.mutation<ApiResponse<Coupon>, string | undefined>({
            query: (id) => ({
                url: `/coupon/clicks/${id}`,
                method: 'PUT'
            }),
            
        }),
        updateCouponVewCopyCode: builder.mutation<ApiResponse<Coupon>, string | undefined>({
            query: (id) => ({
                url: `/coupon/copiedCode/${id}`,
                method: 'PUT'
            }),
            
        }),


        

    

        


       



    })
});

export const {
  useCreateCouponMutation,
  useGetCouponQuery,
  useGetCouponByCompanyIdQuery,
  useGetPopularCouponsQuery,
  useGetNewestCouponsQuery,
  useUpdateCouponViewsMutation,
  useUpdateCouponVewClicksMutation,
  useUpdateCouponVewCopyCodeMutation

} = couponApiSlice;




//For coupons
// export const selectCouponResults = couponApiSlice.endpoints.getCoupon.select();

const transformCouponResponseToState = (couponResponse: CouponResponse): CouponState => {
    // Use the couponAdapter to transform the array of categories into the desired state shape
    return couponAdapter.setAll(initialCouponState, couponResponse.data.coupons);
}

// const selectCouponData = createSelector(
//     selectCouponResults,
//     (couponResult) => {
//         if (couponResult && couponResult.data) {
//             return transformCouponResponseToState(couponResult.data);
//         }
//         return initialCouponState;
//     }
// );

// export const selectCouponResponseStatusAndMessage = createSelector(
//     selectCouponResults,
//     (couponResult) => {
//         if (couponResult && couponResult.data) {
//             const { status, message } = couponResult.data;
//             return { status, message };
//         }
//         return { status: "", message: "" };
//     }
// );



export const {
    selectAll: selectAllCoupon,
    selectById: selectCouponById,
    selectIds: selectCouponIds,
} = couponAdapter.getSelectors((state: any) => {

    const search = state.coupon.search
    const page  = state.coupon.page
    const limit = state.coupon.limit
    const sort  = state.coupon.sortField
    const order = state.coupon.sortOrder
    const category = state.coupon.couponCategory
    const selectCouponResults = couponApiSlice.endpoints.getCoupon.select({page,limit, search, sort, order, category});

    const selectCouponData = createSelector(
        selectCouponResults,
        (couponResult) => {
            if (couponResult && couponResult.data) {
                return transformCouponResponseToState(couponResult.data);
            }
            return initialCouponState;
        }

    );

    return selectCouponData(state) ?? initialCouponState



})
