import React from 'react'
import { formatNumber } from '../../utils/formatting'

type AnalyticsDataProps ={
  label : string
  data : number
}
const AnalyticsData = ({label, data }: AnalyticsDataProps) => {
  return (
    <div className='flex flex-col items-center justify-center gap-1 overflow-auto w-18 '>
      <div className='py-2 text-lg font-medium truncate md:text-xl lg:text-2xl '>
        {/* Convert number to string and render */}
        <p>{formatNumber(data)}</p>
      </div>
      <div className='max-w-xs text-sm truncate md:text-base'>
        <p>{label}</p>
      </div>
    </div>
  );
  
  
}

export default AnalyticsData