import React from 'react';

const AboutUs = () => {
  return (
    <div className='px-1 py-10 text-base bg-background'>
      {/* Container */}
      <div className='mx-auto container-2'>
        <h1 className='py-6 mb-4 text-3xl font-bold text-center md:text-4xl'>Welcome to UniqShare</h1>
        <p className='mb-6 '>
          In a world where connections and smart savings make a significant difference, 
          UniqShare stands out as a pioneering platform for sharing and discovering 
          referral codes. Our mission is to transform the way referral codes are utilized, 
          making it rewarding and straightforward for everyone involved.
        </p>

        <h2 className='mb-3 text-2xl font-semibold'>Why Choose UniqShare?</h2>
        <ul className='mb-6 list-disc list-inside'>
          <li>
            <strong>Earn and Save with Referral Codes:</strong> If you're looking to join a website, 
            don't just sign up – sign up smart. Use a referral code from our extensive collection and 
            unlock exclusive rewards and savings. It's not just about joining; it's about earning and 
            saving as you do.
          </li>
          <li>
            <strong>Request Your Favorite Brands:</strong> Can't find your preferred brand on our platform? 
            No problem! Our unique feature allows users to request brands that are not yet present. This 
            means you get a say in shaping our growing collection, ensuring that your favorite brands 
            are just a click away.
          </li>
          <li>
            <strong>Exclusive Access for Subscribers:</strong> Our subscription-based model is designed to 
            maintain the integrity and authenticity of every referral code shared. By eliminating bots and 
            unverified posts, we ensure that every code you find is reliable and valuable.
          </li>
          <li>
            <strong>Equitable Code Distribution:</strong> Our innovative algorithm ensures that every code 
            gets its moment in the spotlight. We display codes equally, rotating them to offer each subscriber 
            a fair opportunity to benefit. The more you engage with our platform, the more visibility your 
            codes receive.
          </li>
          <li>
            <strong>Rapid and Rigorous Approval Process:</strong> We value your time. That's why our team 
            works around the clock to review and approve codes within 24 hours. Our swift and thorough process 
            means that new opportunities for savings and earnings are constantly at your fingertips.
          </li>
        </ul>

        <p className='mb-6'>
          At UniqShare, we are committed to providing an environment that rewards active participation 
          and fosters a community of savvy users who understand the power of a good deal. We are not just a 
          platform; we are a community of smart savers and earners.
        </p>

        <h2 className='mb-3 text-2xl font-semibold'>Join the Smart Savers Community</h2>
        <p className='mb-6'>
          Whether you're a deal hunter, a savvy shopper, or someone who loves to share the joy of savings, 
          UniqShare is your go-to destination. Join us and step into a world where every sign-up, 
          every purchase, and every share can be more rewarding than ever before.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
