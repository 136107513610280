import  userDefault  from '../../images/default.png'
import { EntityId } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { selectSubscription, selectUserCouponById } from '../../features/user/userSlice'
import { Coupon } from '../../types/modelType'
import { FaCircleCheck } from "react-icons/fa6";
import { FaClock } from "react-icons/fa6";
import { FaCircleXmark } from "react-icons/fa6";
import { FaCircleExclamation } from "react-icons/fa6";
import { FaCircleMinus } from "react-icons/fa6";
import { TbCurrencyDollarOff } from "react-icons/tb";

type  AccountCodeType ={
    codeId?: EntityId  
    onClick : ()=> void
    coupon_data? : Coupon
    logged : boolean
  
}
const AccountCode = ({  logged , codeId, onClick, coupon_data} : AccountCodeType) => {
    //Selectors
    const subscription  =  useSelector(selectSubscription)
    const coupon = useSelector(state => {
        if (codeId) {
            // Convert to a number if your EntityId is a number
            return selectUserCouponById(state, codeId);   
        }
        return coupon_data; // or a suitable default/fallback
    });

    return (
        <div className='relative flex flex-col items-center w-full gap-4' onClick={onClick}>
            <div className='relative w-full aspect-square'>
                <img src={coupon?.company.image ? coupon?.company.image : userDefault} className='object-cover w-full aspect-square'/>
                
                <div className='absolute top-1 right-1'>
                        {
                            logged === true && (
                                subscription?.status !== "active" ? (
                                    <TbCurrencyDollarOff className='w-4 h-4 text-gray-700' />
                                ) : (
                                    <>
                                        { coupon?.status === "pending" && <FaClock className='w-4 h-4 text-gray-700' /> }
                                        { coupon?.status === "approved" && <FaCircleCheck className='w-4 h-4 text-green-700' /> }
                                        { coupon?.status === "rejected" && <FaCircleXmark className='w-4 h-4 text-red-700' /> }
                                        { coupon?.status === "disabled" && <FaCircleExclamation className='w-4 h-4 text-red-700' /> }
                                        { coupon?.status === "deactivated" && <FaCircleMinus className='w-4 h-4 text-gray-700' /> }
                                    </>
                                )
                            )
                        }

                    
                </div>
            </div>
     
        </div>
    );

}

export default AccountCode