import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'


import { apiSlice } from '../api/apiSlice'
import authSlice from '../../features/auth/authSlice'
import userSlice from '../../features/user/userSlice'
import searchSlice from '../../features/search/searchSlice'
import { compayReducer} from '../../features/company/companySlice'
import { couponReducer} from '../../features/coupon/couponApiSlice'
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore ,  
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,} from 'redux-persist';
import modalSlice from '../../features/modal/modalSlice';
import { errorMiddleware } from '../../middleware/errorMiddleware';
import mobileMenu from '../../features/mobileMenu/mobileMenu'




const rootReducer  =  combineReducers({
  [apiSlice.reducerPath] : apiSlice.reducer,
  auth : authSlice, 
  modal : modalSlice,
  mobileMenu: mobileMenu,
  user:  userSlice,
  search : searchSlice,
  company : compayReducer,
  coupon: couponReducer,


})


const persistConfig = {

  key: 'root',
  storage,
  blacklist: ['api', 'modal', 'company', 'search', 'coupon', 'mobileMenu']

  
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
  middleware : getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(apiSlice.middleware, errorMiddleware),

  
})


export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch