import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { showModal } from '../features/modal/modalSlice';

// Define the expected structure of the error response
interface ErrorResponse {
  status: number;
  data: {
    status: string;
    message: string;
    statusCode: number;
    errorType: string;
  };
}

export const errorMiddleware: Middleware = ({ dispatch }) => next => action => {
  if (isRejectedWithValue(action)) {
    // Cast the payload to the expected error response structure
    const error = action.payload as ErrorResponse;

    // Check the top-level status code
    switch (error.status) {
      case 401:
        dispatch(showModal({ type: 'error', message: error.data.message || 'Unauthorized. Please log in again.' ,
        redirectPath: '/login', // The path where you want to redirect the user
        redirectButtonText: 'Login' // Optional custom text for the redirect button
      
      }));
        break;

      case 403:
          dispatch(showModal({ type: 'error', message: error.data.message || 'Unauthorized. Please log in again.' , 
          redirectPath: '/verify', // The path where you want to redirect the user
          redirectButtonText: 'Verify Account' // Optional custom text for the redirect button

      }));
      break;
    
      case 400:
        // Use the message from the nested data object for the modal
        dispatch(showModal({ type: 'error', message: error.data.message || 'Bad request. Check your input and try again.' }));
        break;
    
      case 500:
        dispatch(showModal({ type: 'error', message: error.data.message || 'An unexpected server error occurred.' }));
        break;

     
    
      default:
        dispatch(showModal({ type: 'error', message: error.data.message || 'An unknown error occurred.' }));
      // Handle other status codes as necessary
    }
  }

  return next(action);
};
