import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiSlice } from "../../app/api/apiSlice"
import { ApiResponse } from "../../types/apiType";
import { Coupon, CouponUpdateRequest, ISubscription } from "../../types/modelType";
import { url } from "inspector";


type CouponResponse = ApiResponse<Coupon[]>;
type CouponResponseSingle = ApiResponse<Coupon>;


interface UserSubscriptionData {
  couponMonthlyLimit: number;
  couponUsed: number;
  tier: string | null;
  resetCouponDate: string | null;
  subscription:  ISubscription | null;
}

type SubscriptionResponse =  ApiResponse<UserSubscriptionData>

export const userApiSlice  =  apiSlice.injectEndpoints({
  endpoints : builder  =>({
      getUserCoupons  : builder.query<CouponResponse, { status : string} >({
          query : ({ status})=> `/coupon/users/auth?status=${status}`,
         
          providesTags: (result?: CouponResponse, error?: FetchBaseQueryError) => {
            if (error) {
                return [];
            }
            
            if (result) {
                return [
                    { type: 'UserCoupon' as const, id: "LIST" },
                    ...result.data.map(coupon => ({ type: 'UserCoupon' as const, id: coupon._id }))
                ];
            }
            
            return [];
         }


      } ),

      getNoAuthCoupons  : builder.query<CouponResponse, {id: string | undefined} >({
        query : ({id})=> `/coupon/users/${id}`,
       
        

      } ),




      //Make sure that even the other coupon is updated
      editCoupon : builder.mutation<CouponResponseSingle, CouponUpdateRequest>({
        query:  ({id, ...rest}) =>({
            url: `/coupon/${id}`,
            method : 'PUT',
            body: rest
            
        }),
        invalidatesTags: (result, error, arg) => [
            { type: 'UserCoupon', id: arg.id }
        ]
    }),

    activateCoupon : builder.mutation<CouponResponseSingle, {id: string}>({
      query:  ({id}) =>({
          url: `/coupon/activate/${id}`,
          method : 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
          { type: 'UserCoupon', id: arg.id }
      ]
  }),

  deactivateCoupon : builder.mutation<CouponResponseSingle, {id: string}>({
    query:  ({id}) =>({
        url: `/coupon/deactivate/${id}`,
        method : 'PUT',
    }),
    invalidatesTags: (result, error, arg) => [
        { type: 'UserCoupon', id: arg.id }
    ]
  }),
  deleteCoupon : builder.mutation<{success: boolean}, {id:string}>({
    query:  ({id}) =>({
        url: `/coupon/${id}`,
        method : 'DELETE',
    }),
    invalidatesTags: (result, error, arg) => [
        { type: 'UserCoupon', id: arg.id }
    ]
  }),





    getUserSubscription : builder.query<SubscriptionResponse, void>({
      query: () =>({
        url: `/user/subscription`,
        keepUnusedDataFor: 0,
        // method: 'POST'
      }),



    }),

    portalBilling : builder.mutation <ApiResponse<string>, void>({
      query: () =>({
        url : `/payment/create-portal-session`,
        method : 'POST',
      })
    })





      
      
      
      
      
   
  




  })



})


export const {
  useGetNoAuthCouponsQuery,
  useGetUserCouponsQuery,
  useEditCouponMutation,
  useGetUserSubscriptionQuery,
  usePortalBillingMutation,
  useLazyGetUserSubscriptionQuery,
  useDeactivateCouponMutation,
  useActivateCouponMutation,
  useDeleteCouponMutation

} = userApiSlice

// https://stackoverflow.com/questions/75764913/how-can-i-get-my-entityadapter-to-automatically-load-its-data-when-a-selector-th